import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "col-md-6 mb-3" }
const _hoisted_2 = {
  key: 0,
  class: "badge bg-secondary"
}
const _hoisted_3 = {
  key: 1,
  class: "form-group"
}
const _hoisted_4 = { class: "form-label" }
const _hoisted_5 = { class: "col-md-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_Datepicker = _resolveComponent("Datepicker")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.questions, (item) => {
    return (_openBlock(), _createElementBlock("div", {
      class: "row",
      key: item.key
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.getQuestionComponent(item))
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.getQuestionComponent(item)), _mergeProps({ key: 0 }, _ctx.getQuestionComponentProps(item), {
              modelValue: _ctx.getValue(item),
              label: item.text,
              descriptions: item.descriptions,
              descriptionsText: item.descriptionsText,
              "onUpdate:modelValue": ($event: any) => (_ctx.onUpdate(item, $event))
            }), {
              label: _withCtx(() => [
                (_ctx.showIndex)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(item.key), 1))
                  : _createCommentVNode("", true),
                _createTextVNode(" " + _toDisplayString(item.text) + " ", 1),
                (item.tooltip && item.type != 'Boolean')
                  ? (_openBlock(), _createBlock(_component_Tooltip, {
                      key: 1,
                      title: item.tooltip
                    }, null, 8, ["title"]))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1040, ["modelValue", "label", "descriptions", "descriptionsText", "onUpdate:modelValue"]))
          : _createCommentVNode("", true),
        (item.type == 'Date')
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("label", _hoisted_4, _toDisplayString(item.text), 1),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_Datepicker, {
                  autoApply: "",
                  inline: "",
                  monthNameFormat: "long",
                  locale: "sv",
                  enableTimePicker: false,
                  modelValue: _ctx.getValue(item),
                  "onUpdate:modelValue": ($event: any) => (_ctx.onUpdate(item, $event)),
                  minDate: new Date()
                }, null, 8, ["modelValue", "onUpdate:modelValue", "minDate"])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]))
  }), 128))
}