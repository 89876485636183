
import { defineComponent } from "vue";
import moment from "moment";
import { api, CallbackRequest } from "src/api";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default defineComponent({
  components: { Datepicker },
  props: {
    submittedTime: { type: Boolean, default: false }
  },
  data() {
    return {
      date: null as any,
      disableHolidays: new Array(),
      time: {
        hours: 0,
        minutes: 0
      },
      form: {} as CallbackRequest,
      submitted: this.submittedTime,
      errorMessage: "",
      startTime: {
        hours: 0,
        minutes: 0
      },
      minTime: {
        hours: 8,
        minutes: 0
      },
      maxTime: {
        hours: 17,
        minutes: 30
      },
      timeSlotsWithTenBooking: Array()
    };
  },
  watch: {
    date: function (newVal) {
      this.form.callbackDate = moment(newVal).format("YYYY-MM-DD");
      this.getTimeSlotsWithTenBooking();
    }
  },

  computed: {
    //disable time outside 8.00 - 17.30
    filters() {
      if (this.time.hours >= 17) {
        return {
          times: {
            hours: [0, 1, 2, 3, 4, 5, 6, 7, 18, 19, 20, 21, 22, 23].concat(
              this.timeSlotsWithTenBooking
            ),
            minutes: [35, 40, 45, 50, 55]
          }
        };
      } else {
        return {
          times: {
            hours: [0, 1, 2, 3, 4, 5, 6, 7, 18, 19, 20, 21, 22, 23].concat(
              this.timeSlotsWithTenBooking
            )
          }
        };
      }
    },

    setMinDate() {
      let today = new Date();
      return new Date(today.setDate(today.getDate() + 1));
    },

    setMaxDate() {
      let today = new Date();
      return new Date(
        today.getFullYear() + 2,
        today.getMonth(),
        today.getDay()
      );
    }
  },

  // TODO move some date functions to separate file
  methods: {
    getTimeSlotsWithTenBooking: async function () {
      if (this.date == null) return;
      const timeSlotsWithTenBooking = (
        await api.Mortgage.capCallbackTime({ date: this.date })
      ).data;
      this.timeSlotsWithTenBooking = timeSlotsWithTenBooking;
    },
    formatDate(value: Date) {
      if (value == null) return null;
      return moment(value).format("YYYY-MM-DD");
    },

    formatTime(value: Date) {
      return moment(value).format("HH:mm");
    },
    formatCallTime(value: Date) {
      return `${moment(value).format("HH:mm")} och ${moment(value).add(30, "minutes").format("HH:mm")}`;
    },
    formatDateTime(value: Date) {
      return moment(value).format("YYYY-MM-DDTHH:mm:ss");
    },

    disabledDates() {
      let precentYear = new Date().getFullYear();
      let twoYearsForward = new Date().getFullYear() + 2;

      //Look for holidays 2 years ahead
      for (let year = precentYear; year <= twoYearsForward; year++) {
        let easter = this.getEaster(year);
        let midsummer = this.calculateHoliday(
          new Date(year, 5, 19),
          new Date(year, 5, 25)
        );
        let halloween = this.calculateHoliday(
          new Date(year, 9, 31),
          new Date(year, 10, 5)
        );
        this.disableHolidays.push(
          new Date(easter.setDate(easter.getDate() - 2))
        );
        this.disableHolidays.push(
          new Date(easter.setDate(easter.getDate() + 3))
        );
        this.disableHolidays.push(new Date(year, 0, 1));
        this.disableHolidays.push(new Date(year, 0, 6));
        this.disableHolidays.push(new Date(year, 4, 1));
        this.disableHolidays.push(
          new Date(easter.setDate(easter.getDate() + 38))
        );
        this.disableHolidays.push(
          new Date(easter.setDate(easter.getDate() + 10))
        );
        this.disableHolidays.push(new Date(year, 5, 6));
        this.disableHolidays.push(
          new Date(midsummer.setDate(midsummer.getDate() - 1))
        );
        this.disableHolidays.push(new Date(year, 11, 24));
        this.disableHolidays.push(new Date(year, 11, 25));
        this.disableHolidays.push(new Date(year, 11, 26));
        this.disableHolidays.push(halloween);
      }
    },

    calculateHoliday(from: Date, until: Date) {
      for (let day = from; day <= until; day.setDate(day.getDate() + 1)) {
        if (day.getDay() == 6) return new Date(day);
      }
      return from;
    },

    getEaster(year: number) {
      var f = Math.floor,
        // Golden Number - 1
        G = year % 19,
        C = f(year / 100),
        // related to Epact
        H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30,
        // number of days from 21 March to the Paschal full moon
        I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11)),
        // weekday for the Paschal full moon
        J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7,
        // number of days from 21 March to the Sunday on or before the Paschal full moon
        L = I - J,
        month = 3 + f((L + 40) / 44),
        day = L + 28 - 31 * f(month / 4);

      return new Date(year, month - 1, day);
    },

    goToHome() {
      this.$router.push("/mortgage");
    },

    submit() {
      this.errorMessage = "";
      let isValid = this.checkForm();

      if (isValid) {
        api.Mortgage.setCallbackFromCustomer(this.form)
          .then(() => {
            this.submitted = true;
          })
          .catch(() => {
            this.errorMessage =
              "Något gick fel, vänligen kontakta oss på 020-25 26 26";
          });
      }
    },
    formatHour() {},
    checkForm() {
      if (
        this.form.callbackDate == null ||
        this.form.callbackDate == undefined
      ) {
        this.errorMessage = "Felaktigt datum, vänligen kontrollera datumet.";
        return false;
      } else if (this.time.hours == 0) {
        this.errorMessage =
          "Ogiltig tid, vänligen kontrollera så tiden är vald mellan 08.00 - 17.30.";
        return false;
      } else if (this.timeSlotsWithTenBooking.includes(this.time.hours)) {
        this.errorMessage = `Tyvärr är alla tider bokade mellan 
          ${
            this.time.hours <= 9 ? "0" + this.time.hours : this.time.hours
          } och ${
          this.time.hours + 1 <= 9
            ? "0" + this.time.hours + 1
            : this.time.hours + 1
        }, vänligen välj en annan tid.`;
        return false;
      }

      let chosenDate = this.formatDate(this.date);
      let newDate =
        chosenDate + ":" + this.time.hours + ":" + this.time.minutes;
      let parse = new Date(newDate);
      let format = this.formatDateTime(parse);
      this.form.callbackDate = format;
      return true;
    }
  },

  beforeMount() {
    this.disabledDates();
    window.scrollTo(0, 0);
  }
});
