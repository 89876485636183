
import { defineComponent } from "vue";
export default defineComponent({
  props: ["app"],
  methods: {
    checkIfMobileDevice() {
      var userAgent = navigator.userAgent;
      var devicesRegex = /android|iphone|kindle|ipad/i;
      let isMobileDevice = devicesRegex.test(userAgent);
      return isMobileDevice;
    }
  }
});
