
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    modelValue: Boolean,
    label: String,
    options: Array
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    function emitValue(value: boolean) {
      emit("update:modelValue", value);
    }

    function onChanged(e: InputEvent) {
      const target = e.currentTarget as HTMLInputElement;
      emitValue(target?.checked);
    }

    return {
      emitValue,
      onChanged
    };
  }
});
