import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  for: "",
  class: "form-label"
}
const _hoisted_2 = { class: "input-group" }
const _hoisted_3 = ["value", "readonly", "placeholder"]
const _hoisted_4 = { class: "invalid-feedback" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", _hoisted_1, [
      _renderSlot(_ctx.$slots, "label", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.label), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        type: "tel",
        pattern: "[0-9]*",
        inputmode: "decimal",
        class: _normalizeClass(["form-control border-end-0", { 'is-valid': _ctx.valid, 'is-invalid': _ctx.error }]),
        value: _ctx.inputValue,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isDirty = true)),
        onKeydown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onKeydown && _ctx.onKeydown(...args))),
        readonly: _ctx.readonly,
        placeholder: _ctx.placeholder
      }, null, 42, _hoisted_3),
      _createElementVNode("span", {
        class: _normalizeClass([_ctx.readonly ? 'bg-grey' : 'bg-white', "input-group-text border-start-0"])
      }, _toDisplayString(_ctx.percent ? "%" : _ctx.money ? "kr" : ""), 3),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.error), 1)
    ])
  ]))
}