
import { defineComponent, PropType } from "vue";
import MortgageQuestionsList from "src/components/Mortgage/MortgageQuestionsList.vue";
import {
  getRequiredQuestions,
  getQuestionValue
} from "src/model/mortgageQuestions";
import questions from "src/model/mortgageQuestionsModel";
import { QuestionStep, steps } from "src/model/mortgageQuestionsSteps";
import { intersect } from "src/lib/array";
import _ from "lodash";
import {
  MortgageQuestionsForm,
  MortgageQuestion,
  ApplicantAdditionsForm,
  AdditionalMortgageLoan,
  MortgageLoanPurpose,
  AdditionalPrivateLoan,
  Housing,
  PrivateLoanType,
  LoanOwnerType,
  IncomeDeviate
} from "@/api";

export default defineComponent({
  components: {
    MortgageQuestionsList
  },
  emits: ["submitStep"],
  props: {
    // TODO should be one state object, from server
    loanPurpose: { type: String, default: "" },
    applicantEmployment: { type: String, default: 0 },
    coApplicantEmployment: { type: String, default: 0 },
    currentHousing: { type: String, default: "" },
    propertyHousing: { type: String, default: "" },
    downPayment: { type: Number, default: 0 },
    withSpecialist: { type: Boolean, default: false },
    withPremium: { type: Boolean, default: false },
    applicantIncomeDeviates: { type: Array, default: [] },
    coApplicantIncomeDeviates: { type: Array, default: [] },
    withPrivateLoans: { type: Boolean, default: false },
    isCoApplicant: { type: Boolean, default: false },
    showIndex: { type: Boolean, default: false },
    formValue: {
      type: Object as PropType<MortgageQuestionsForm>,
      default: () => ({})
    },
    questions: {
      type: Array as PropType<MortgageQuestion[]>,
      default: () => []
    },
    applicantPersonalNumber: { type: String, default: "" },
    coApplicantPersonalNumber: { type: String, default: "" },
    applicantMaritalStatus: { type: String, default: "" },
    coApplicantMaritalStatus: { type: String, default: "" },
  },
  data() {
    return {
      currentStepKey: "",
      form: {} as MortgageQuestionsForm,
      bookAdvisor: false,
      allQuestionsAnswered: false,
      formHasChanges: false,
      formValueSet: false
    };
  },
  methods: {
    onUpdateQuestion(value: any, question: MortgageQuestion, index: number) {
      this.formHasChanges = true;
      if (!question.field) {
        console.warn("onUpdate: question.field is missing", question);
        return;
      }
      if (question.forHousing) {
        const field = question.field as keyof AdditionalMortgageLoan;
        if (this.form.housings) this.form.housings[index][field] = value;
      } else if (question.field == "carsInHousehold") {
        this.form[question.field as keyof MortgageQuestionsForm] = value;
        if (value) {
          if (!this.form.privateLoans) this.form.privateLoans = [];
          this.form.privateLoans.push({
            amount: 0,
            monthlyCost: 0,
            loanType: PrivateLoanType.Car,
            loanOwnerType: LoanOwnerType.MainApplicant
          });
        }else{
          if(this.form.privateLoans)
            this.form.privateLoans = this.form.privateLoans.filter(x => x.loanType != PrivateLoanType.Car)
        }

      } else if (question.forPrivateLoans || question.forOwnedCars) {
        const field = question.field as keyof AdditionalPrivateLoan;
        if (this.form.privateLoans)
          this.form.privateLoans[index][field] = value;
      } else if (question.forApplicant) {
        const field = question.field as keyof ApplicantAdditionsForm;
        if (this.isCoApplicant) {
          if (!this.form.coApplicant) this.form.coApplicant = {};
          this.form.coApplicant[field] = value;
        } else {
          if (!this.form.applicant) this.form.applicant = {};
          this.form.applicant[field] = value;
        }
      } else {
        this.form[question.field as keyof MortgageQuestionsForm] = value;
      }
    },
    onNextStep() {
      // TODO VALIDATE FORM
      const requiredStepsKeys = this.requiredSteps.map((step) => step.key);
      const currentStepIndex = requiredStepsKeys.indexOf(this.currentStepKey);
      const isLastStep = currentStepIndex === requiredStepsKeys.length - 1;

      if (isLastStep) {
        this.gotoStep("thankyou");
        this.allQuestionsAnswered = true;
        return;
      }

      this.gotoStep(requiredStepsKeys[currentStepIndex + 1]);
    },
    onPreviousStep() {
      const requiredStepsKeys = this.requiredSteps.map((step) => step.key);
      const currentStepIndex = requiredStepsKeys.indexOf(this.currentStepKey);
      this.gotoStep(requiredStepsKeys[currentStepIndex - 1]);
    },
    gotoStep(step: string) {
      if (this.formHasChanges) {
        let form = _.cloneDeep(this.form);
        this.$emit("submitStep", form);
      }
      this.formHasChanges = false;
      this.currentStepKey = step;
    },
    gotoFirstUnansweredStep() {
      if (this.requiredSteps.length > 0 && !this.currentStepKey) {
        // go to first unanswered step
        for (var i = 0; i < this.requiredSteps.length; i++) {
          if (this.answeredSteps.indexOf(this.requiredSteps[i].key) == -1) {
            console.log(this.requiredSteps[i].key, "setting unanswered step");
            this.currentStepKey = this.requiredSteps[i].key;
            return;
          }
        }
        // all steps answered
        if (i === this.requiredSteps.length) {
          this.allQuestionsAnswered = true;
          this.currentStepKey = "thankyou";
        }
        // default go to first step
        else this.currentStepKey = this.requiredSteps[0].key;
      }
    },
    getHousingText(housing: Housing) {
      switch (housing) {
        case "House":
          return "Hus";
        case "Cottage":
          return "Fritidshus";
        case "Condominium":
          return "Bostadsrätt";
        default:
          return "";
      }
    },
    getOwnedCarsQuestions(carOwnType: number): MortgageQuestion[] {
      if (carOwnType == 0) return this.ownedCarsLeasingQuestions;
      else return this.ownedCarsQuestions;
    },
    getOwnedCarIndex(index: number): number {
      if (!this.form.privateLoans) return 0;
      const firstIndex = this.form.privateLoans.findIndex(this.isOwnedCar);
      // works for only 2 cars
      if (index == firstIndex) return 1;
      return 2;
    },
    isOwnedCar(loan: AdditionalPrivateLoan): boolean {
      return loan.loanType == PrivateLoanType.Car;
    },
    addOwnedCar() {
      if (!this.form.privateLoans) this.form.privateLoans = [];
      this.form.privateLoans.push({
        amount: 0,
        monthlyCost: 0,
        loanType: PrivateLoanType.Car,
        loanOwnerType: LoanOwnerType.MainApplicant
      });
    },
    removeOwnedCar(index: number) {
      this.form.privateLoans?.splice(index, 1);
    },
    setIncomeYear(questions: MortgageQuestion[]) {
      var incomeDeviates = this.isCoApplicant
        ? this.coApplicantIncomeDeviates
        : this.applicantIncomeDeviates;
      if (incomeDeviates != null) {
        incomeDeviates.forEach(function (element: any) {
          if (element.applicantIncomeDeviates) {
            var question = questions.find((x) => x.key == element.key);
            if (question != null)
              question.text = question.text?.replace("{year}", element.year);
          }
        });
      }
    }
  },
  computed: {
    // steps which contain at least one required question
    requiredSteps(): QuestionStep[] {
      return steps.filter(
        (step) =>
          // last "thankyou" step is shown after answering everything
          (step.key == "thankyou" && this.allQuestionsAnswered) ||
          intersect(this.requiredQuestions, step.questions).length > 0
      );
    },
    answeredQuestions(): string[] {
      return questions
        .filter((q) => q.key && this.requiredQuestions.indexOf(q.key) !== -1)
        .filter((q) => {
          const value = getQuestionValue(q, this.form, this.isCoApplicant, 0);
          return (
            value !== null &&
            typeof value !== "undefined" &&
            value !== "" &&
            value.length !== 0
          );
        })
        .map((q) => q.key || "");
    },
    // steps which have all requried questions answered
    answeredSteps(): string[] {
      return this.requiredSteps
        .filter(
          (step) =>
            // all required step questions should be answered when step is not other
            intersect(this.answeredQuestions, step.questions).length ===
              intersect(this.requiredQuestions, step.questions).length ||
            // enough that one downpayment question is answered
            (step.key === "downpayment1" &&
              intersect(this.answeredQuestions, step.questions).length > 0)
        )
        .map((step) => step.key);
    },
    currentStep(): QuestionStep | undefined {
      return steps.find((step) => step.key == this.currentStepKey);
    },
    isFirstStep(): boolean {
      return this.requiredSteps[0]?.key == this.currentStep?.key;
    },
    currentStepQuestions(): MortgageQuestion[] {
      if (!this.currentStep) return [];
      const currentStepRequiredQuestions = intersect(
        this.currentStep.questions,
        this.requiredQuestions
      );
      const resultQuestions = [];
      if (this.questions)
        for (const questionKey of currentStepRequiredQuestions) {
          const question = this.questions.find((q) => q.key == questionKey);
          if (question && !question.forOwnedCars) {
            resultQuestions.push({ ...question });
          }
        }
      this.setIncomeYear(resultQuestions);
      return resultQuestions;
    },
    housingQuestions(): MortgageQuestion[] {
      return this.questions.filter((q) => q.forHousing);
    },
    ownedCarsQuestions(): MortgageQuestion[] {
      return this.questions.filter(
        (q) => q.forOwnedCars && q.field != "monthlyCost"
      );
    },
    ownedCarsLeasingQuestions(): MortgageQuestion[] {
      return this.questions.filter((q) => q.forOwnedCars);
    },
    ownedCars(): AdditionalPrivateLoan[] {
      if (!this.form.privateLoans) return [];
      return this.form.privateLoans.filter(this.isOwnedCar);
    },
    requiredQuestions(): String[] {
      // TODO should be one state object, from server
      const state = {
        loanPurpose: this.loanPurpose,
        applicantEmployment: this.applicantEmployment,
        coApplicantEmployment: this.coApplicantEmployment,
        currentHousing: this.currentHousing,
        propertyHousing: this.propertyHousing,
        withSpecialist: this.withSpecialist,
        withPremium: this.withPremium,
        applicantIncomeDeviates: this.applicantIncomeDeviates,
        coApplicantIncomeDeviates: this.coApplicantIncomeDeviates,
        withPrivateLoans: this.withPrivateLoans,
        downPayment: this.downPayment,
        isCoApplicant: this.isCoApplicant,
        applicantPersonalNumber: this.applicantPersonalNumber,
        coApplicantPersonalNumber: this.coApplicantPersonalNumber,
        applicantMaritalStatus: this.applicantMaritalStatus,
        coApplicantMaritalStatus: this.coApplicantMaritalStatus
      };
      return getRequiredQuestions(state, this.form);
    },
    totalDownPayment: function (): number {
      var totalDownPayment =
        (this.form.downPaymentFromGift || 0) +
        (this.form.downPaymentFromHeritage || 0) +
        (this.form.downPaymentFromHomeSale || 0) +
        (this.form.downPaymentFromLoan || 0) +
        (this.form.downPaymentFromOther || 0) +
        (this.form.downPaymentFromSavings || 0);

      return totalDownPayment;
    },
    downPaymentToEnter: function (): number {
      const result = (this.downPayment || 0) - this.totalDownPayment;
      return result >= 0 ? result : 0;
    },
    housingsToKeep: function (): AdditionalMortgageLoan[] {
      const housingsToKeep = this.form.housings?.filter(
        (h) => 
        h.keepCurrentHousing 
        && h?.housing != "Rental"
        && h?.housing != "Lodger"
      );
      return housingsToKeep || [];
    },
    isLoanPurposeRenegotiate() {
      return this.loanPurpose == MortgageLoanPurpose.Renegotiate;
    },
    checkIfMobileDevice(){
      var userAgent = navigator.userAgent
      var devicesRegex = /android|iphone|kindle|ipad/i;
      let isMobileDevice = devicesRegex.test(userAgent);
      return isMobileDevice;
    }

  },
  beforeUnmount() {
    if (this.formHasChanges) {
      let form = _.cloneDeep(this.form);
      this.$emit("submitStep", form);
    }
  },
  watch: {
    formValue: {
      handler(form: MortgageQuestionsForm) {
        if (this.formValueSet) return;
        this.formValueSet = true;

        if (!form) return;
        this.form = { ...form };
        this.gotoFirstUnansweredStep();
      },
      deep: true,
      immediate: true
    }
  }
});
