
import { defineComponent } from "vue";
import _ from "lodash";
import TextField from "../Controls/TextInput.vue";
import SelectInput from "../Controls/SelectInput.vue";
import {
  api,
  LoanApplicationView,
  OfferView,
  AccountInfo,
  CustomerLoanChangeRequest
} from "src/api";
// import LoanOfferCard from "src/components/Controls/Loan/LoanOfferCard.vue";
import LoanOfferCard from "./LoanOfferCard.vue";

const formatterWithoutCurrency = new Intl.NumberFormat("sv-SE", {
  currency: "SEK"
});

export default defineComponent({
  components: {
    LoanOfferCard,
    TextField,
    SelectInput
  },
  data: function () {
    return {
      app: {} as LoanApplicationView,
      expandedOffers: new Array<number>(),
      headerExpanded: false,
      headerExpandDone: false,
      headerExpandTreatment: false,
      headerExpandDescription: false,
      changeRequest: {
        loanAmount: 0,
        paybackYears: 0
      } as CustomerLoanChangeRequest,
      showchangeRequest: false,
      changeRequestSubmitted: false,
      accountInfo: {} as AccountInfo,
      isLoaded: false
    };
  },
  beforeMount: async function () {
    const activeApps = (await api.Application.getActiveApplications()).data;
    this.isLoaded = true;

    if (activeApps.loanApplication) {
      this.app = activeApps.loanApplication;
      this.changeRequest.loanAmount = this.app.loanAmount ?? 0;
      this.changeRequest.paybackYears = this.app.paybackYears ?? 0;

      if (this.app.chosenOfferId) {
        this.$router.push("/loan/selected");
      }
    }
  },
  computed: {
    sortedOffers: function (): OfferView[] {
      return _.orderBy(
        this.app.offers,
        ["approvedAmount", "effectiveRate"],
        ["desc", "asc"]
      );
    },

    loanStatusText() {
      let status = "";

      if (this.app.loanStatus === 1) status = "FärdigBehandlad";

      if (this.app.loanStatus === 2) status = "Färdigbehandlad";

      if (this.app.loanStatus === 3) status = "Behandlas";

      if (this.app.loanStatus === 4) status = "Behandlas";

      return status;
    },

    //TODO make this as a component
    changeRequestLoanAmount: {
      get: function (): string {
        const value = this.changeRequest.loanAmount ?? 0;
        return formatterWithoutCurrency.format(value);
      },
      set: function (newvalue) {
        const value = this.takeOutspaceFromNumber(newvalue);
        this.changeRequest.loanAmount = Number(value);
      }
    }
  },
  methods: {
    getPaybackDurationText(offer: OfferView) {
      if (offer?.repaymentPeriod === undefined) return;
      if (offer.bank?.webDurationInMonths)
        return offer.repaymentPeriod + " månader";
      // TODO

      const repaymentPeriod = offer.repaymentPeriod;
      if (!repaymentPeriod) return "";
      if (
        (offer.bank?.name === "Collector" ||
          offer.bank?.name === "Spring Finance") &&
        repaymentPeriod < 24
      )
        return repaymentPeriod + " år";
      if (repaymentPeriod % 12) return repaymentPeriod + " månader";
      return repaymentPeriod / 12 + " år";
    },

    getTotalCost: function (offer: OfferView) {
      if (!offer.repaymentPeriod) return 0;

      let paybackYears = 0;
      if (
        offer.bank &&
        (offer.bank.name === "Collector" ||
          offer.bank.name === "Spring Finance") &&
        offer.repaymentPeriod < 24
      )
        paybackYears = offer.repaymentPeriod;
      else paybackYears = offer.repaymentPeriod / 12;

      return (offer.monthlyInstallment ?? 0) * paybackYears * 12;
    },

    formatApprovedAmount(value: number) {
      if (value != null) return value.toLocaleString();
    },
    formatMonthlyInstallment(value: number) {
      if (value != null) return Math.round(value).toLocaleString();
    },
    formatDecimal: function (value: number) {
      return (Math.round(value * 100) / 100).toFixed(2).replace(".", ",");
    },
    isExpanded: function (id: number) {
      return this.expandedOffers.indexOf(id) !== -1;
    },
    expand: function (id: number) {
      const index = this.expandedOffers.indexOf(id);
      if (index === -1) this.expandedOffers.push(id);
    },
    collapse: function (id: number) {
      const index = this.expandedOffers.indexOf(id);
      if (index !== -1) this.expandedOffers.splice(index, 1);
    },
    toggle: function (id: number) {
      this.isExpanded(id) ? this.collapse(id) : this.expand(id);
    },
    submitChange: function () {
      api.Loan.submitChangeRequest(this.changeRequest).then(() => {
        this.changeRequestSubmitted = true;
      });
    },

    format(value: number) {
      return formatterWithoutCurrency.format(value);
    },
    takeOutspaceFromNumber(number: string) {
      let value;
      if (typeof number == "number") {
        value = number;
      } else {
        const takeOutSpace = number.replace(/\s/g, "");
        value = takeOutSpace;
      }
      return value;
    }
  }
});
