import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-check" }
const _hoisted_2 = ["id", "checked"]
const _hoisted_3 = ["for"]
const _hoisted_4 = { class: "invalid-feedback" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      id: `checkbox-${_ctx.checkboxId}`,
      type: "checkbox",
      checked: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChanged && _ctx.onChanged(...args))),
      class: _normalizeClass(["form-check-input", { 'is-invalid': _ctx.error }])
    }, null, 42, _hoisted_2),
    _createElementVNode("label", {
      for: `checkbox-${_ctx.checkboxId}`,
      class: "form-check-label"
    }, [
      _renderSlot(_ctx.$slots, "label", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.label), 1)
      ])
    ], 8, _hoisted_3),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.error), 1)
  ]))
}