<template>
<div class="border-light border-top pt-3 mt-3">
  <div class="row">
    <div class="col-md-6 offset-md-3 text-center">
      <form @submit.prevent="submit">
        <div v-if="showPersonalNumber && !sameDevice" class="form-group mb-2">
          <label class="form-label">Personnummer</label>
          <input
            type="tel"
            numbers-only=""
            maxlength="12"
            class="form-control"
            v-model="personalNumber"
            placeholder="ÅÅÅÅMMDDXXXX"
          />
          <span class="alert-danger" v-if="invalidPersonalNumber"
            >Ange giltigt svenskt personnummer - format ÅÅÅÅMMDDXXXX</span
          >
        </div>
        <button
          class="btn btn-primary my-2"
          type="submit"
        >
          Start
        </button>
      </form>
    </div>
  </div>
</div>
</template>

<script>
import { defineComponent } from "vue";
import { isMobile } from "src/lib/util.ts";
import { ssn } from "src/lib/ssn.ts";

export default defineComponent({
  props: {
    chosenDevice: { type: String, required: false },
    showPersonalNumber: { type: Boolean, required: false }
  },
  data: function() {
    return {
      personalNumber: "",
      invalidPersonalNumber: false
    };
  },
  components: {},
  computed: {
    sameDevice: function() {
      return (
        (this.chosenDevice === "mobile" && isMobile.any()) ||
        (this.chosenDevice === "other" && !isMobile.any())
      );
    }
  },
  methods: {
    submit: function() {
      if (this.showPersonalNumber && !this.sameDevice) {
        this.invalidPersonalNumber = !ssn.isValid(this.personalNumber);
        if (this.invalidPersonalNumber) return;
      }

      // TODO remove Test: 197602017639

      this.$emit("start", this.personalNumber);
    }
  }
});
</script>
