
import { defineComponent } from "vue";
import { api, OfferView, SelectOffer } from "src/api";
import LoanSelectOfferForm from "@/components/Loan/LoanSelectOfferForm.vue";

export default defineComponent({
  props: {
    id:  { required: true } as any
  },
  components: { LoanSelectOfferForm },
  data: function () {
    return {
      loanStatus: -1,
      offer: { bank: {} } as OfferView,
      isLoading: false,
      offerSelectingError: false
    };
  },
  beforeMount: async function () {
    const activeApps = (await api.Application.getActiveApplications()).data;

    if (activeApps.loanApplication) {
      this.loanStatus = activeApps.loanApplication.loanStatus || -1;
      const offer = activeApps.loanApplication.offers?.find(
        (o) => o.id == this.$props.id
      );
      if (offer !== undefined) this.offer = offer;
      return offer;
    }
  },
  methods: {
    submit: function (selectOfferForm: SelectOffer) {
      this.offerSelectingError = false;
      this.isLoading = true;
      api.Loan.selectLoanOffer(this.$props.id, selectOfferForm).then(() => {
        this.isLoading = false;
        this.$router.push({ name: "loan_offer_selected" })
      }
      ).catch(() => {
        this.offerSelectingError = true;
        this.isLoading = false;
      });
    }
  }
});
