
import { defineComponent } from "vue";
import { api } from "src/api";
export default defineComponent({
  data() {
    return {
      feedback: "",
      feedbackSend: false
    };
  },
  methods: {
    sendFeedback: async function () {
      this.feedbackSend = false;
      await api.Mortgage.submitCustomerFeedback(this.feedback);
      this.feedback = "";
      this.feedbackSend = true;
    }
  }
});
