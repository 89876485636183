import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.SetSelected && _ctx.SetSelected(...args))),
    class: _normalizeClass({
            'btn btn-box active': _ctx.modelValue == _ctx.type,
            'btn btn-box': _ctx.modelValue != _ctx.type
          })
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          class: "icon",
          src: require('assets/icons/' + _ctx.icon)
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("strong", null, [
      _createElementVNode("small", null, _toDisplayString(_ctx.text), 1)
    ])
  ], 2))
}