import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = {
  key: 0,
  class: "content-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_MainMenu = _resolveComponent("MainMenu")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NavBar, {
      username: _ctx.user.name,
      onToggleMenu: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showMenu = !_ctx.showMenu)),
      showMenu: _ctx.showMenu
    }, null, 8, ["username", "showMenu"]),
    (_ctx.userStatusFetched)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_MainMenu, {
            username: _ctx.user.name,
            showMenu: _ctx.showMenu,
            badges: _ctx.badges
          }, null, 8, ["username", "showMenu", "badges"]),
          _createVNode(_component_router_view)
        ]))
      : _createCommentVNode("", true)
  ]))
}