import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  for: "",
  class: "form-label"
}
const _hoisted_2 = ["value", "placeholder"]
const _hoisted_3 = { class: "invalid-feedback" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", _hoisted_1, [
      _renderSlot(_ctx.$slots, "label", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.label), 1)
      ])
    ]),
    _createElementVNode("input", {
      class: _normalizeClass(["form-control", { 'is-valid': _ctx.valid, 'is-invalid': _ctx.error }]),
      onKeydown: _cache[0] || (_cache[0] = ($event: any) => (_ctx.OnlyNumbers($event))),
      type: "text",
      value: _ctx.inputValue,
      onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.checkIfEmpty && _ctx.checkIfEmpty(...args))),
      onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args))),
      placeholder: _ctx.placeholder
    }, null, 42, _hoisted_2),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.error), 1)
  ]))
}