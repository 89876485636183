
import { defineComponent } from "vue";
import { api, MessageView } from "src/api";
import MessageList from "components/Message/MessageList.vue";

export default defineComponent({
  data: function () {
    return {
      messages: [] as MessageView[],
      loaded: false
    };
  },
  components: { MessageList },
  mounted: function () {
    api.Message.getMessages().then((res) => {
      this.messages = res.data;
      this.loaded = true;
    });
  }
});
