
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    username: { type: String, required: false },
    showMenu: { type: Boolean, default: false }
  },
  methods: {
    onToggleMenu() {
      this.$emit("toggleMenu");
    },
    getSiteLink() {
      if (window.location.host.includes("localhost"))
        return "http://localhost:8001";

      if (window.location.host.includes("test-app"))
        return "https://test-www.ordnabolan.se";

      return "https://www.ordnabolan.se";
    }
  }
});
