export interface QuestionStep {
  key: string;
  title: string;
  subtitle?: string;
  questions: string[];
}

export const steps: QuestionStep[] = [
  {
    key: "purpose",
    title: "Lånesyfte",
    questions: ["Q54", "Q66", "Q67"]
  },
  {
    key: "income",
    title: "Inkomst",
    questions: [
      "Q22",
      "Q23",
      "Q24",
      "Q25",
      "Q26",
      "Q27",
      "Q28",
      "Q28b",
      "Q29",
      "Q30",
      "Q31",
      "Q32",
      "Q33",
      "Q34",
      "Q34b",
      "Q68",
      "Q70",
      "Q71",
    ]
  },
  {
    key: "housing_purchase",
    title: "Bostadsköp",
    questions: ["Q35", "Q36", "Q37", "Q38", "Q39", "Q40"]
  },
  //   {
  //     key: "housing_to_keep",
  //     title: "Housing to keep",
  //     questions: ["Q58", "Q59", "Q60", "Q61", "Q62"]
  //   },
  {
    key: "downpayment1",
    title: "Kontantinsats",
    subtitle: "Vänligen ange och fördela var din kontantinsats kommer från.",
    questions: ["Q1a", "Q1b", "Q1c", "Q1d", "Q1e", "Q1f"]
  },
  {
    key: "downpayment2",
    title: "Kontantinsats forts.",
    subtitle:
      "Vi är snart färdiga. Här följer några frågor på fördelningen av din kontantinsats.",
    questions: [
      "Q2",
      "Q3",
      "Q4",
      "Q5",
      "Q6",
      "Q7",
      "Q8",
      "Q9",
      "Q10",
      "Q11",
      "Q12",
      "Q13",
      "Q14",
      "Q15",
      "Q16",
      "Q18",
      "Q19",
      "Q20",
      "Q21",
      "Q64", 
      "Q65",
      "Q69",
      "Q72"
    ]
  },
  {
    key: "object",
    title: "Bostad",
    questions: ["Q45", "Q46", "Q47", "Q48", "Q49", "Q50", "Q51", "Q52", "Q53"]
  },
  // {
  //   key: "privateloan",
  //   title: "Lån/kredit",
  //   questions: ["Q41", "Q42"]
  // },
  {
    key: "other",
    title: "Övriga frågor",
    questions: [
      "Q44",
      "Q56",
      //   "Q56a",
      "Q57",
      "Q55"
      //   "Q58",
      //   "Q59",
      //   "Q60",
      //   "Q61",
      //   "Q62"
    ]
  },
  {
    key: "thankyou",
    title: "Tack",
    questions: []
  }
];
