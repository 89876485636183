
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    label: { type: String },
    rows: { type: Number, default: 3 },
    modelValue: { type: String }
  },
  methods: {
    onInput(event: { target: HTMLInputElement }) {
      this.$emit("update:modelValue", event.target.value);
    }
  }
});
