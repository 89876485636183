import {
  createRouter,
  //   createWebHistory,
  createWebHashHistory,
  RouteRecordRaw
} from "vue-router";
import Home from "components/Home.vue";
import Login from "components/Login.vue";
import Message from "components/Message/Message.vue";
import MessageView from "components/Message/MessageView.vue";
import MessageForm from "components/Message/MessageForm.vue";
import LoanOffers from "components/Loan/LoanOffers.vue";
import LoanSelectOffer from "components/Loan/LoanSelectOffer.vue";
import OfferSelected from "components/Loan/OfferSelected.vue";
import MortgageOfferSelected from "components/Mortgage/OfferSelected.vue";
// import MortgageSelectOffer from "components/Mortgage/SelectOffer.vue";
import MortgageOffers from "components/Mortgage/Offers.vue";
import Documents from "components/Documents.vue";
import PostSingle from "components/Post/PostSingle.vue";
import Profile from "components/Profile.vue";
import AdvisoryDocument from "components/Mortgage/AdvisoryDocument.vue";
import BookAdvisorTime from "components/Mortgage/BookAdvisorTime.vue";
import NewPropertyObject from "components/Mortgage/NewPropertyObject.vue";
import MortgageIndex from "components/Mortgage/MortgageIndex.vue";
import OfferDocuments from "components/Mortgage/OfferDocumentsIndex.vue";
import CustomerOffersIndex from "components/Mortgage/CustomerOffersIndex.vue";
import MortgageQuestions from "components/Mortgage/MortgageQuestions.vue";
import MortgageCalculationIndex from "components/Mortgage/MortgageCalculationIndex.vue"

export const routes: Array<RouteRecordRaw> = [
  { path: "/", name: "home", component: Home },
  {
    path: "/login",
    name: "login",
    component: Login,
    props: (route) => ({
      token: route.query.token,
      applicantId: route.query.id
    })
  },
  {
    path: "/login/sign",
    name: "login_sign",
    component: Login,
    props: { signing: true }
  },
  { path: "/message", name: "message_index", component: MessageView },
  { path: "/message/add", name: "message_add", component: MessageForm },
  {
    path: "/message/:id",
    name: "message_single",
    component: Message,
    props: true
  },
  { path: "/loan", name: "loan_index", component: LoanOffers },
  {
    path: "/loan/select/:id",
    name: "loan_offer_select",
    component: LoanSelectOffer,
    props: true
  },
  {
    path: "/loan/selected",
    name: "loan_offer_selected",
    component: OfferSelected
  },
  // {
  //   path: "/mortgage/select/:id",
  //   name: "mortgage_offer_select",
  //   component: MortgageSelectOffer,
  //   props: true
  // },
  {
    path: "/mortgage/selected",
    name: "mortgage_offer_selected",
    component: MortgageOfferSelected
  },
  { path: "/mortgage", name: "mortgage_index", component: MortgageIndex },
  {
    path: "/mortgage/document",
    name: "advisory_document_index",
    component: AdvisoryDocument
  },
  {
    path: "/mortgage/questions",
    name: "mortgage_questions",
    component: MortgageQuestions
  },
  //   {
  //     path: "/mortgage/home",
  //     name: "mortgage_home_index",
  //     component: MortgageIndex
  //   },
  {
    path: "/mortgage/offers",
    name: "mortgage_offers",
    component: MortgageOffers,
    props: { showAdditionalInformation: true }
  },
  { path: "/documents", name: "document_index", component: Documents },
  // { path: "/blog", name: "blog_index", component: PostNews }, hide for now
  {
    path: "/blog/:id",
    name: "blog_single",
    component: PostSingle,
    props: true
  },
  { path: "/profile", name: "profile_index", component: Profile },
  {
    path: "/mortgage/book-advisor",
    name: "mortgage_book_advisor",
    component: BookAdvisorTime
  },
  {
    path: "/mortgage/new-property-object",
    name: "new_property_object_index",
    component: NewPropertyObject
  },
  {
    path: "/mortgage/offer-documents",
    name: "offer_documents_index",
    component: OfferDocuments
  },
  {
    path: "/customer-offer",
    name: "customer_offer_index",
    component: CustomerOffersIndex
  },
  {
    path: "/mortgage/calculation",
    name: "mortgage_calculation_index",
    component: MortgageCalculationIndex
  }
];

const router = createRouter({
  // history: createWebHistory(),
  history: createWebHashHistory(),
  routes,
  linkActiveClass: "menu-item-targeted"
});

export default router;
