import App from "./App.vue";
import router from "./router";
import { createApp } from "vue";
// import axios from "axios";
// import VueAxios from "vue-axios";

// .use(
//     VueAxios,
//     axios.create({

//       withCredentials: true
//     })
//   )

var logLimit = 10;
var errorsLogged = 0;

var logUrl =
  window.location.hostname === "localhost"
    ? "http://localhost:5020/log/browser/error?context=mypages"
    : "/api/log/browser/error?context=mypages";

// global error handler: post error info to log on server
window.addEventListener("error", async function (event) {
  // prevent infinite logging
  errorsLogged++;
  if (errorsLogged > logLimit) return;

  var payload = {
    message: event.message,
    filename: event.filename,
    lineno: event.lineno,
    colno: event.colno,
    source: window.location.href
  };

  await fetch(logUrl, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(payload)
  });
});

// global error handler for exceptions thrown by promises (not caught in "error" listener)
window.addEventListener(
  "unhandledrejection",
  async function (event: PromiseRejectionEvent) {
    errorsLogged++;
    if (errorsLogged > logLimit) return;

    var payload = {
      reason: {
        message: event.reason.message,
        stack: event.reason.stack
      }
    };

    await fetch(logUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload)
    });
  }
);

createApp(App).use(router).mount("#app");
