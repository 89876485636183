
import { defineComponent } from "vue";
import Number from "../Controls/NumberInput.vue";
import TextField from "../Controls/TextInput.vue";
import SelectInput from "../Controls/SelectInput.vue";
import MortgageTopMenu from "../Controls/MortgageTopMenu.vue";
import { api, CustomerNewPropertyRequest, MortgageApplicationView } from "src/api";

export default defineComponent({
  data() {
    return {
      submitted: false,
      error: false,
      errorMessage: "",
      status: 0,
      statusText: "",
      property: {} as CustomerNewPropertyRequest,
      // TODO check if status should be numeric/enum
      options: [
        { id: 0, value: "Jag ska lägga bud på en bostad" },
        { id: 1, value: "Jag har lagt bud på en bostad" },
        { id: 2, value: "Jag har vunnit budgivningen" },
        { id: 3, value: "Jag ska skriva kontrakt" },
        { id: 4, value: "Jag ska på visning" }
      ],
      app: {} as MortgageApplicationView,
    };
  },
  components: {
    Number,
    SelectInput,
    TextField,
    MortgageTopMenu
  },

  methods: {
    load: async function () {
      const activeApps = (await api.Application.getActiveApplications()).data;
      if (activeApps.mortgageApplication)
        this.app = activeApps.mortgageApplication;
    },
    submit() {
      this.error = false;
      //   let statusText = this.getPropertyStatusText(this.status);
      let isValid = this.checkForm();

      let request = {
        downPayment: this.property.downPayment,
        url: this.property.url,
        loanAmount: this.property.loanAmount,
        status: this.statusText
      };

      if (isValid) {
        api.Mortgage.submitNewPropertyObject(request)
          .then(() => {
            this.submitted = true;
            this.error = false;
            this.errorMessage = "";
          })
          .catch(() => {
            this.errorMessage =
              "Något gick fel, vänligen kontakta oss på 020-25 26 26";
          });
      }
    },

    goToHome() {
      this.$router.push("/mortgage");
    },

    checkForm() {
      if (
        !this.property.downPayment ||
        !this.property.url ||
        !this.property.loanAmount
      ) {
        this.error = true;
        return false;
      }

      return true;
    },

    checkStatus() {
      this.statusText = this.options[this.status].value;
    }
  },
  beforeMount: async function () {
    this.load();
  }
});
