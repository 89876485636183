
import { defineComponent } from "vue";
import Tooltip from "src/components/Controls/Tooltip.vue";

let idSequence = 0;

export default defineComponent({
  props: {
    modelValue: Boolean,
    label: String,
    options: Array,
    tooltip: String
  },
  components: { Tooltip },
  emits: ["update:modelValue"],
  data() {
    return { elementId: idSequence++ };
  }
  //   setup(props, { emit }) {
  //     function emitValue(value: boolean) {
  //       emit("update:modelValue", value);
  //     }

  //     function onChanged(e: InputEvent) {
  //       const target = e.currentTarget as HTMLInputElement;
  //       emitValue(target?.checked);
  //     }

  //     return {
  //       emitValue,
  //       onChanged
  //     };
  //   }
});
