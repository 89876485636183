
import { defineComponent } from "vue";
import { api, PostView } from "src/api";

export default defineComponent({
  props: {
    id: Number
  },
  data: function () {
    return {
      post: {} as PostView
    };
  },
  components: {},
  methods: {},
  mounted: async function () {
    if (this.$props.id) this.post = (await api.Post.get(this.$props.id)).data;
  }
});
