
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    modelValue:String, 
    type:String,
    icon: String,
    text: String,
  },
  methods: {
    SetSelected() {
      this.$emit("update:modelValue", this.type);
    }
  }
});
