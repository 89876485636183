import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"width":"100%","height":"19px","text-align":"center","margin-top":"-20px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle([{"pwidth":"100%","height":"20px"}, { 'background-color': _ctx.backgroundColor }])
  }, [
    _createElementVNode("div", {
      style: _normalizeStyle([{"width":"100%","height":"19px"}, { 'background-color': _ctx.trackColor, width: _ctx.progress + '%' }])
    }, null, 4),
    _createElementVNode("div", _hoisted_1, " laddar upp " + _toDisplayString(_ctx.progress) + " % ", 1)
  ], 4))
}