
import { defineComponent } from "vue";
import { Advisory, api, MortgageApplicationView } from "src/api";
import moment from "moment";
export default defineComponent({
  data() {
    return {
      app: {} as MortgageApplicationView,
      loaded: false
    };
  },
  methods: {
    load: async function () {
      const activeApps = (await api.Application.getActiveApplications()).data;
      if (activeApps.mortgageApplication)
        this.app = activeApps.mortgageApplication;
    },
    formatDate: function (advisory: Advisory) {
      return moment(advisory.createdAt).format("YYYY-MM-DD HH:mm:ss");
    },
    showAdvisoryDocuments: function (): boolean {
      if (this.app.advisories != null) return this.app.advisories.length > 0;
      return false;
    },
    async downloadAdvisoryDocument(advisory: Advisory) {
      const url = await api.Mortgage.downloadAdvisory(`${advisory.id}`);
      // ts fix because data is void in generated api
      window.open(url.config.url, url.data as unknown as string);
    }
  },
  beforeMount: async function () {
    this.load();
  }
});
