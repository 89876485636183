export const ssn = {
  isValid: function (personalNumber: string) {
    // test dummy
    if (personalNumber === "5555555555") return true;

    const digits = personalNumber
      .replace(/\D/g, "") // strip out all but digits
      .split("") // convert string to array
      .reverse() // reverse order for Luhn
      .slice(0, 10); // keep only 10 digits (i.e. 1977 becomes 77)

    if (digits.length != 10) return false;

    const sum = digits
      .map(function (n) {
        return Number(n);
      })
      .reduce(function (previous, current, index) {
        if (index % 2) current *= 2;
        if (current > 9) current -= 9;
        return previous + current;
      });

    return 0 === sum % 10;
  }
};
