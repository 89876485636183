
import { defineComponent } from "vue";
import MortgageCalculation from "./MortgageCalculation.vue";
import MortgageTopMenu from "../Controls/MortgageTopMenu.vue";
export default defineComponent({
  props: ["app", "label"],
  components: {
    MortgageCalculation,
    MortgageTopMenu
  },
  data() {
    return {
      currentPage: this.app.showMortgageCalculationForCanceledApp
        ? "CalculateInterest"
        : "CalculateCosts",
      isMobileDevice: false 
    };
  },
  methods: {
    setCurrentPage(value: string) {
      this.currentPage = value;
    },
    checkIfMobileDevice(){
      var userAgent = navigator.userAgent
      var devicesRegex = /android|iphone|kindle|ipad/i;
      this.isMobileDevice = devicesRegex.test(userAgent); 
    }
  },
  watch: {
    "navigator.userAgent": {
      handler: "checkIfMobileDevice",
      immediate: true,
    },
  },
});
