
import { defineComponent } from "vue";
import { api } from "src/api";
import { userStore } from "src/store/UserStore";

export default defineComponent({
  props: ["username", "showMenu", "badges"],
  components: {},
  methods: {
    logout: async function () {
      await api.Auth.logout();
      window.location.reload();
    },
    isLinkActive(linkPath: string) {
      const path = this.$router?.currentRoute.value.path;
      if (!path) return false;

      // fix: both routes have 'mortgage' in path
      if (linkPath == "mortgage" && path.indexOf("mortgage/document") != -1)
        return false;
      return path.indexOf(linkPath) != -1;
    },
    markMyPagesOfferAsViewed(){
      var state = userStore.getState();
      if(state.appType == "Mortgage" && !state.myPagesOfferViewed){
        api.Mortgage.mortgageOfferPageViewed(true).then(()=>{
          this.badges.customerOfferCount = 0;
        })
      }
      if(state.appType == "Loan" && !state.myPagesOfferViewed){
        api.Loan.loanOfferPageViewed(true).then(()=>{
          this.badges.customerOfferCount = 0;
        })
      }
      if(state.appType == "MortgageAndLoan" && !state.myPagesOfferViewed){
        api.Loan.loanOfferPageViewed(true)
        api.Mortgage.mortgageOfferPageViewed(true).then(()=>{
          this.badges.customerOfferCount = 0;
        })
      }
    }
  }
});
