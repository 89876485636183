
import { defineComponent } from "vue";
import MainMenu from "./components/MainMenu.vue";
import NavBar from "./components/NavBar.vue";
import { api } from "src/api";
import { userStore } from "src/store/UserStore";
import { giosg } from "src/lib/giosg";

try {
  giosg.wrapGetActiveApplications(api.Application);
} catch (e) {
  console.log(e);
}

export default defineComponent({
  name: "App",
  components: { MainMenu, NavBar },
  data: function () {
    return {
      userStatusFetched: false,
      showMenu: false,
      badges: {
        messages: 0,
        private: 0,
        mortgage: 0,
        advisoryDocuments: 0,
        customerOfferCount: 0
      }
    };
  },
  computed: {
    user() {
      return userStore.getState();
    },
    isSignedIn() {
      const user = userStore.getState();
      return user.name != null && user.name.length > 0;
    }
  },
  methods: {
    getUserStatus: async function () {
      // TODO workaround, because this.$route.nanme is undefined
      // const isLoginRoute = this.$route.name?.toString().indexOf("login") === 0;
      const isLoginRoute = window.location.hash.indexOf("login") !== -1;

      // check if user is logged in
      try {
        const userResponse = await api.Auth.getUser();

        this.userStatusFetched = true;
        if (!userResponse.data.name) {
          if (!isLoginRoute && this.$router)
            this.$router.push({ name: "login" });
          return;
        }
        // this.user.name = userResponse.data.name;

        userStore.loginUser(userResponse.data.name);
        userStore.setMypagesOfferViewed(
          userResponse.data.appType ?? "",
          userResponse.data.myPagesOfferPageViewed
        );

        const countResponse = await api.Message.countUnread();
        if (countResponse.data) this.badges.messages = countResponse.data;
        else this.badges.messages = 0;

        const advisoryCountResponse =
          await api.Mortgage.getUnReadAdvisoryCount();
        if (advisoryCountResponse.data)
          this.badges.advisoryDocuments = advisoryCountResponse.data;
        else this.badges.advisoryDocuments = 0;

        if (!userResponse.data.myPagesOfferPageViewed)
          this.badges.customerOfferCount = 4;
        else this.badges.customerOfferCount = 0;

        giosg.submitVisitorData({ username: userResponse.data.name });
      } catch (e) {
        this.userStatusFetched = true;
        if (!isLoginRoute && this.$router) this.$router.push({ name: "login" });
      }

      //console.log(this.badges);
      // TODO consider store for global state
      // this.$bus.$on("messageRead", function () {
      //   if (vm.badges.messages > 0) vm.badges.messages -= 1;
      // });
    }
  },
  watch: {
    $route: async function () {
      this.showMenu = false;
      await this.getUserStatus();
    }
  }
});
