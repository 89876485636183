
import { defineComponent } from "vue";
import PhoneCard from "../PhoneCard.vue";
export default defineComponent({
  props: ["app"],
  components: {
    PhoneCard
  },
  methods: {
    goToPage() {
      window.open("https://www.familjensjurist.se/ordna-bolan/");
    }
  }
});
