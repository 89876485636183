
import { defineComponent } from "vue";
import PhoneCard from "../PhoneCard.vue";
import EmailCard from "../EmailCard.vue";
export default defineComponent({
  props: ["app"],
  components: {
    PhoneCard,
    EmailCard
  },
  methods: {
    goToPage() {
      window.open("https://www.skistar.com/sv/skistarliving/kop/?st=4");
    }
  }
});
