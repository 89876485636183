
import { defineComponent } from "vue";
// import { api } from "src/api";
import Tooltip from "src/components/Controls/Tooltip.vue";
import Number from "@/components/Controls/NumberInput.vue";
import {
  api,
  ApplicationAdditionsForm,
  MortgageApplicationView,
  MyPagesRequiredFields
} from "src/api";

export default defineComponent({
  components: { Tooltip, Number },
  props: {
    app: { object: () => ({} as MortgageApplicationView), required: true }
  },
  data() {
    return {
      form: {} as ApplicationAdditionsForm,
      required: {} as MyPagesRequiredFields,
      submitted: false,
      submitSuccess: false,
      submitError: false
    };
  },

  computed: {
    totalDownPayment: function (): number {
      return (
        (this.form.downPaymentFromGift || 0) +
        (this.form.downPaymentFromHeritage || 0) +
        (this.form.downPaymentFromHomeSale || 0) +
        (this.form.downPaymentFromLoan || 0) +
        (this.form.downPaymentFromOther || 0) +
        (this.form.downPaymentFromSavings || 0)
      );
    },
    downPaymentToEnter: function (): number {
      const result =
        ((this.app as any).downPayment || 0) - this.totalDownPayment;
      return result >= 0 ? result : 0;
    },
    valid: function (): boolean {
      return this.isValid();
    }
  },

  watch: {
    app: {
      handler(appView: MortgageApplicationView) {
        if (appView.applicationAdditionsForm) {
          this.form = appView.applicationAdditionsForm;
          //   if (appView.requiredFields) this.required = appView.requiredFields;

          // default answer to "yes"
          if (this.form.propertyHavingOtherOwner === null)
            this.form.propertyHavingOtherOwner = true;
        }
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    isRequired: function (key: string): boolean {
      if (window.location.hash.indexOf("debug") !== -1) return true;
      return (this.required as any)[key];
    },
    isValid: function () {
      for (const ref in this.$refs) {
        const input = this.$refs[ref] as unknown as ValidatableInput;
        if (!input.isValid) return false;
      }
      return true;
    },
    submit: async function () {
      if (!this.submitted) {
        this.submitted = true;
        for (const ref in this.$refs) {
          const input = this.$refs[ref] as unknown as ValidatableInput;
          console.log("setting dirty " + ref);
          input.isDirty = true;
        }
      }

      if (this.isValid()) {
        console.log("isValid TRUE, submitting");
        await api.Mortgage.submitApplicationAdditions(this.form);
        this.submitSuccess = true;
        this.$emit("submit");
      } else {
        this.submitError = true;
      }
    }
  }
});

interface ValidatableInput {
  isValid: boolean;
  isDirty: boolean;
}
