
import { defineComponent, PropType } from "vue";
import { OfferView, SelectOffer, AccountInfo } from "src/api";
import AccountInfoForm from "src/components/Loan/AccountInfoForm.vue";
import BackButton from "src/components/Controls/BackButton.vue";
// import InsuranceForm from "src/components/Loan/InsuranceForm.vue";
import CheckboxInput from "src/components/Controls/CheckboxInput.vue";

export default defineComponent({
  props: {
    offer: { type: Object as PropType<OfferView> },
    loanStatus: { type: Number, required: true, default: -1 },
    offerSelectingError: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false }
  },
  components: {
    AccountInfoForm,
    BackButton,
    // InsuranceForm,
    CheckboxInput
  },
  data: function () {
    return {
      agreeTerms: false,
      selectOfferForm: {} as SelectOffer,
      isValid: true,
    };
  },
  computed: {
    errors(): String[] {
      //   const errors = new Array<String>();
      //   if ()
      //   return errors;
      return [];
    },
    valid() {
      // is account info form valid
      if (
        this.offer?.bank?.showAccountDetailsForPayment &&
        !this.validate(this.selectOfferForm.account)
      ) {
        return false;
      }

      // is insurance form valid
      //   if (
      //     this.selectOfferForm.withInsurance &&
      //     !this.validate(this.selectOfferForm.insuranceAccount)
      //   ){
      //      return false;
      //   }
      if (!this.agreeTerms) return false;
      return true;
    }
  },
  mounted() {
    console.log(this.$refs.accountInfo, "mounted");
  },
  methods: {
    validate(form: any): boolean {
      if (
        form?.accountNumber == null ||
        form?.accountNumber == "" ||
        form?.accountNumber == "undefined"
      )
        return false;
      if (
        form?.clearingNumber == null ||
        form?.clearingNumber == "" ||
        form?.clearingNumber == "undefined" ||
        form?.clearingNumber.toString().length > 5
      )
        return false;
      if (
        form?.bankCode == null ||
        form?.bankCode == "" ||
        form?.bankCode == "undefined"
      )
        return false;

      form.accountNumber = form.accountNumber.toString();
      form.clearingNumber = form.clearingNumber.toString();
      return true;
    },
    submit: function () {
      this.isValid = this.valid;
      if (!this.isValid) return;
      this.$emit("submit", this.selectOfferForm);
    }
  },
});
