import { AxiosResponse } from "axios";
import {
  ApplicationApi,
  CustomerApplicationsView,
  CustomerStatus,
  Housing,
  MortgageApplicationView
} from "src/api/gen/api";

interface ChatInteractions {
  sfLead: boolean;
  renegotiate: boolean;
  purchase: boolean;
  promissoryLetter: boolean;
  offersExpired: boolean;
  guidePromissoryLetter: boolean;
  guidePurchase: boolean;
  guideRenegotiate: boolean;
}

declare global {
  interface Window {
    _giosg(...args: any[]): void;
    chatInteractions: ChatInteractions;
    setChatInteractions(app: MortgageApplicationView): void;
  }
}

window.chatInteractions = {} as ChatInteractions;

window.setChatInteractions = (app: MortgageApplicationView) => {
  window.chatInteractions = {} as ChatInteractions;

  // Loan application to old - Pops up when all offers are due/expired. Look at time left on loan proposals under “Låneförslag”.
  if (
    app.customerStatus == CustomerStatus.PresentOffer &&
    app.offers &&
    app.offers.length > 0 &&
    app.offers.every((off) => (off.daysUntilDueDate || 0) <= 0)
  )
    window.chatInteractions.offersExpired = true;

  if (app.customerStatus == CustomerStatus.PresentOffer) {
    window.chatInteractions.guidePromissoryLetter =
      app.purpose == "PromissoryLetter";
    window.chatInteractions.guidePurchase = app.purpose == "Purchase";
    window.chatInteractions.guideRenegotiate = app.purpose == "Renegotiate";
  }

  if (app.customerStatus == CustomerStatus.NoContact) {
    if (app.purpose == "PromissoryLetter" || app.purpose == "Purchase")
      // SF lead - current housing is house or condominium and housing is not being kept, status is No contact. Loan purpose is Prom.letter or Purchase.
      window.chatInteractions.sfLead =
        (!app.mainKeepCurrentHousing &&
          (app.mainHousing == Housing.House ||
            app.mainHousing == Housing.Condominium)) ||
        false;

    // Interactions for renegotiate - Loan purpose is renegotiate. Status No contact.
    if (app.purpose == "Renegotiate")
      window.chatInteractions.renegotiate = true;
    if (app.purpose == "Purchase") window.chatInteractions.purchase = true;
    if (app.purpose == "PromissoryLetter")
      window.chatInteractions.promissoryLetter = true;
  }
};

let _originalGetActiveApplications: (
  options?: any
) => Promise<AxiosResponse<CustomerApplicationsView>>;

export const giosg = {
  // Submits extra visitor data (username, application IDs) to giosg.
  // More here: https://support.giosg.com/knowledge/visitor-variables
  submitVisitorData: function (data: object) {
    // TODO room id is hardcoded for now
    try {
      window._giosg("visitor", "submit", data, "plain", false, [
        { id: "motj32e2w72m5pclnaaafrhylnkjp5yr5wfysascvqjaabim" }
      ]);
    } catch (e) {
      console.log(e);
      console.log("Could not submit giosg visitor data");
    }
  },

  // submits PL or ML application IDs to giosg each time original getActiveApplications function is called
  // sets chat interaction states
  wrapGetActiveApplications: function (api: ApplicationApi) {
    _originalGetActiveApplications = api.getActiveApplications;

    api.getActiveApplications = function (options?: any) {
      const resultPromise = _originalGetActiveApplications.bind(this)(options);
      resultPromise.then((result) => {
        if (result.data?.loanApplication?.loanApplicationId)
          giosg.submitVisitorData({
            loanApplication: result.data?.loanApplication?.loanApplicationId
          });
        if (result.data?.loanApplication?.assignedTo)
          giosg.submitVisitorData({
            loanAssignedTo: result.data?.loanApplication?.assignedTo
          });
        if (result.data?.mortgageApplication?.applicationId)
          giosg.submitVisitorData({
            mortgageApplication: result.data?.mortgageApplication?.applicationId
          });
        if (result.data?.mortgageApplication?.assignedTo)
          giosg.submitVisitorData({
            MortgageAssignedTo: result.data?.mortgageApplication?.assignedTo
          });
        if (result.data.mortgageApplication)
          window.setChatInteractions(result.data.mortgageApplication);
      });
      return resultPromise;
    };
  }
};
