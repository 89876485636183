<template>
  <div class="content-section col">
    <div class="container blog-post-container">
      <h1 class="header-content">
        Vi på Ordna tackar för att vi fick hjälpa till med ditt nya privatlån
      </h1>
    </div>

    <div>
      <hr />
    </div>

    <div class="container blog-post-container">
      <div>
        <p>
          Lånehandlingarna är nu på väg till er per brev och/eller mejl. Ni
          behöver signera med hjälp av Bank-ID, eller signera och returnera
          lånehandlingarna per brev till långivaren. Glöm inte att skicka med
          eventuella kompletteringar, för snabb och smidig utbetalning av lånet.
        </p>
        <p>
          Har du funderingar när du ska fylla i lånehandlingarna? Kontakta oss!
        </p>

        <div class="feat-contact-box">
          <p><i class="fa fa-2x fa-address-card muted"></i></p>
          <p class="append-vertical">
            www.ordnabolan.se<br />
            020-25 26 26
          </p>
          <p class="append-vertical">
            <img
              src="@/assets/icons/logo-2.svg"
              alt="footer-logo-black"
              width="215"
              class="img-fluid"
            />
          </p>
        </div>

        <p>
          Spara gärna vårt telefonnummer eller ta en skärmbild på detta så har
          du våra kontakter för dina framtida lånebehov.
        </p>
      </div>
    </div>
  </div>
</template>
