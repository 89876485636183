
import { defineComponent } from "vue";
import MortgageTopMenu from "../Controls/MortgageTopMenu.vue";
import SelectInput from "../Controls/SelectInput.vue";
import Tooltip from "../Controls/Tooltip.vue";
import { api, InterestRateProposalReply } from "src/api";
import NumberInput from "../Controls/NumberInput.vue";
import { max } from "lodash";

export default defineComponent({
  props: ["app", "calculationType"],
  components: {
    MortgageTopMenu,
    SelectInput,
    NumberInput,
    Tooltip
  },
  data() {
    return {
      interestRates: [] as InterestRateProposalReply[],
      selectedInterestRateIndex: 0,
      selectInterestRate: null as any,
      showInterestRateButton: false,
      form: { loanAmount: null, propertyEstimateValue: null },
      btnText:
        this.calculationType == "CalculateInterest"
          ? "Flytta bolån"
          : "Ansök om lånelöfte",
      amortizationRate: null as any,
      formLabels: this.getFormLabels(),
      totalCost: null as any,
      loanAmountIsGreaterThanMaxLoanAmount: false
    };
  },
  methods: {
    setForm() {
      if (this.app.showMortgageCalculationForCanceledApp) {
        this.form.loanAmount = null;
        this.form.propertyEstimateValue = null;
        return;
      }
      if (this.calculationType == "CalculateCosts") {
        this.form.loanAmount =
          this.app.purpose == "Renegotiate"
            ? this.app.loanAmount
            : this.app.downPayment;
        this.form.propertyEstimateValue = this.app.propertyValue;
      }
      if (this.calculationType == "CalculateInterest") {
        this.form.loanAmount = this.app.loanAmount;
        this.form.propertyEstimateValue = this.app.propertyValue;
      }
    },
    calculateInterestRate: async function () {
      if (!this.IsValid()) return;
      var request = this.getRequestValues();
      this.compareMaxLoanAnountAgainstLoanAmount(request);
      var result = await api.Mortgage.getInterestRateProposal(request);
      this.interestRates = result.data;
      this.selectedInterestRateIndex = 0;
      this.setInterestRate();
    },
    compareMaxLoanAnountAgainstLoanAmount(request: any) {
      if (
        this.app.maxLoanAmount &&
        request.loanAmount > this.app.maxLoanAmount 
        && !this.app.showMortgageCalculationForCanceledApp
      ) {
        this.loanAmountIsGreaterThanMaxLoanAmount = true;
      } else {
        this.loanAmountIsGreaterThanMaxLoanAmount = false;
      }
    },
    getMaxLoanAmountText() {
      if (this.app.purpose == "Renegotiate")
        return `Notera att ditt maximala lånebelopp är ${this.formatCurrency(
          this.app.maxLoanAmount
        )} Kr.`;

      var lowestLoanAmount =
        this.form.propertyEstimateValue == null
          ? 0
          : this.form.propertyEstimateValue - this.app.maxLoanAmount;
      return `Du behöver gå in med minst ${this.formatCurrency(
        lowestLoanAmount
      )} kr om du vill köpa för ${this.formatCurrency(
        this.form.propertyEstimateValue ?? 0
      )} kr.`;
    },
    calculateAmortizationRate: async function () {
      if (this.selectInterestRate == null) return;
      var request = this.getRequestValues();
      var result = await api.Mortgage.getAmortizationCost(
        request.loanAmount,
        this.selectInterestRate.interestRate,
        request.loanToValue
      );
      this.amortizationRate = result.data;
      this.calculateTotalCost();
    },
    IsValid() {
      return (
        this.form.loanAmount != null &&
        this.form.loanAmount > 0 &&
        this.form.propertyEstimateValue != null &&
        this.form.propertyEstimateValue > 0
      );
    },
    setInterestRate() {
      this.selectInterestRate =
        this.interestRates[this.selectedInterestRateIndex];
      if (
        this.selectInterestRate != null &&
        this.calculationType == "CalculateCosts"
      ) {
        this.calculateAmortizationRate();
      }
    },
    getFormLabels() {
      var loanPurpose = ["Purchase", "PromissoryLetter"];
      if (this.calculationType == "CalculateInterest")
        return {
          title: "Räkna på vilken ränta du kan få",
          description:
            "Fyll i uppgifterna nedan för att se din nya ränta. Observera att det är ett preliminärt besked och att det görs en sedvanlig kreditprövning innan bolånet beviljas.",
          proppertyEstimateValueLabel: "Uppskattat värde på din bostad",
          loanAmountLabel:
            this.app.showMortgageCalculationForCanceledApp ||
            this.app.purpose == "Renegotiate"
              ? "Storlek på befintliga lån"
              : "Storlek på bolån",
          propertyEstimateValueTooltip:
            this.app.showMortgageCalculationForCanceledApp ||
            this.app.purpose == "Renegotiate"
              ? String.raw`Ange det ungefärliga värdet på din bostad. Värderingar är inte exakta. 
                    Det behöver inte det värde som du anger här heller vara. Om du vill känna dig 
                    tryggare kring värderingen kan vi hjälpa dig att kolla upp det. 
                    För att det ska bli så smidigt som möjligt för dig kan du efter att ha skickat 
                    in ansökan ringa oss. Vi kan då ordna en statistisk värdering direkt per telefon. 
                    Vi kan också ordna en mäklarvärdering genom Svensk Fastighetsförmedling om du behöver det.`
              : null,
          loanAmountLabelTooltip:
            this.app.showMortgageCalculationForCanceledApp ||
            this.app.purpose == "Renegotiate"
              ? String.raw`Om du vill utöka ditt bolån anger du totalen här. Dvs befintliga lån plus 
                    utökning. Har du till exempel 2 000 000 i lån och vill låna ytterligare 500 000 kr - för att renovera, 
                    köpa en bil eller betala av privatlån - så anger du 2 500 000 kr.`
              : null
        };
      return {
        title: "Räkna vad ett nytt bolån skulle kosta",
        description:
          "Fyll i uppgifterna nedan för att se din nya ränta och kostnad. Observera att det är ett preliminärt besked och att det görs en sedvanlig kreditprövning innan bolånet beviljas.",
        proppertyEstimateValueLabel:
          this.app.showMortgageCalculationForCanceledApp ||
          loanPurpose.includes(this.app.purpose)
            ? "Uppskattat pris ny bostad"
            : "Värde på bostad",
        loanAmountLabel:
          this.app.showMortgageCalculationForCanceledApp ||
          loanPurpose.includes(this.app.purpose)
            ? "Kontantinsats"
            : "Bolån",
        propertyEstimateValueTooltip:
          "Ange det pris du kan tänka dig att köpa en bostad för.",
        loanAmountLabelTooltip: null
      };
    },
    getRequestValues(): any {
      if (
        this.form.loanAmount == null ||
        this.form.propertyEstimateValue == null
      )
        return;
      var loanAmount =
        this.calculationType == "CalculateInterest"
          ? this.form.loanAmount
          : this.app.purpose == "Renegotiate" &&
            !this.app.showMortgageCalculationForCanceledApp
          ? this.form.loanAmount
          : this.form.propertyEstimateValue - this.form.loanAmount;

      var loanToValue = Math.floor(
        (loanAmount / this.form.propertyEstimateValue) * 100
      );
      return {
        loanAmount: loanAmount,
        loanToValue: loanToValue,
        offer: this.app.offerWithLowestInterestRate,
        propertyEstimateValue: this.form.propertyEstimateValue
      };
    },
    gotoApplicationForm() {
      if (
        this.form.loanAmount == null ||
        this.form.propertyEstimateValue == null
      )
        return;

      var host = window.location.hostname;
      var campain =
        this.calculationType == "CalculateInterest"
          ? "&utm_campaign=Newsletter+1+-+Renegotiate"
          : "&utm_campaign=Newsletter+1+-+Buy";

      var path = "http://localhost:8001/bolan/";

      if (host.indexOf("app.ordnabolan.se") != -1)
        path = "https://www.ordnabolan.se/bolan/";

      if (host.indexOf("test-app.ordnabolan.se") != -1)
        path = "https://test-www.ordnabolan.se/bolan/";

      window.open(
        `${path}?utm_source=MA&utm_medium=Email${campain}&propertyEstimateValue=${this.form.propertyEstimateValue}&loanAmount=${this.form.loanAmount}&intetestRate=${this.selectInterestRate.interestRate}&email=${this.app.applicant.email}&mobilePhone=${this.app.applicant.phone}&purpose=${this.app.purpose}`
      );
    },
    calculateTotalCost() {
      this.totalCost =
        this.amortizationRate.monthlyCost + this.app.propertyMonthlyCost;
    },
    formatCurrency(value: number) {
      return value.toLocaleString("sv-SE");
    }
  },
  mounted() {
    this.setForm();
    this.calculateInterestRate();
  }
});
