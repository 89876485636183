import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MortgageQuestionsSteps = _resolveComponent("MortgageQuestionsSteps")!

  return (_ctx.loaded)
    ? (_openBlock(), _createBlock(_component_MortgageQuestionsSteps, _mergeProps({
        key: 0,
        onSubmitStep: _ctx.onSubmitStep
      }, _ctx.questionsTerms, {
        questions: _ctx.questions,
        formValue: _ctx.form,
        isCoApplicant: _ctx.isCoApplicant
      }), null, 16, ["onSubmitStep", "questions", "formValue", "isCoApplicant"]))
    : _createCommentVNode("", true)
}