/* tslint:disable */
/* eslint-disable */
/**
 * Direkto.Web
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AccountInfo
 */
export interface AccountInfo {
    /**
     * 
     * @type {string}
     * @memberof AccountInfo
     */
    ssn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountInfo
     */
    accountNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountInfo
     */
    clearingNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AccountInfo
     */
    bankCode?: string | null;
}
/**
 * 
 * @export
 * @interface AdditionalMortgageLoan
 */
export interface AdditionalMortgageLoan {
    /**
     * 
     * @type {Housing}
     * @memberof AdditionalMortgageLoan
     */
    housing?: Housing;
    /**
     * 
     * @type {number}
     * @memberof AdditionalMortgageLoan
     */
    amount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdditionalMortgageLoan
     */
    monthlyCost?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdditionalMortgageLoan
     */
    amortizationAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdditionalMortgageLoan
     */
    interestRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdditionalMortgageLoan
     */
    ownerShipShare?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdditionalMortgageLoan
     */
    taxationValue?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AdditionalMortgageLoan
     */
    keepCurrentHousing?: boolean | null;
}
/**
 * 
 * @export
 * @interface AdditionalPrivateLoan
 */
export interface AdditionalPrivateLoan {
    /**
     * 
     * @type {number}
     * @memberof AdditionalPrivateLoan
     */
    amount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdditionalPrivateLoan
     */
    monthlyCost?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdditionalPrivateLoan
     */
    amortizationAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdditionalPrivateLoan
     */
    interestRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AdditionalPrivateLoan
     */
    ownerShipShare?: number | null;
    /**
     * 
     * @type {PrivateLoanType}
     * @memberof AdditionalPrivateLoan
     */
    loanType?: PrivateLoanType;
    /**
     * 
     * @type {LoanOwnerType}
     * @memberof AdditionalPrivateLoan
     */
    loanOwnerType?: LoanOwnerType;
    /**
     * 
     * @type {CarOwnType}
     * @memberof AdditionalPrivateLoan
     */
    carOwnType?: CarOwnType;
    /**
     * 
     * @type {boolean}
     * @memberof AdditionalPrivateLoan
     */
    redeemLoan?: boolean | null;
}
/**
 * 
 * @export
 * @interface Advisory
 */
export interface Advisory {
    /**
     * 
     * @type {string}
     * @memberof Advisory
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof Advisory
     */
    applicationId?: number;
    /**
     * 
     * @type {string}
     * @memberof Advisory
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Advisory
     */
    createdAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Advisory
     */
    showOnMyPage?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Advisory
     */
    notes?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Advisory
     */
    isRead?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum AmortizationRule {
    Fi2018 = 'FI2018',
    Fi2016 = 'FI2016',
    Skb70 = 'SKB70',
    Other = 'Other'
}

/**
 * 
 * @export
 * @interface ApplicantAdditionsForm
 */
export interface ApplicantAdditionsForm {
    /**
     * 
     * @type {string}
     * @memberof ApplicantAdditionsForm
     */
    profession?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicantAdditionsForm
     */
    hasProfessionalDegree?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicantAdditionsForm
     */
    professionalExperience?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicantAdditionsForm
     */
    hasOtherIncome?: boolean | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ApplicantAdditionsForm
     */
    otherIncomeSource?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicantAdditionsForm
     */
    otherIncomeBeforeTax?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicantAdditionsForm
     */
    organizationAnnualTurnover?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicantAdditionsForm
     */
    organizationAnnualTurnoverDescription?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicantAdditionsForm
     */
    organizationNumberOfEmployees?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicantAdditionsForm
     */
    expectedDividend?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicantAdditionsForm
     */
    incomeBeforeTaxWhenRetired?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicantAdditionsForm
     */
    hasUnemploymentFund?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicantAdditionsForm
     */
    guaranteedIncomeBeforeTax?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ApplicantAdditionsForm
     */
    incomeDeviationReason?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ApplicantAdditionsForm
     */
    incomeDeviationReason2?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicantAdditionsForm
     */
    isLoanGuarantor?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicantAdditionsForm
     */
    incomeAfterRetirement?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicantAdditionsForm
     */
    incomeDuration?: number | null;
}
/**
 * 
 * @export
 * @interface ApplicantCriteria
 */
export interface ApplicantCriteria {
    /**
     * 
     * @type {Array<Employment>}
     * @memberof ApplicantCriteria
     */
    employments?: Array<Employment> | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicantCriteria
     */
    maxDebtRatio?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicantCriteria
     */
    maxPaymentRemarks?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicantCriteria
     */
    monthsFromLatestKfDebt?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicantCriteria
     */
    minYearsInSweden?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicantCriteria
     */
    leftToLive?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicantCriteria
     */
    maxPrivateLoans?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicantCriteria
     */
    yearsSinceSelfEmployment?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicantCriteria
     */
    maxUcCheck?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicantCriteria
     */
    minEmployedMonths?: number | null;
}
/**
 * 
 * @export
 * @interface ApplicantForm
 */
export interface ApplicantForm {
    /**
     * 
     * @type {string}
     * @memberof ApplicantForm
     */
    personalNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicantForm
     */
    customer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicantForm
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicantForm
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicantForm
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicantForm
     */
    mobilePhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicantForm
     */
    landPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicantForm
     */
    email?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicantForm
     */
    housingID?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicantForm
     */
    statusID?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicantForm
     */
    childrenUnder18?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicantForm
     */
    employmentID?: number;
    /**
     * 
     * @type {EmploymentFrom}
     * @memberof ApplicantForm
     */
    from?: EmploymentFrom;
    /**
     * 
     * @type {EmploymentFromUntil}
     * @memberof ApplicantForm
     */
    fromUntil?: EmploymentFromUntil;
    /**
     * 
     * @type {number}
     * @memberof ApplicantForm
     */
    incomeBeforeTax?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicantForm
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicantForm
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicantForm
     */
    cityName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicantForm
     */
    moveInDate?: string;
    /**
     * 
     * @type {number}
     * @memberof ApplicantForm
     */
    housingFromMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicantForm
     */
    housingFromYear?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicantForm
     */
    housingCost?: number;
    /**
     * 
     * @type {number}
     * @memberof ApplicantForm
     */
    retiredFromYear?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicantForm
     */
    retiredFromMonth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicantForm
     */
    interestAndAmortizationCost?: number | null;
}
/**
 * 
 * @export
 * @interface ApplicantView
 */
export interface ApplicantView {
    /**
     * 
     * @type {string}
     * @memberof ApplicantView
     */
    firstname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicantView
     */
    lastname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicantView
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicantView
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicantView
     */
    personalNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicantView
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicantView
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicantView
     */
    city?: string | null;
}
/**
 * 
 * @export
 * @interface ApplicationAdditionsForm
 */
export interface ApplicationAdditionsForm {
    /**
     * 
     * @type {number}
     * @memberof ApplicationAdditionsForm
     */
    propertyAmountFromPreviousSell?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationAdditionsForm
     */
    propertyBuyingDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationAdditionsForm
     */
    propertyStartingPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationAdditionsForm
     */
    propertySellingPrice?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationAdditionsForm
     */
    sellingContractSigned?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationAdditionsForm
     */
    propertyAmortizationAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationAdditionsForm
     */
    propertyInterestRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationAdditionsForm
     */
    totalSavingsInSkandia?: number | null;
    /**
     * 
     * @type {SkandiaLoanPurpose}
     * @memberof ApplicationAdditionsForm
     */
    skandiaLoanPurpose?: SkandiaLoanPurpose;
    /**
     * 
     * @type {number}
     * @memberof ApplicationAdditionsForm
     */
    partnerMonthlyIncome?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationAdditionsForm
     */
    propertyOwnerShipShare?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApplicationAdditionsForm
     */
    propertyHavingOtherOwner?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationAdditionsForm
     */
    downPaymentFromHomeSale?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationAdditionsForm
     */
    downPaymentFromSavings?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationAdditionsForm
     */
    downPaymentFromGift?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationAdditionsForm
     */
    downPaymentFromHeritage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationAdditionsForm
     */
    downPaymentFromLoan?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationAdditionsForm
     */
    downPaymentFromOther?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationAdditionsForm
     */
    bridgeLoanAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationAdditionsForm
     */
    existingLoanAmount?: number | null;
    /**
     * 
     * @type {Array<AdditionalMortgageLoan>}
     * @memberof ApplicationAdditionsForm
     */
    housings?: Array<AdditionalMortgageLoan> | null;
    /**
     * 
     * @type {Array<AdditionalPrivateLoan>}
     * @memberof ApplicationAdditionsForm
     */
    privateLoans?: Array<AdditionalPrivateLoan> | null;
}
/**
 * 
 * @export
 * @interface ApplicationFormRequest
 */
export interface ApplicationFormRequest {
    /**
     * 
     * @type {WebApplicationForm}
     * @memberof ApplicationFormRequest
     */
    data?: WebApplicationForm;
    /**
     * 
     * @type {any}
     * @memberof ApplicationFormRequest
     */
    submit_referer?: any | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum AppliedWith {
    Parent = 'Parent',
    Spouse = 'Spouse'
}

/**
 * 
 * @export
 * @interface AuthResponse
 */
export interface AuthResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthResponse
     */
    orderRef?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthResponse
     */
    autoStartToken?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthResponse
     */
    errorCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthResponse
     */
    details?: string | null;
}
/**
 * 
 * @export
 * @interface Bank
 */
export interface Bank {
    /**
     * 
     * @type {Array<IDomainEvent>}
     * @memberof Bank
     */
    domainEvents?: Array<IDomainEvent> | null;
    /**
     * 
     * @type {number}
     * @memberof Bank
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Bank
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Bank
     */
    integrationTag?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Bank
     */
    offerImage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Bank
     */
    offerAdditions?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Bank
     */
    introDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Bank
     */
    expandedDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Bank
     */
    representativeText?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Bank
     */
    commission?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Bank
     */
    arrangementFee?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Bank
     */
    arrangementFee2?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Bank
     */
    administrationFee?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Bank
     */
    paybackYears?: number | null;
    /**
     * 
     * @type {MortgageOfferCriteria}
     * @memberof Bank
     */
    automationCriteria?: MortgageOfferCriteria;
    /**
     * 
     * @type {boolean}
     * @memberof Bank
     */
    autoAddOffer?: boolean;
    /**
     * 
     * @type {RequiredFields}
     * @memberof Bank
     */
    requiredFields?: RequiredFields;
    /**
     * 
     * @type {Array<InterestRates>}
     * @memberof Bank
     */
    interestRates?: Array<InterestRates> | null;
    /**
     * 
     * @type {Array<BankCommission>}
     * @memberof Bank
     */
    bankCommissions?: Array<BankCommission> | null;
    /**
     * 
     * @type {string}
     * @memberof Bank
     */
    symplifyOriginalId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Bank
     */
    displayName?: string | null;
}
/**
 * 
 * @export
 * @interface BankCommission
 */
export interface BankCommission {
    /**
     * 
     * @type {Array<IDomainEvent>}
     * @memberof BankCommission
     */
    domainEvents?: Array<IDomainEvent> | null;
    /**
     * 
     * @type {number}
     * @memberof BankCommission
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof BankCommission
     */
    bankId?: number;
    /**
     * 
     * @type {number}
     * @memberof BankCommission
     */
    commission?: number;
    /**
     * 
     * @type {MortgageLoanPurpose}
     * @memberof BankCommission
     */
    loanPurpose?: MortgageLoanPurpose;
    /**
     * 
     * @type {string}
     * @memberof BankCommission
     */
    fromDate?: string;
    /**
     * 
     * @type {string}
     * @memberof BankCommission
     */
    untilDate?: string;
}
/**
 * 
 * @export
 * @interface BankIdUser
 */
export interface BankIdUser {
    /**
     * 
     * @type {string}
     * @memberof BankIdUser
     */
    personalNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BankIdUser
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BankIdUser
     */
    givenName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BankIdUser
     */
    surname?: string | null;
}
/**
 * 
 * @export
 * @interface BankIdUserV6
 */
export interface BankIdUserV6 {
    /**
     * 
     * @type {string}
     * @memberof BankIdUserV6
     */
    personalNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BankIdUserV6
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BankIdUserV6
     */
    givenName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BankIdUserV6
     */
    surname?: string | null;
}
/**
 * 
 * @export
 * @interface BankList
 */
export interface BankList {
    /**
     * 
     * @type {MortgageLoanPurpose}
     * @memberof BankList
     */
    loanPurpose?: MortgageLoanPurpose;
    /**
     * 
     * @type {Array<Banks>}
     * @memberof BankList
     */
    banks?: Array<Banks> | null;
}
/**
 * 
 * @export
 * @interface BankView
 */
export interface BankView {
    /**
     * 
     * @type {string}
     * @memberof BankView
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BankView
     */
    offerImage?: string | null;
    /**
     * 
     * @type {number}
     * @memberof BankView
     */
    autogiroFee?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BankView
     */
    invoiceFee?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof BankView
     */
    bankIdService?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BankView
     */
    offerAdditions?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BankView
     */
    webDurationInMonths?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BankView
     */
    showAccountDetailsForPayment?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BankView
     */
    introDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BankView
     */
    expandedDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BankView
     */
    representativeText?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BankView
     */
    disableInsurance?: boolean;
    /**
     * 
     * @type {OfferPreset}
     * @memberof BankView
     */
    offerPreset?: OfferPreset;
}
/**
 * 
 * @export
 * @interface Banks
 */
export interface Banks {
    /**
     * 
     * @type {string}
     * @memberof Banks
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Banks
     */
    text?: string | null;
}
/**
 * 
 * @export
 * @interface CalculatedMortgage
 */
export interface CalculatedMortgage {
    /**
     * 
     * @type {number}
     * @memberof CalculatedMortgage
     */
    amortizationRate?: number;
    /**
     * 
     * @type {number}
     * @memberof CalculatedMortgage
     */
    interestCost?: number;
    /**
     * 
     * @type {number}
     * @memberof CalculatedMortgage
     */
    amortizationCost?: number;
    /**
     * 
     * @type {number}
     * @memberof CalculatedMortgage
     */
    monthlyCost?: number;
    /**
     * 
     * @type {number}
     * @memberof CalculatedMortgage
     */
    loanToValue?: number;
    /**
     * 
     * @type {number}
     * @memberof CalculatedMortgage
     */
    debtRatio?: number;
}
/**
 * 
 * @export
 * @interface CallbackRequest
 */
export interface CallbackRequest {
    /**
     * 
     * @type {string}
     * @memberof CallbackRequest
     */
    callbackDate?: string;
    /**
     * 
     * @type {string}
     * @memberof CallbackRequest
     */
    note?: string | null;
}
/**
 * 
 * @export
 * @interface CapTimeRequest
 */
export interface CapTimeRequest {
    /**
     * 
     * @type {string}
     * @memberof CapTimeRequest
     */
    date?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CarOwnType {
    Leased = 'Leased',
    Owned = 'Owned'
}

/**
 * 
 * @export
 * @interface Cert
 */
export interface Cert {
    /**
     * 
     * @type {string}
     * @memberof Cert
     */
    notBefore?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Cert
     */
    notAfter?: string | null;
}
/**
 * 
 * @export
 * @interface ClientAuthResponseV6
 */
export interface ClientAuthResponseV6 {
    /**
     * 
     * @type {string}
     * @memberof ClientAuthResponseV6
     */
    orderRef?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientAuthResponseV6
     */
    autoStartToken?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientAuthResponseV6
     */
    qrCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientAuthResponseV6
     */
    errorCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClientAuthResponseV6
     */
    details?: string | null;
}
/**
 * 
 * @export
 * @interface CollectResponse
 */
export interface CollectResponse {
    /**
     * 
     * @type {string}
     * @memberof CollectResponse
     */
    orderRef?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CollectResponse
     */
    status?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CollectResponse
     */
    signature?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CollectResponse
     */
    hintCode?: string | null;
    /**
     * 
     * @type {CompletionData}
     * @memberof CollectResponse
     */
    completionData?: CompletionData;
    /**
     * 
     * @type {string}
     * @memberof CollectResponse
     */
    errorCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CollectResponse
     */
    details?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CollectResponse
     */
    userMessage?: string | null;
    /**
     * 
     * @type {MyPagesAppType}
     * @memberof CollectResponse
     */
    appType?: MyPagesAppType;
}
/**
 * 
 * @export
 * @interface CollectResponseV6
 */
export interface CollectResponseV6 {
    /**
     * 
     * @type {string}
     * @memberof CollectResponseV6
     */
    orderRef?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CollectResponseV6
     */
    status?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CollectResponseV6
     */
    signature?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CollectResponseV6
     */
    hintCode?: string | null;
    /**
     * 
     * @type {CompletionDataV6}
     * @memberof CollectResponseV6
     */
    completionData?: CompletionDataV6;
    /**
     * 
     * @type {string}
     * @memberof CollectResponseV6
     */
    errorCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CollectResponseV6
     */
    details?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CollectResponseV6
     */
    userMessage?: string | null;
    /**
     * 
     * @type {MyPagesAppType}
     * @memberof CollectResponseV6
     */
    appType?: MyPagesAppType;
    /**
     * 
     * @type {string}
     * @memberof CollectResponseV6
     */
    qrCode?: string | null;
}
/**
 * 
 * @export
 * @interface CompletionData
 */
export interface CompletionData {
    /**
     * 
     * @type {BankIdUser}
     * @memberof CompletionData
     */
    user?: BankIdUser;
    /**
     * 
     * @type {Device}
     * @memberof CompletionData
     */
    device?: Device;
    /**
     * 
     * @type {Cert}
     * @memberof CompletionData
     */
    cert?: Cert;
    /**
     * 
     * @type {string}
     * @memberof CompletionData
     */
    signature?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletionData
     */
    ocspResponse?: string | null;
}
/**
 * 
 * @export
 * @interface CompletionDataV6
 */
export interface CompletionDataV6 {
    /**
     * 
     * @type {BankIdUserV6}
     * @memberof CompletionDataV6
     */
    user?: BankIdUserV6;
    /**
     * 
     * @type {DeviceV6}
     * @memberof CompletionDataV6
     */
    device?: DeviceV6;
    /**
     * 
     * @type {string}
     * @memberof CompletionDataV6
     */
    bankIdIssueDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletionDataV6
     */
    signature?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompletionDataV6
     */
    ocspResponse?: string | null;
}
/**
 * 
 * @export
 * @interface CustomerApplicationsView
 */
export interface CustomerApplicationsView {
    /**
     * 
     * @type {LoanApplicationView}
     * @memberof CustomerApplicationsView
     */
    loanApplication?: LoanApplicationView;
    /**
     * 
     * @type {MortgageApplicationView}
     * @memberof CustomerApplicationsView
     */
    mortgageApplication?: MortgageApplicationView;
}
/**
 * 
 * @export
 * @interface CustomerLoanChangeRequest
 */
export interface CustomerLoanChangeRequest {
    /**
     * 
     * @type {number}
     * @memberof CustomerLoanChangeRequest
     */
    loanAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerLoanChangeRequest
     */
    paybackYears?: number;
}
/**
 * 
 * @export
 * @interface CustomerMessageForm
 */
export interface CustomerMessageForm {
    /**
     * 
     * @type {string}
     * @memberof CustomerMessageForm
     */
    category?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerMessageForm
     */
    subject?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerMessageForm
     */
    message?: string | null;
    /**
     * 
     * @type {MessageLoantype}
     * @memberof CustomerMessageForm
     */
    loanType?: MessageLoantype;
}
/**
 * 
 * @export
 * @interface CustomerNewPropertyRequest
 */
export interface CustomerNewPropertyRequest {
    /**
     * 
     * @type {number}
     * @memberof CustomerNewPropertyRequest
     */
    downPayment?: number;
    /**
     * 
     * @type {number}
     * @memberof CustomerNewPropertyRequest
     */
    loanAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof CustomerNewPropertyRequest
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerNewPropertyRequest
     */
    status?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum CustomerStatus {
    NoContact = 'NoContact',
    PresentOffer = 'PresentOffer',
    WaitingForClient = 'WaitingForClient',
    WaitingForDocument = 'WaitingForDocument',
    DocumentAudit = 'DocumentAudit',
    WaitingForPayment = 'WaitingForPayment',
    WaitingForEntry = 'WaitingForEntry'
}

/**
 * 
 * @export
 * @interface Device
 */
export interface Device {
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    ipAddress?: string | null;
}
/**
 * 
 * @export
 * @interface DeviceV6
 */
export interface DeviceV6 {
    /**
     * 
     * @type {string}
     * @memberof DeviceV6
     */
    ipAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeviceV6
     */
    uhi?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Employment {
    FullTimeEmployee = 'FullTimeEmployee',
    SelfEmployed = 'SelfEmployed',
    Student = 'Student',
    Unemployed = 'Unemployed',
    EarlyRetired = 'EarlyRetired',
    Retired = 'Retired',
    Probationary = 'Probationary',
    HourlyEmployee = 'HourlyEmployee',
    Other = 'Other',
    PartTimeProject = 'PartTimeProject'
}

/**
 * 
 * @export
 * @interface EmploymentFrom
 */
export interface EmploymentFrom {
    /**
     * 
     * @type {number}
     * @memberof EmploymentFrom
     */
    fromYear?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EmploymentFrom
     */
    fromMonth?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EmploymentFrom
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmploymentFrom
     */
    phone?: string | null;
}
/**
 * 
 * @export
 * @interface EmploymentFromUntil
 */
export interface EmploymentFromUntil {
    /**
     * 
     * @type {number}
     * @memberof EmploymentFromUntil
     */
    fromYear?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EmploymentFromUntil
     */
    fromMonth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EmploymentFromUntil
     */
    untilYear?: number | null;
    /**
     * 
     * @type {number}
     * @memberof EmploymentFromUntil
     */
    untilMonth?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EmploymentFromUntil
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmploymentFromUntil
     */
    phone?: string | null;
}
/**
 * 
 * @export
 * @interface ExistingLoans
 */
export interface ExistingLoans {
    /**
     * 
     * @type {number}
     * @memberof ExistingLoans
     */
    index?: number;
    /**
     * 
     * @type {number}
     * @memberof ExistingLoans
     */
    bankID?: number;
    /**
     * 
     * @type {number}
     * @memberof ExistingLoans
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof ExistingLoans
     */
    interestRate?: number;
    /**
     * 
     * @type {number}
     * @memberof ExistingLoans
     */
    monthlyCost?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum FieldType {
    Text = 'Text',
    Textarea = 'Textarea',
    Number = 'Number',
    Money = 'Money',
    Percent = 'Percent',
    Date = 'Date',
    Select = 'Select',
    MultiSelect = 'MultiSelect',
    Boolean = 'Boolean'
}

/**
 * 
 * @export
 * @interface GdprRequestForm
 */
export interface GdprRequestForm {
    /**
     * 
     * @type {string}
     * @memberof GdprRequestForm
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GdprRequestForm
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GdprRequestForm
     */
    surname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GdprRequestForm
     */
    personalNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GdprRequestForm
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GdprRequestForm
     */
    mobilePhone?: string | null;
}
/**
 * 
 * @export
 * @interface HemnetImage
 */
export interface HemnetImage {
    /**
     * 
     * @type {string}
     * @memberof HemnetImage
     */
    url?: string | null;
}
/**
 * 
 * @export
 * @interface HemnetMoney
 */
export interface HemnetMoney {
    /**
     * 
     * @type {number}
     * @memberof HemnetMoney
     */
    amount?: number | null;
    /**
     * 
     * @type {string}
     * @memberof HemnetMoney
     */
    currency?: string | null;
}
/**
 * 
 * @export
 * @interface HemnetNameCode
 */
export interface HemnetNameCode {
    /**
     * 
     * @type {string}
     * @memberof HemnetNameCode
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HemnetNameCode
     */
    code?: string | null;
}
/**
 * 
 * @export
 * @interface HouseIdListingData
 */
export interface HouseIdListingData {
    /**
     * 
     * @type {string}
     * @memberof HouseIdListingData
     */
    label?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HouseIdListingData
     */
    dataType?: string | null;
    /**
     * 
     * @type {any}
     * @memberof HouseIdListingData
     */
    value?: any | null;
    /**
     * 
     * @type {string}
     * @memberof HouseIdListingData
     */
    source?: string | null;
}
/**
 * 
 * @export
 * @interface HouseIdResponse
 */
export interface HouseIdResponse {
    /**
     * 
     * @type {HouseIdListingData}
     * @memberof HouseIdResponse
     */
    propertyType?: HouseIdListingData;
    /**
     * 
     * @type {HouseIdListingData}
     * @memberof HouseIdResponse
     */
    propertyHousingType?: HouseIdListingData;
    /**
     * 
     * @type {HouseIdListingData}
     * @memberof HouseIdResponse
     */
    propertyHousingLivingArea?: HouseIdListingData;
    /**
     * 
     * @type {HouseIdListingData}
     * @memberof HouseIdResponse
     */
    userEmail?: HouseIdListingData;
    /**
     * 
     * @type {HouseIdListingData}
     * @memberof HouseIdResponse
     */
    userPhonenumber?: HouseIdListingData;
    /**
     * 
     * @type {HouseIdListingData}
     * @memberof HouseIdResponse
     */
    userGivenName?: HouseIdListingData;
    /**
     * 
     * @type {HouseIdListingData}
     * @memberof HouseIdResponse
     */
    userSurname?: HouseIdListingData;
    /**
     * 
     * @type {HouseIdListingData}
     * @memberof HouseIdResponse
     */
    propertyValuation?: HouseIdListingData;
    /**
     * 
     * @type {HouseIdListingData}
     * @memberof HouseIdResponse
     */
    propertyTotalLoanAmount?: HouseIdListingData;
    /**
     * 
     * @type {HouseIdListingData}
     * @memberof HouseIdResponse
     */
    propertyLoanAverageInterestRate?: HouseIdListingData;
    /**
     * 
     * @type {HouseIdListingData}
     * @memberof HouseIdResponse
     */
    propertyNumberOfBedrooms?: HouseIdListingData;
    /**
     * 
     * @type {HouseIdListingData}
     * @memberof HouseIdResponse
     */
    propertyNumberOfRooms?: HouseIdListingData;
    /**
     * 
     * @type {HouseIdListingData}
     * @memberof HouseIdResponse
     */
    propertyNumberOfOtherRooms?: HouseIdListingData;
    /**
     * 
     * @type {HouseIdListingData}
     * @memberof HouseIdResponse
     */
    propertyPostalCode?: HouseIdListingData;
    /**
     * 
     * @type {HouseIdListingData}
     * @memberof HouseIdResponse
     */
    propertyStreetAddress?: HouseIdListingData;
    /**
     * 
     * @type {HouseIdListingData}
     * @memberof HouseIdResponse
     */
    propertyaddressCountry?: HouseIdListingData;
    /**
     * 
     * @type {HouseIdListingData}
     * @memberof HouseIdResponse
     */
    propertyAddressLocality?: HouseIdListingData;
    /**
     * 
     * @type {HouseIdListingData}
     * @memberof HouseIdResponse
     */
    propertyMunicipalityName?: HouseIdListingData;
    /**
     * 
     * @type {HouseIdListingData}
     * @memberof HouseIdResponse
     */
    propertyOperatingCost?: HouseIdListingData;
    /**
     * 
     * @type {HouseIdListingData}
     * @memberof HouseIdResponse
     */
    propertyMonthlyFee?: HouseIdListingData;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum Housing {
    House = 'House',
    Condominium = 'Condominium',
    Cottage = 'Cottage',
    Rental = 'Rental',
    Lodger = 'Lodger'
}

/**
 * 
 * @export
 * @interface IDomainEvent
 */
export interface IDomainEvent {
    /**
     * 
     * @type {number}
     * @memberof IDomainEvent
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof IDomainEvent
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDomainEvent
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof IDomainEvent
     */
    createdByID?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IDomainEvent
     */
    entityID?: number;
    /**
     * 
     * @type {string}
     * @memberof IDomainEvent
     */
    processedAt?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IDomainEvent
     */
    callbackDate?: string | null;
}
/**
 * 
 * @export
 * @interface IncomeDeviate
 */
export interface IncomeDeviate {
    /**
     * 
     * @type {boolean}
     * @memberof IncomeDeviate
     */
    applicantIncomeDeviates?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IncomeDeviate
     */
    year?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IncomeDeviate
     */
    key?: string | null;
}
/**
 * 
 * @export
 * @interface InterestRateProposalReply
 */
export interface InterestRateProposalReply {
    /**
     * 
     * @type {string}
     * @memberof InterestRateProposalReply
     */
    binding?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InterestRateProposalReply
     */
    interestRate?: number;
    /**
     * 
     * @type {number}
     * @memberof InterestRateProposalReply
     */
    interestRateBeforeDiscount?: number;
    /**
     * 
     * @type {number}
     * @memberof InterestRateProposalReply
     */
    discount?: number;
    /**
     * 
     * @type {number}
     * @memberof InterestRateProposalReply
     */
    effectiveInterestRate?: number | null;
}
/**
 * 
 * @export
 * @interface InterestRateProposalRequest
 */
export interface InterestRateProposalRequest {
    /**
     * 
     * @type {number}
     * @memberof InterestRateProposalRequest
     */
    loanAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof InterestRateProposalRequest
     */
    loanToValue?: number;
    /**
     * 
     * @type {Offer}
     * @memberof InterestRateProposalRequest
     */
    offer?: Offer;
    /**
     * 
     * @type {number}
     * @memberof InterestRateProposalRequest
     */
    propertyEstimateValue?: number;
}
/**
 * 
 * @export
 * @interface InterestRates
 */
export interface InterestRates {
    /**
     * 
     * @type {string}
     * @memberof InterestRates
     */
    bindingPeriod?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InterestRates
     */
    interestRateBeforeDiscount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InterestRates
     */
    discount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InterestRates
     */
    interestRateAfterDiscount?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum LandshypotekLoanPurpose {
    NewLending = 'NewLending',
    RealEstatePurchaseHouse = 'RealEstatePurchaseHouse',
    TakeOverExternally = 'TakeOverExternally',
    ExtensionOfLoan = 'ExtensionOfLoan'
}

/**
 * 
 * @export
 * @interface ListingData
 */
export interface ListingData {
    /**
     * 
     * @type {string}
     * @memberof ListingData
     */
    streetAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListingData
     */
    postCode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ListingData
     */
    numberOfRooms?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ListingData
     */
    livingArea?: number | null;
    /**
     * 
     * @type {HemnetMoney}
     * @memberof ListingData
     */
    askingPrice?: HemnetMoney;
    /**
     * 
     * @type {HemnetMoney}
     * @memberof ListingData
     */
    fee?: HemnetMoney;
    /**
     * 
     * @type {HemnetMoney}
     * @memberof ListingData
     */
    runningCosts?: HemnetMoney;
    /**
     * 
     * @type {HemnetNameCode}
     * @memberof ListingData
     */
    housingForm?: HemnetNameCode;
    /**
     * 
     * @type {HemnetNameCode}
     * @memberof ListingData
     */
    tenure?: HemnetNameCode;
    /**
     * 
     * @type {string}
     * @memberof ListingData
     */
    constructionYear?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListingData
     */
    municipality?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListingData
     */
    region?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListingData
     */
    district?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ListingData
     */
    customAreaName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ListingData
     */
    isNewConstruction?: boolean;
    /**
     * 
     * @type {HemnetImage}
     * @memberof ListingData
     */
    image?: HemnetImage;
}
/**
 * 
 * @export
 * @interface ListingResponse
 */
export interface ListingResponse {
    /**
     * 
     * @type {ListingData}
     * @memberof ListingResponse
     */
    data?: ListingData;
}
/**
 * 
 * @export
 * @interface LoanApplicationView
 */
export interface LoanApplicationView {
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationView
     */
    loanAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationView
     */
    paybackYears?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationView
     */
    purpose?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationView
     */
    loanStatus?: number;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationView
     */
    applicantPersonalNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationView
     */
    coApplicantPersonalNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationView
     */
    customerName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationView
     */
    customerFirstName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplicationView
     */
    insuranceChosen?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplicationView
     */
    insuranceSigned?: boolean;
    /**
     * 
     * @type {Array<OfferView>}
     * @memberof LoanApplicationView
     */
    offers?: Array<OfferView> | null;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationView
     */
    chosenOfferId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LoanApplicationView
     */
    loanApplicationId?: number;
    /**
     * 
     * @type {ApplicantView}
     * @memberof LoanApplicationView
     */
    applicant?: ApplicantView;
    /**
     * 
     * @type {boolean}
     * @memberof LoanApplicationView
     */
    hideOutgoingLeadForm?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoanApplicationView
     */
    assignedTo?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum LoanOwnerType {
    MainApplicant = 'MainApplicant',
    CoApplicant = 'CoApplicant',
    Both = 'Both'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum MarginalenLoanPurpose {
    Consolidate = 'Consolidate',
    Kronofogden = 'Kronofogden',
    Renovation = 'Renovation',
    Purchase = 'Purchase',
    Other = 'Other'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum MaritalStatus {
    Married = 'Married',
    Cohabitant = 'Cohabitant',
    Divorced = 'Divorced',
    Single = 'Single',
    Partner = 'Partner'
}

/**
 * 
 * @export
 * @interface MaxLoanAmountDetailsView
 */
export interface MaxLoanAmountDetailsView {
    /**
     * 
     * @type {number}
     * @memberof MaxLoanAmountDetailsView
     */
    maxLoanAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof MaxLoanAmountDetailsView
     */
    maxLoanAmountRounded?: number;
    /**
     * 
     * @type {number}
     * @memberof MaxLoanAmountDetailsView
     */
    maxDownPayment?: number;
    /**
     * 
     * @type {number}
     * @memberof MaxLoanAmountDetailsView
     */
    maxDownPaymentRounded?: number;
    /**
     * 
     * @type {number}
     * @memberof MaxLoanAmountDetailsView
     */
    maxPurchase?: number;
    /**
     * 
     * @type {number}
     * @memberof MaxLoanAmountDetailsView
     */
    maxPurchaseRounded?: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof MaxLoanAmountDetailsView
     */
    details?: { [key: string]: number; } | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum MessageLoantype {
    Mortgage = 'Mortgage',
    Loan = 'Loan',
    Insurance = 'Insurance'
}

/**
 * 
 * @export
 * @interface MessageView
 */
export interface MessageView {
    /**
     * 
     * @type {number}
     * @memberof MessageView
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MessageView
     */
    createdAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MessageView
     */
    isRead?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MessageView
     */
    category?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageView
     */
    subject?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageView
     */
    message?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MessageView
     */
    fromCustomer?: boolean;
    /**
     * 
     * @type {MessageLoantype}
     * @memberof MessageView
     */
    loanType?: MessageLoantype;
}
/**
 * 
 * @export
 * @interface MortgageApplicantForm
 */
export interface MortgageApplicantForm {
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicantForm
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicantForm
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicantForm
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicantForm
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicantForm
     */
    personalNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicantForm
     */
    mobilePhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicantForm
     */
    landPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicantForm
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicantForm
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicantForm
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicantForm
     */
    city?: string | null;
    /**
     * 
     * @type {MaritalStatus}
     * @memberof MortgageApplicantForm
     */
    maritalStatus?: MaritalStatus;
    /**
     * 
     * @type {Employment}
     * @memberof MortgageApplicantForm
     */
    employment?: Employment;
    /**
     * 
     * @type {Housing}
     * @memberof MortgageApplicantForm
     */
    housing?: Housing;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicantForm
     */
    operationalCost?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicantForm
     */
    ownershipPercentage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicantForm
     */
    taxablePropertyValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicantForm
     */
    numberOfRooms?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicantForm
     */
    moveInDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicantForm
     */
    incomeBeforeTax?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicantForm
     */
    otherIncomeBeforeTax?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicantForm
     */
    employerName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicantForm
     */
    employmentFromYear?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicantForm
     */
    employmentFromMonth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicantForm
     */
    employmentUntilYear?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicantForm
     */
    employmentUntilMonth?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicantForm
     */
    employerPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicantForm
     */
    profession?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicantForm
     */
    organizationNumber?: string | null;
}
/**
 * 
 * @export
 * @interface MortgageApplicantInfoView
 */
export interface MortgageApplicantInfoView {
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicantInfoView
     */
    applicantId?: string;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicantInfoView
     */
    coApplicantId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicantInfoView
     */
    applicantPersonalNumberDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicantInfoView
     */
    coApplicantPersonalNumberDate?: string | null;
}
/**
 * 
 * @export
 * @interface MortgageApplicationRenewalForm
 */
export interface MortgageApplicationRenewalForm {
    /**
     * 
     * @type {MortgageApplicantForm}
     * @memberof MortgageApplicationRenewalForm
     */
    applicant?: MortgageApplicantForm;
    /**
     * 
     * @type {MortgageApplicantForm}
     * @memberof MortgageApplicationRenewalForm
     */
    coApplicant?: MortgageApplicantForm;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageApplicationRenewalForm
     */
    hasCoApplicant?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationRenewalForm
     */
    associationName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationRenewalForm
     */
    associationStreetAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationRenewalForm
     */
    association?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationRenewalForm
     */
    associationOrganisationNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationRenewalForm
     */
    propertyNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationRenewalForm
     */
    propertyCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationRenewalForm
     */
    propertyCounty?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationRenewalForm
     */
    propertyCountyCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationRenewalForm
     */
    propertyPostalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationRenewalForm
     */
    propertyAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationRenewalForm
     */
    googleAnalyticsClientId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    propertyAreaSquareMeter?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    propertyValueEstimate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    propertyMonthlyCost?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    propertyAmortizationAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    propertyInterestRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    propertyOwnerShipShare?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    propertyNumberOfRooms?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationRenewalForm
     */
    propertyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationRenewalForm
     */
    propertyUrl?: string | null;
    /**
     * 
     * @type {Housing}
     * @memberof MortgageApplicationRenewalForm
     */
    propertyHousing?: Housing;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    propertyHousingCost?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationRenewalForm
     */
    propertyDesignation?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    propertyBuildingYear?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    propertyAssessValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    purchaseAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    downPayment?: number;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    loanAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    paybackYears?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    preferredInterestRate?: number;
    /**
     * 
     * @type {MortgageLoanPurpose}
     * @memberof MortgageApplicationRenewalForm
     */
    loanPurpose?: MortgageLoanPurpose;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    existingMortgageLoanAmount?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationRenewalForm
     */
    existingMortgageLoanBank?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    existingMortgageLoanMonthlyCost?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    existingMortgageLoanAmortizationAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    existingMortgageLoanInterestRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    existingMortgageLoanOwnershipShare?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    existingMortgageAssessValue?: number | null;
    /**
     * 
     * @type {Array<AdditionalMortgageLoan>}
     * @memberof MortgageApplicationRenewalForm
     */
    additionalMortgageLoans?: Array<AdditionalMortgageLoan> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageApplicationRenewalForm
     */
    hasExistingPrivateLoan?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    existingPrivateLoanAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    existingPrivateLoanMonthlyCost?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    existingPrivateLoanAmortizationAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    existingPrivateLoanInterestRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    existingPrivateLoanOwnershipShare?: number | null;
    /**
     * 
     * @type {Array<AdditionalPrivateLoan>}
     * @memberof MortgageApplicationRenewalForm
     */
    additionalPrivateLoans?: Array<AdditionalPrivateLoan> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageApplicationRenewalForm
     */
    hasExistingStudentLoan?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    existingStudentLoanAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    existingStudentLoanMonthlyCost?: number | null;
    /**
     * 
     * @type {Array<AdditionalMortgageLoan>}
     * @memberof MortgageApplicationRenewalForm
     */
    housings?: Array<AdditionalMortgageLoan> | null;
    /**
     * 
     * @type {Array<AdditionalPrivateLoan>}
     * @memberof MortgageApplicationRenewalForm
     */
    privateLoans?: Array<AdditionalPrivateLoan> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageApplicationRenewalForm
     */
    hasChildren?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    numberOfChildrenPayingAlimony?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MortgageApplicationRenewalForm
     */
    numberOfChildrenPayingAlimonyAges?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    numberOfChildrenReceivingAlimony?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MortgageApplicationRenewalForm
     */
    numberOfChildrenReceivingAlimonyAges?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    numberOfChildrenReceivingBenefit?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MortgageApplicationRenewalForm
     */
    numberOfChildrenReceivingBenefitAges?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    numberOfChildrenSharedCustody?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MortgageApplicationRenewalForm
     */
    numberOfChildrenSharedCustodyAges?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    travelExpenses?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageApplicationRenewalForm
     */
    canMoveSavings?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    monthlySavings?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    savingOnBankAccounts?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    savingsInStocks?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    savingsOnMutualFunds?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationRenewalForm
     */
    uploadedDocumentationUrls?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationRenewalForm
     */
    propertyCadastralDesignation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationRenewalForm
     */
    notes?: string | null;
    /**
     * 
     * @type {Array<MortgageUtmParams>}
     * @memberof MortgageApplicationRenewalForm
     */
    utmParams?: Array<MortgageUtmParams> | null;
    /**
     * 
     * @type {MortgageUtmParams}
     * @memberof MortgageApplicationRenewalForm
     */
    utmParamsLast?: MortgageUtmParams;
    /**
     * 
     * @type {Array<ReferralParams>}
     * @memberof MortgageApplicationRenewalForm
     */
    referralParams?: Array<ReferralParams> | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationRenewalForm
     */
    referralUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationRenewalForm
     */
    referralSource?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationRenewalForm
     */
    clientCookies?: string | null;
    /**
     * 
     * @type {AmortizationRule}
     * @memberof MortgageApplicationRenewalForm
     */
    propertyAmortizationRule?: AmortizationRule;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    propertyAmountFromPreviousSell?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    propertyApartmentFloor?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationRenewalForm
     */
    propertyBuyingDate?: string | null;
    /**
     * 
     * @type {AppliedWith}
     * @memberof MortgageApplicationRenewalForm
     */
    propertyAppliedWith?: AppliedWith;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageApplicationRenewalForm
     */
    propertyIsNewProduction?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageApplicationRenewalForm
     */
    propertyHasElevator?: boolean | null;
    /**
     * 
     * @type {PropertyBalconyType}
     * @memberof MortgageApplicationRenewalForm
     */
    propertyBalconyType?: PropertyBalconyType;
    /**
     * 
     * @type {SkandiaLoanPurpose}
     * @memberof MortgageApplicationRenewalForm
     */
    skandiaLoanPurpose?: SkandiaLoanPurpose;
    /**
     * 
     * @type {MarginalenLoanPurpose}
     * @memberof MortgageApplicationRenewalForm
     */
    marginalenLoanPurpose?: MarginalenLoanPurpose;
    /**
     * 
     * @type {LandshypotekLoanPurpose}
     * @memberof MortgageApplicationRenewalForm
     */
    landshypotekLoanPurpose?: LandshypotekLoanPurpose;
    /**
     * 
     * @type {SveaLoanPurpose}
     * @memberof MortgageApplicationRenewalForm
     */
    sveaLoanPurpose?: SveaLoanPurpose;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageApplicationRenewalForm
     */
    sellingContractSigned?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    brokerFee?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    bridgeLoanAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    existingLoanAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    totalSavingsInSkandia?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageApplicationRenewalForm
     */
    propertyHavingOtherOwner?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    partnerMonthlyIncome?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    coApplicantPartnersMonthlyIncome?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    propertySellingPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    propertyStartingPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    leadId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageApplicationRenewalForm
     */
    occupationalPensionInSkandia?: boolean;
    /**
     * 
     * @type {SkandiaExcessAmountIntention}
     * @memberof MortgageApplicationRenewalForm
     */
    skandiaExcessAmountIntention?: SkandiaExcessAmountIntention;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    skandiaExcessAmountMonthlyCost?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    currentMortgageBank?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    propertyPurchacePrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    originalApplicationId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationRenewalForm
     */
    entryDate?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageApplicationRenewalForm
     */
    askedAboutSf?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageApplicationRenewalForm
     */
    doNotShowToOtherBrokers?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    extraIncome?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    extraCost?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationRenewalForm
     */
    energyClass?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationRenewalForm
     */
    houseIdToken?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationRenewalForm
     */
    houseIdMoveToken?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageApplicationRenewalForm
     */
    agreeToTerms?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageApplicationRenewalForm
     */
    agreeToInformationAndMarketing?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageApplicationRenewalForm
     */
    agreeToHouseId?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationRenewalForm
     */
    accessToken?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationRenewalForm
     */
    originalApplicantId?: string;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationRenewalForm
     */
    renewalBankId?: number | null;
}
/**
 * 
 * @export
 * @interface MortgageApplicationView
 */
export interface MortgageApplicationView {
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationView
     */
    loanAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationView
     */
    downPayment?: number;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationView
     */
    purpose?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationView
     */
    propertyValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationView
     */
    propertyMonthlyCost?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationView
     */
    propertyCounty?: string | null;
    /**
     * 
     * @type {Housing}
     * @memberof MortgageApplicationView
     */
    mainHousing?: Housing;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageApplicationView
     */
    mainKeepCurrentHousing?: boolean;
    /**
     * 
     * @type {Housing}
     * @memberof MortgageApplicationView
     */
    propertyHousing?: Housing;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationView
     */
    loanStatus?: number;
    /**
     * 
     * @type {Array<MortgageFlowStatus>}
     * @memberof MortgageApplicationView
     */
    mortgageFlowStatus?: Array<MortgageFlowStatus> | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationView
     */
    customerName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationView
     */
    chosenOfferId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationView
     */
    applicantPersonalNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationView
     */
    coApplicantPersonalNumber?: string | null;
    /**
     * 
     * @type {Employment}
     * @memberof MortgageApplicationView
     */
    applicantEmployment?: Employment;
    /**
     * 
     * @type {Array<MortgageOfferView>}
     * @memberof MortgageApplicationView
     */
    offers?: Array<MortgageOfferView> | null;
    /**
     * 
     * @type {Array<Advisory>}
     * @memberof MortgageApplicationView
     */
    advisories?: Array<Advisory> | null;
    /**
     * 
     * @type {ApplicationAdditionsForm}
     * @memberof MortgageApplicationView
     */
    applicationAdditionsForm?: ApplicationAdditionsForm;
    /**
     * 
     * @type {ApplicantView}
     * @memberof MortgageApplicationView
     */
    applicant?: ApplicantView;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationView
     */
    applicationId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageApplicationView
     */
    findProperty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageApplicationView
     */
    hideOutgoingLeadForm?: boolean;
    /**
     * 
     * @type {Array<OfferDocument>}
     * @memberof MortgageApplicationView
     */
    documents?: Array<OfferDocument> | null;
    /**
     * 
     * @type {CustomerStatus}
     * @memberof MortgageApplicationView
     */
    customerStatus?: CustomerStatus;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageApplicationView
     */
    showMortgageCalculationForCanceledApp?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MortgageApplicationView
     */
    maxLoanAmount?: number;
    /**
     * 
     * @type {Offer}
     * @memberof MortgageApplicationView
     */
    offerWithLowestInterestRate?: Offer;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageApplicationView
     */
    offerIsPremiumBank?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageApplicationView
     */
    showMortgageCalculationForActiveApp?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MortgageApplicationView
     */
    assignedTo?: string | null;
}
/**
 * 
 * @export
 * @interface MortgageBankView
 */
export interface MortgageBankView {
    /**
     * 
     * @type {string}
     * @memberof MortgageBankView
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageBankView
     */
    offerImage?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageBankView
     */
    autogiroFee?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageBankView
     */
    bankIdService?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MortgageBankView
     */
    offerAdditions?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageBankView
     */
    webDurationInMonths?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageBankView
     */
    showAccountDetailsForPayment?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MortgageBankView
     */
    introDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageBankView
     */
    expandedDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageBankView
     */
    representativeText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageBankView
     */
    displayName?: string | null;
}
/**
 * 
 * @export
 * @interface MortgageContactForm
 */
export interface MortgageContactForm {
    /**
     * 
     * @type {string}
     * @memberof MortgageContactForm
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageContactForm
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageContactForm
     */
    purpose?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageContactForm
     */
    text?: string | null;
}
/**
 * 
 * @export
 * @interface MortgageFlowEvent
 */
export interface MortgageFlowEvent {
    /**
     * 
     * @type {MortgageFlowEventStatus}
     * @memberof MortgageFlowEvent
     */
    status?: MortgageFlowEventStatus;
    /**
     * 
     * @type {string}
     * @memberof MortgageFlowEvent
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageFlowEvent
     */
    text?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageFlowEvent
     */
    renegotiateText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageFlowEvent
     */
    buttonText?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageFlowEvent
     */
    show?: boolean;
    /**
     * 
     * @type {BankList}
     * @memberof MortgageFlowEvent
     */
    bankList?: BankList;
    /**
     * 
     * @type {Array<string>}
     * @memberof MortgageFlowEvent
     */
    instructions?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MortgageFlowEvent
     */
    renegotiateInstructions?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageFlowEvent
     */
    showRecommendationForm?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MortgageFlowEvent
     */
    sbabText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageFlowEvent
     */
    questionMissingText?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum MortgageFlowEventStatus {
    Created = 'Created',
    QuestionAnswered = 'QuestionAnswered',
    SentToBanks = 'SentToBanks',
    ChooseOffer = 'ChooseOffer',
    OfferChosen = 'OfferChosen',
    DocumentUploaded = 'DocumentUploaded',
    DocumentDeclined = 'DocumentDeclined',
    DocumentApproved = 'DocumentApproved',
    FindProperty = 'FindProperty',
    PropertyInfoUpdated = 'PropertyInfoUpdated',
    UploadPurchaseDocument = 'UploadPurchaseDocument',
    PurchaseDocumentUploaded = 'PurchaseDocumentUploaded',
    PurchaseDocumentDeclined = 'PurchaseDocumentDeclined',
    SignMortgage = 'SignMortgage',
    MortgagePaid = 'MortgagePaid',
    QuestionMissing = 'QuestionMissing',
    Renewal = 'Renewal'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum MortgageFlowProgressStatus {
    Current = 'Current',
    Upcoming = 'Upcoming',
    Done = 'Done'
}

/**
 * 
 * @export
 * @interface MortgageFlowStatus
 */
export interface MortgageFlowStatus {
    /**
     * 
     * @type {string}
     * @memberof MortgageFlowStatus
     */
    title?: string | null;
    /**
     * 
     * @type {MortgageFlowProgressStatus}
     * @memberof MortgageFlowStatus
     */
    status?: MortgageFlowProgressStatus;
    /**
     * 
     * @type {Array<MortgageFlowEvent>}
     * @memberof MortgageFlowStatus
     */
    events?: Array<MortgageFlowEvent> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum MortgageLoanPurpose {
    Renegotiate = 'Renegotiate',
    Purchase = 'Purchase',
    PromissoryLetter = 'PromissoryLetter'
}

/**
 * 
 * @export
 * @interface MortgageOfferCriteria
 */
export interface MortgageOfferCriteria {
    /**
     * 
     * @type {number}
     * @memberof MortgageOfferCriteria
     */
    minLoanAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageOfferCriteria
     */
    maxLoanAmount?: number | null;
    /**
     * 
     * @type {Array<MortgageLoanPurpose>}
     * @memberof MortgageOfferCriteria
     */
    loanPurposes?: Array<MortgageLoanPurpose> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MortgageOfferCriteria
     */
    excludedMunicipalities?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageOfferCriteria
     */
    minMarketValue?: number | null;
    /**
     * 
     * @type {ApplicantCriteria}
     * @memberof MortgageOfferCriteria
     */
    applicantCriteria?: ApplicantCriteria;
    /**
     * 
     * @type {ApplicantCriteria}
     * @memberof MortgageOfferCriteria
     */
    coApplicantCriteria?: ApplicantCriteria;
    /**
     * 
     * @type {Array<string>}
     * @memberof MortgageOfferCriteria
     */
    propertyCodes?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface MortgageOfferView
 */
export interface MortgageOfferView {
    /**
     * 
     * @type {number}
     * @memberof MortgageOfferView
     */
    id?: number;
    /**
     * 
     * @type {MortgageBankView}
     * @memberof MortgageOfferView
     */
    bank?: MortgageBankView;
    /**
     * 
     * @type {Array<OfferDocument>}
     * @memberof MortgageOfferView
     */
    documents?: Array<OfferDocument> | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageOfferView
     */
    approvedAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageOfferView
     */
    privateLoanApprovedAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageOfferView
     */
    interestRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageOfferView
     */
    effectiveRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageOfferView
     */
    discountRateMonthlyInstallment?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageOfferView
     */
    monthlyInstallment?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageOfferView
     */
    interestPayment?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageOfferView
     */
    amortizationPayment?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageOfferView
     */
    arrangementFee?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageOfferView
     */
    administrationFee?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageOfferView
     */
    interestBindingPeriod?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageOfferView
     */
    paybackYearsFixed?: number | null;
    /**
     * 
     * @type {AmortizationRule}
     * @memberof MortgageOfferView
     */
    amortizationRule?: AmortizationRule;
    /**
     * 
     * @type {Array<InterestRates>}
     * @memberof MortgageOfferView
     */
    possibleInterestRates?: Array<InterestRates> | null;
    /**
     * 
     * @type {OfferStatus}
     * @memberof MortgageOfferView
     */
    status?: OfferStatus;
    /**
     * 
     * @type {string}
     * @memberof MortgageOfferView
     */
    dueDate?: string;
    /**
     * 
     * @type {number}
     * @memberof MortgageOfferView
     */
    daysUntilDueDate?: number;
    /**
     * 
     * @type {OfferDueDateTextType}
     * @memberof MortgageOfferView
     */
    offerDueDateTextType?: OfferDueDateTextType;
}
/**
 * 
 * @export
 * @interface MortgagePartialApplicantForm
 */
export interface MortgagePartialApplicantForm {
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicantForm
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicantForm
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicantForm
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicantForm
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicantForm
     */
    personalNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicantForm
     */
    mobilePhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicantForm
     */
    landPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicantForm
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicantForm
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicantForm
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicantForm
     */
    city?: string | null;
    /**
     * 
     * @type {MaritalStatus}
     * @memberof MortgagePartialApplicantForm
     */
    maritalStatus?: MaritalStatus;
    /**
     * 
     * @type {Employment}
     * @memberof MortgagePartialApplicantForm
     */
    employment?: Employment;
    /**
     * 
     * @type {Housing}
     * @memberof MortgagePartialApplicantForm
     */
    housing?: Housing;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicantForm
     */
    operationalCost?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicantForm
     */
    ownershipPercentage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicantForm
     */
    taxablePropertyValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicantForm
     */
    numberOfRooms?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicantForm
     */
    moveInDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicantForm
     */
    incomeBeforeTax?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicantForm
     */
    otherIncomeBeforeTax?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicantForm
     */
    employerName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicantForm
     */
    employmentFromYear?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicantForm
     */
    employmentFromMonth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicantForm
     */
    employmentUntilYear?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicantForm
     */
    employmentUntilMonth?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicantForm
     */
    employerPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicantForm
     */
    profession?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicantForm
     */
    organizationNumber?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicantForm
     */
    yearOfBirth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicantForm
     */
    incomeBeforeTaxWhenRetired?: number | null;
}
/**
 * 
 * @export
 * @interface MortgagePartialApplicationForm
 */
export interface MortgagePartialApplicationForm {
    /**
     * 
     * @type {MortgagePartialApplicantForm}
     * @memberof MortgagePartialApplicationForm
     */
    applicant?: MortgagePartialApplicantForm;
    /**
     * 
     * @type {MortgagePartialApplicantForm}
     * @memberof MortgagePartialApplicationForm
     */
    coApplicant?: MortgagePartialApplicantForm;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePartialApplicationForm
     */
    hasCoApplicant?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicationForm
     */
    associationName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicationForm
     */
    associationStreetAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicationForm
     */
    association?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicationForm
     */
    associationOrganisationNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicationForm
     */
    propertyNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicationForm
     */
    propertyCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicationForm
     */
    propertyCounty?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicationForm
     */
    propertyCountyCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicationForm
     */
    propertyPostalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicationForm
     */
    propertyAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicationForm
     */
    googleAnalyticsClientId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    propertyAreaSquareMeter?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    propertyValueEstimate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    propertyMonthlyCost?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    propertyAmortizationAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    propertyInterestRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    propertyOwnerShipShare?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    propertyNumberOfRooms?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicationForm
     */
    propertyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicationForm
     */
    propertyUrl?: string | null;
    /**
     * 
     * @type {Housing}
     * @memberof MortgagePartialApplicationForm
     */
    propertyHousing?: Housing;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    propertyHousingCost?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicationForm
     */
    propertyDesignation?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    propertyBuildingYear?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    propertyAssessValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    purchaseAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    downPayment?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    loanAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    paybackYears?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    preferredInterestRate?: number | null;
    /**
     * 
     * @type {MortgageLoanPurpose}
     * @memberof MortgagePartialApplicationForm
     */
    loanPurpose?: MortgageLoanPurpose;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    existingMortgageLoanAmount?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicationForm
     */
    existingMortgageLoanBank?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    existingMortgageLoanMonthlyCost?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    existingMortgageLoanAmortizationAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    existingMortgageLoanInterestRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    existingMortgageLoanOwnershipShare?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    existingMortgageAssessValue?: number | null;
    /**
     * 
     * @type {Array<AdditionalMortgageLoan>}
     * @memberof MortgagePartialApplicationForm
     */
    additionalMortgageLoans?: Array<AdditionalMortgageLoan> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePartialApplicationForm
     */
    hasExistingPrivateLoan?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    existingPrivateLoanAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    existingPrivateLoanMonthlyCost?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    existingPrivateLoanAmortizationAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    existingPrivateLoanInterestRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    existingPrivateLoanOwnershipShare?: number | null;
    /**
     * 
     * @type {Array<AdditionalPrivateLoan>}
     * @memberof MortgagePartialApplicationForm
     */
    additionalPrivateLoans?: Array<AdditionalPrivateLoan> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePartialApplicationForm
     */
    hasExistingStudentLoan?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    existingStudentLoanAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    existingStudentLoanMonthlyCost?: number | null;
    /**
     * 
     * @type {Array<AdditionalMortgageLoan>}
     * @memberof MortgagePartialApplicationForm
     */
    housings?: Array<AdditionalMortgageLoan> | null;
    /**
     * 
     * @type {Array<AdditionalPrivateLoan>}
     * @memberof MortgagePartialApplicationForm
     */
    privateLoans?: Array<AdditionalPrivateLoan> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePartialApplicationForm
     */
    hasChildren?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    numberOfChildrenPayingAlimony?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MortgagePartialApplicationForm
     */
    numberOfChildrenPayingAlimonyAges?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    numberOfChildrenReceivingAlimony?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MortgagePartialApplicationForm
     */
    numberOfChildrenReceivingAlimonyAges?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    numberOfChildrenReceivingBenefit?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MortgagePartialApplicationForm
     */
    numberOfChildrenReceivingBenefitAges?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    numberOfChildrenSharedCustody?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MortgagePartialApplicationForm
     */
    numberOfChildrenSharedCustodyAges?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    travelExpenses?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePartialApplicationForm
     */
    canMoveSavings?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    monthlySavings?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    savingOnBankAccounts?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    savingsInStocks?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    savingsOnMutualFunds?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicationForm
     */
    uploadedDocumentationUrls?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicationForm
     */
    propertyCadastralDesignation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicationForm
     */
    notes?: string | null;
    /**
     * 
     * @type {Array<MortgageUtmParams>}
     * @memberof MortgagePartialApplicationForm
     */
    utmParams?: Array<MortgageUtmParams> | null;
    /**
     * 
     * @type {MortgageUtmParams}
     * @memberof MortgagePartialApplicationForm
     */
    utmParamsLast?: MortgageUtmParams;
    /**
     * 
     * @type {Array<ReferralParams>}
     * @memberof MortgagePartialApplicationForm
     */
    referralParams?: Array<ReferralParams> | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicationForm
     */
    referralUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicationForm
     */
    referralSource?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicationForm
     */
    clientCookies?: string | null;
    /**
     * 
     * @type {AmortizationRule}
     * @memberof MortgagePartialApplicationForm
     */
    propertyAmortizationRule?: AmortizationRule;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    propertyAmountFromPreviousSell?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    propertyApartmentFloor?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicationForm
     */
    propertyBuyingDate?: string | null;
    /**
     * 
     * @type {AppliedWith}
     * @memberof MortgagePartialApplicationForm
     */
    propertyAppliedWith?: AppliedWith;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePartialApplicationForm
     */
    propertyIsNewProduction?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePartialApplicationForm
     */
    propertyHasElevator?: boolean | null;
    /**
     * 
     * @type {PropertyBalconyType}
     * @memberof MortgagePartialApplicationForm
     */
    propertyBalconyType?: PropertyBalconyType;
    /**
     * 
     * @type {SkandiaLoanPurpose}
     * @memberof MortgagePartialApplicationForm
     */
    skandiaLoanPurpose?: SkandiaLoanPurpose;
    /**
     * 
     * @type {MarginalenLoanPurpose}
     * @memberof MortgagePartialApplicationForm
     */
    marginalenLoanPurpose?: MarginalenLoanPurpose;
    /**
     * 
     * @type {LandshypotekLoanPurpose}
     * @memberof MortgagePartialApplicationForm
     */
    landshypotekLoanPurpose?: LandshypotekLoanPurpose;
    /**
     * 
     * @type {SveaLoanPurpose}
     * @memberof MortgagePartialApplicationForm
     */
    sveaLoanPurpose?: SveaLoanPurpose;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePartialApplicationForm
     */
    sellingContractSigned?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    brokerFee?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    bridgeLoanAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    existingLoanAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    totalSavingsInSkandia?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePartialApplicationForm
     */
    propertyHavingOtherOwner?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    partnerMonthlyIncome?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    coApplicantPartnersMonthlyIncome?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    propertySellingPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    propertyStartingPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    leadId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePartialApplicationForm
     */
    occupationalPensionInSkandia?: boolean;
    /**
     * 
     * @type {SkandiaExcessAmountIntention}
     * @memberof MortgagePartialApplicationForm
     */
    skandiaExcessAmountIntention?: SkandiaExcessAmountIntention;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    skandiaExcessAmountMonthlyCost?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    currentMortgageBank?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    propertyPurchacePrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    originalApplicationId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicationForm
     */
    entryDate?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePartialApplicationForm
     */
    askedAboutSf?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePartialApplicationForm
     */
    doNotShowToOtherBrokers?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    extraIncome?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialApplicationForm
     */
    extraCost?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicationForm
     */
    energyClass?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicationForm
     */
    houseIdToken?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialApplicationForm
     */
    houseIdMoveToken?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePartialApplicationForm
     */
    agreeToTerms?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePartialApplicationForm
     */
    agreeToInformationAndMarketing?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePartialApplicationForm
     */
    agreeToHouseId?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePartialApplicationForm
     */
    childrenSharedCustody?: boolean | null;
}
/**
 * 
 * @export
 * @interface MortgagePartialContactApplicationForm
 */
export interface MortgagePartialContactApplicationForm {
    /**
     * 
     * @type {MortgagePartialApplicantForm}
     * @memberof MortgagePartialContactApplicationForm
     */
    applicant?: MortgagePartialApplicantForm;
    /**
     * 
     * @type {MortgagePartialApplicantForm}
     * @memberof MortgagePartialContactApplicationForm
     */
    coApplicant?: MortgagePartialApplicantForm;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePartialContactApplicationForm
     */
    hasCoApplicant?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialContactApplicationForm
     */
    associationName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialContactApplicationForm
     */
    associationStreetAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialContactApplicationForm
     */
    association?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialContactApplicationForm
     */
    associationOrganisationNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertyNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertyCity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertyCounty?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertyCountyCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertyPostalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertyAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialContactApplicationForm
     */
    googleAnalyticsClientId?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertyAreaSquareMeter?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertyValueEstimate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertyMonthlyCost?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertyAmortizationAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertyInterestRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertyOwnerShipShare?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertyNumberOfRooms?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertyUrl?: string | null;
    /**
     * 
     * @type {Housing}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertyHousing?: Housing;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertyHousingCost?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertyDesignation?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertyBuildingYear?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertyAssessValue?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    purchaseAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    downPayment?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    loanAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    paybackYears?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    preferredInterestRate?: number | null;
    /**
     * 
     * @type {MortgageLoanPurpose}
     * @memberof MortgagePartialContactApplicationForm
     */
    loanPurpose?: MortgageLoanPurpose;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    existingMortgageLoanAmount?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialContactApplicationForm
     */
    existingMortgageLoanBank?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    existingMortgageLoanMonthlyCost?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    existingMortgageLoanAmortizationAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    existingMortgageLoanInterestRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    existingMortgageLoanOwnershipShare?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    existingMortgageAssessValue?: number | null;
    /**
     * 
     * @type {Array<AdditionalMortgageLoan>}
     * @memberof MortgagePartialContactApplicationForm
     */
    additionalMortgageLoans?: Array<AdditionalMortgageLoan> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePartialContactApplicationForm
     */
    hasExistingPrivateLoan?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    existingPrivateLoanAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    existingPrivateLoanMonthlyCost?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    existingPrivateLoanAmortizationAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    existingPrivateLoanInterestRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    existingPrivateLoanOwnershipShare?: number | null;
    /**
     * 
     * @type {Array<AdditionalPrivateLoan>}
     * @memberof MortgagePartialContactApplicationForm
     */
    additionalPrivateLoans?: Array<AdditionalPrivateLoan> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePartialContactApplicationForm
     */
    hasExistingStudentLoan?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    existingStudentLoanAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    existingStudentLoanMonthlyCost?: number | null;
    /**
     * 
     * @type {Array<AdditionalMortgageLoan>}
     * @memberof MortgagePartialContactApplicationForm
     */
    housings?: Array<AdditionalMortgageLoan> | null;
    /**
     * 
     * @type {Array<AdditionalPrivateLoan>}
     * @memberof MortgagePartialContactApplicationForm
     */
    privateLoans?: Array<AdditionalPrivateLoan> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePartialContactApplicationForm
     */
    hasChildren?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    numberOfChildrenPayingAlimony?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MortgagePartialContactApplicationForm
     */
    numberOfChildrenPayingAlimonyAges?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    numberOfChildrenReceivingAlimony?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MortgagePartialContactApplicationForm
     */
    numberOfChildrenReceivingAlimonyAges?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    numberOfChildrenReceivingBenefit?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MortgagePartialContactApplicationForm
     */
    numberOfChildrenReceivingBenefitAges?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    numberOfChildrenSharedCustody?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MortgagePartialContactApplicationForm
     */
    numberOfChildrenSharedCustodyAges?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    travelExpenses?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePartialContactApplicationForm
     */
    canMoveSavings?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    monthlySavings?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    savingOnBankAccounts?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    savingsInStocks?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    savingsOnMutualFunds?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialContactApplicationForm
     */
    uploadedDocumentationUrls?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertyCadastralDesignation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialContactApplicationForm
     */
    notes?: string | null;
    /**
     * 
     * @type {Array<MortgageUtmParams>}
     * @memberof MortgagePartialContactApplicationForm
     */
    utmParams?: Array<MortgageUtmParams> | null;
    /**
     * 
     * @type {MortgageUtmParams}
     * @memberof MortgagePartialContactApplicationForm
     */
    utmParamsLast?: MortgageUtmParams;
    /**
     * 
     * @type {Array<ReferralParams>}
     * @memberof MortgagePartialContactApplicationForm
     */
    referralParams?: Array<ReferralParams> | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialContactApplicationForm
     */
    referralUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialContactApplicationForm
     */
    referralSource?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialContactApplicationForm
     */
    clientCookies?: string | null;
    /**
     * 
     * @type {AmortizationRule}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertyAmortizationRule?: AmortizationRule;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertyAmountFromPreviousSell?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertyApartmentFloor?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertyBuyingDate?: string | null;
    /**
     * 
     * @type {AppliedWith}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertyAppliedWith?: AppliedWith;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertyIsNewProduction?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertyHasElevator?: boolean | null;
    /**
     * 
     * @type {PropertyBalconyType}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertyBalconyType?: PropertyBalconyType;
    /**
     * 
     * @type {SkandiaLoanPurpose}
     * @memberof MortgagePartialContactApplicationForm
     */
    skandiaLoanPurpose?: SkandiaLoanPurpose;
    /**
     * 
     * @type {MarginalenLoanPurpose}
     * @memberof MortgagePartialContactApplicationForm
     */
    marginalenLoanPurpose?: MarginalenLoanPurpose;
    /**
     * 
     * @type {LandshypotekLoanPurpose}
     * @memberof MortgagePartialContactApplicationForm
     */
    landshypotekLoanPurpose?: LandshypotekLoanPurpose;
    /**
     * 
     * @type {SveaLoanPurpose}
     * @memberof MortgagePartialContactApplicationForm
     */
    sveaLoanPurpose?: SveaLoanPurpose;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePartialContactApplicationForm
     */
    sellingContractSigned?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    brokerFee?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    bridgeLoanAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    existingLoanAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    totalSavingsInSkandia?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertyHavingOtherOwner?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    partnerMonthlyIncome?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    coApplicantPartnersMonthlyIncome?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertySellingPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertyStartingPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    leadId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePartialContactApplicationForm
     */
    occupationalPensionInSkandia?: boolean;
    /**
     * 
     * @type {SkandiaExcessAmountIntention}
     * @memberof MortgagePartialContactApplicationForm
     */
    skandiaExcessAmountIntention?: SkandiaExcessAmountIntention;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    skandiaExcessAmountMonthlyCost?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    currentMortgageBank?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    propertyPurchacePrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    originalApplicationId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialContactApplicationForm
     */
    entryDate?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePartialContactApplicationForm
     */
    askedAboutSf?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePartialContactApplicationForm
     */
    doNotShowToOtherBrokers?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    extraIncome?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgagePartialContactApplicationForm
     */
    extraCost?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialContactApplicationForm
     */
    energyClass?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialContactApplicationForm
     */
    houseIdToken?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialContactApplicationForm
     */
    houseIdMoveToken?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePartialContactApplicationForm
     */
    agreeToTerms?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePartialContactApplicationForm
     */
    agreeToInformationAndMarketing?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePartialContactApplicationForm
     */
    agreeToHouseId?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgagePartialContactApplicationForm
     */
    childrenSharedCustody?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialContactApplicationForm
     */
    contactEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialContactApplicationForm
     */
    contactName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialContactApplicationForm
     */
    contactPhone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgagePartialContactApplicationForm
     */
    contactQuestion?: string | null;
}
/**
 * 
 * @export
 * @interface MortgageQuestion
 */
export interface MortgageQuestion {
    /**
     * 
     * @type {string}
     * @memberof MortgageQuestion
     */
    key?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageQuestion
     */
    text?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageQuestion
     */
    field?: string | null;
    /**
     * 
     * @type {FieldType}
     * @memberof MortgageQuestion
     */
    type?: FieldType;
    /**
     * 
     * @type {Array<string>}
     * @memberof MortgageQuestion
     */
    options?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageQuestion
     */
    forApplicant?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageQuestion
     */
    forPrivateLoans?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageQuestion
     */
    forOwnedCars?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageQuestion
     */
    forHousing?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageQuestion
     */
    isCollection?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestion
     */
    rows?: number;
    /**
     * 
     * @type {string}
     * @memberof MortgageQuestion
     */
    tooltip?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MortgageQuestion
     */
    descriptions?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageQuestion
     */
    descriptionsText?: string | null;
}
/**
 * 
 * @export
 * @interface MortgageQuestionsForm
 */
export interface MortgageQuestionsForm {
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestionsForm
     */
    propertyAmountFromPreviousSell?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageQuestionsForm
     */
    propertyBuyingDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestionsForm
     */
    propertyStartingPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestionsForm
     */
    propertySellingPrice?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageQuestionsForm
     */
    sellingContractSigned?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestionsForm
     */
    propertyAmortizationAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestionsForm
     */
    propertyInterestRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestionsForm
     */
    propertyInterestRateType?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestionsForm
     */
    totalSavingsInSkandia?: number | null;
    /**
     * 
     * @type {SkandiaLoanPurpose}
     * @memberof MortgageQuestionsForm
     */
    skandiaLoanPurpose?: SkandiaLoanPurpose;
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestionsForm
     */
    partnerMonthlyIncome?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestionsForm
     */
    propertyOwnerShipShare?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageQuestionsForm
     */
    propertyHavingOtherOwner?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestionsForm
     */
    downPaymentFromHomeSale?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestionsForm
     */
    downPaymentFromSavings?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestionsForm
     */
    downPaymentFromGift?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestionsForm
     */
    downPaymentFromHeritage?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestionsForm
     */
    downPaymentFromLoan?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestionsForm
     */
    downPaymentFromOther?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestionsForm
     */
    bridgeLoanAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestionsForm
     */
    existingLoanAmount?: number | null;
    /**
     * 
     * @type {Array<AdditionalMortgageLoan>}
     * @memberof MortgageQuestionsForm
     */
    housings?: Array<AdditionalMortgageLoan> | null;
    /**
     * 
     * @type {Array<AdditionalPrivateLoan>}
     * @memberof MortgageQuestionsForm
     */
    privateLoans?: Array<AdditionalPrivateLoan> | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestionsForm
     */
    downPaymentFromSavingsDuration?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestionsForm
     */
    downPaymentFromSavingsMonthlyRange?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MortgageQuestionsForm
     */
    downPaymentFromSavingsSource?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestionsForm
     */
    housingCostRange?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageQuestionsForm
     */
    helpWithBroker?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageQuestionsForm
     */
    deferProfitTax?: boolean | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MortgageQuestionsForm
     */
    downPaymentFromHeritageSource?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MortgageQuestionsForm
     */
    downPaymentFromGiftPerson?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestionsForm
     */
    downPaymentFromGiftPersonEmployment?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MortgageQuestionsForm
     */
    downPaymentFromGiftSource?: Array<number> | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MortgageQuestionsForm
     */
    downPaymentFromLoanSource?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestionsForm
     */
    downPaymentFromLoanMonthlyCost?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestionsForm
     */
    downPaymentFromLoanInterestRate?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MortgageQuestionsForm
     */
    downPaymentFromLoanLenderSource?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestionsForm
     */
    downPaymentFromLoanLenderEmployment?: number;
    /**
     * 
     * @type {string}
     * @memberof MortgageQuestionsForm
     */
    downPaymentFromOtherSource?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageQuestionsForm
     */
    needsDownPaymentLoan?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestionsForm
     */
    needsDownPaymentLoanAmount?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageQuestionsForm
     */
    needsBridgeLoan?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageQuestionsForm
     */
    entryDate?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestionsForm
     */
    propertyMonthlyCost?: number | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageQuestionsForm
     */
    propertyNumber?: string | null;
    /**
     * 
     * @type {PropertyBalconyType}
     * @memberof MortgageQuestionsForm
     */
    propertyBalconyType?: PropertyBalconyType;
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestionsForm
     */
    homeMortgageDate?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageQuestionsForm
     */
    propertyHasElevator?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageQuestionsForm
     */
    propertyCadastralDesignation?: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof MortgageQuestionsForm
     */
    renegotiateLoanPurpose?: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageQuestionsForm
     */
    carsInHousehold?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageQuestionsForm
     */
    needsToBorrowDownPayment?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestionsForm
     */
    downPaymentToBorrow?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestionsForm
     */
    currentMortgageLoanAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestionsForm
     */
    currentMortgageBank?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageQuestionsForm
     */
    currentPropertyHasBeenSold?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestionsForm
     */
    howFarInPurchaseProcess?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageQuestionsForm
     */
    agreeToPropertySellBySf?: boolean | null;
    /**
     * 
     * @type {ApplicantAdditionsForm}
     * @memberof MortgageQuestionsForm
     */
    applicant?: ApplicantAdditionsForm;
    /**
     * 
     * @type {ApplicantAdditionsForm}
     * @memberof MortgageQuestionsForm
     */
    coApplicant?: ApplicantAdditionsForm;
}
/**
 * 
 * @export
 * @interface MortgageQuestionsTerms
 */
export interface MortgageQuestionsTerms {
    /**
     * 
     * @type {MortgageLoanPurpose}
     * @memberof MortgageQuestionsTerms
     */
    loanPurpose?: MortgageLoanPurpose;
    /**
     * 
     * @type {Employment}
     * @memberof MortgageQuestionsTerms
     */
    applicantEmployment?: Employment;
    /**
     * 
     * @type {Employment}
     * @memberof MortgageQuestionsTerms
     */
    coApplicantEmployment?: Employment;
    /**
     * 
     * @type {string}
     * @memberof MortgageQuestionsTerms
     */
    applicantPersonalNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageQuestionsTerms
     */
    coApplicantPersonalNumber?: string | null;
    /**
     * 
     * @type {Housing}
     * @memberof MortgageQuestionsTerms
     */
    propertyHousing?: Housing;
    /**
     * 
     * @type {number}
     * @memberof MortgageQuestionsTerms
     */
    downPayment?: number;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageQuestionsTerms
     */
    withSpecialist?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageQuestionsTerms
     */
    withPremium?: boolean;
    /**
     * 
     * @type {Array<IncomeDeviate>}
     * @memberof MortgageQuestionsTerms
     */
    applicantIncomeDeviates?: Array<IncomeDeviate> | null;
    /**
     * 
     * @type {Array<IncomeDeviate>}
     * @memberof MortgageQuestionsTerms
     */
    coApplicantIncomeDeviates?: Array<IncomeDeviate> | null;
    /**
     * 
     * @type {Array<AdditionalPrivateLoan>}
     * @memberof MortgageQuestionsTerms
     */
    privateLoans?: Array<AdditionalPrivateLoan> | null;
    /**
     * 
     * @type {MaritalStatus}
     * @memberof MortgageQuestionsTerms
     */
    applicantMaritalStatus?: MaritalStatus;
    /**
     * 
     * @type {MaritalStatus}
     * @memberof MortgageQuestionsTerms
     */
    coApplicantMaritalStatus?: MaritalStatus;
}
/**
 * 
 * @export
 * @interface MortgageQuestionsView
 */
export interface MortgageQuestionsView {
    /**
     * 
     * @type {Array<MortgageQuestion>}
     * @memberof MortgageQuestionsView
     */
    questions?: Array<MortgageQuestion> | null;
    /**
     * 
     * @type {MortgageQuestionsForm}
     * @memberof MortgageQuestionsView
     */
    form?: MortgageQuestionsForm;
    /**
     * 
     * @type {MortgageQuestionsTerms}
     * @memberof MortgageQuestionsView
     */
    terms?: MortgageQuestionsTerms;
    /**
     * 
     * @type {boolean}
     * @memberof MortgageQuestionsView
     */
    isCoApplicant?: boolean;
}
/**
 * 
 * @export
 * @interface MortgageSubmitResult
 */
export interface MortgageSubmitResult {
    /**
     * 
     * @type {string}
     * @memberof MortgageSubmitResult
     */
    applicantId?: string;
}
/**
 * 
 * @export
 * @interface MortgageUtmParams
 */
export interface MortgageUtmParams {
    /**
     * 
     * @type {string}
     * @memberof MortgageUtmParams
     */
    website?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageUtmParams
     */
    source?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageUtmParams
     */
    medium?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageUtmParams
     */
    campaign?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageUtmParams
     */
    term?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageUtmParams
     */
    content?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageUtmParams
     */
    device?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageUtmParams
     */
    adposition?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageUtmParams
     */
    placement?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MortgageUtmParams
     */
    adgroup?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum MyPagesAppType {
    Loan = 'Loan',
    Mortgage = 'Mortgage'
}

/**
 * 
 * @export
 * @interface MyPagesRequiredFields
 */
export interface MyPagesRequiredFields {
    /**
     * 
     * @type {boolean}
     * @memberof MyPagesRequiredFields
     */
    propertyAmountFromPreviousSell?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MyPagesRequiredFields
     */
    totalSavingsInSkandia?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MyPagesRequiredFields
     */
    partnerMonthlyIncome?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MyPagesRequiredFields
     */
    propertyHavingOtherOwner?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MyPagesRequiredFields
     */
    skandiaLoanPurpose?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MyPagesRequiredFields
     */
    propertyBuyingDate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MyPagesRequiredFields
     */
    existingLoanAmount?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MyPagesRequiredFields
     */
    bridgeLoanAmount?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MyPagesRequiredFields
     */
    sellingContractSigned?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MyPagesRequiredFields
     */
    propertyAmortizationAmount?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MyPagesRequiredFields
     */
    propertyInterestRate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MyPagesRequiredFields
     */
    propertyOwnerShipShare?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MyPagesRequiredFields
     */
    downPaymentFrom?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MyPagesRequiredFields
     */
    privateLoansDetails?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MyPagesRequiredFields
     */
    housingsDetails?: boolean;
}
/**
 * 
 * @export
 * @interface Offer
 */
export interface Offer {
    /**
     * 
     * @type {Array<IDomainEvent>}
     * @memberof Offer
     */
    domainEvents?: Array<IDomainEvent> | null;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    applicationId?: number;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    externalReference?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    bankId?: number;
    /**
     * 
     * @type {Bank}
     * @memberof Offer
     */
    bank?: Bank;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    approvedAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    interestRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    effectiveRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    monthlyInstallment?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    interestPayment?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    amortizationPayment?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    arrangementFee?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    administrationFee?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    discountRateMonthlyInstallment?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof Offer
     */
    existingCustomer?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    notes?: string | null;
    /**
     * 
     * @type {OfferStatus}
     * @memberof Offer
     */
    status?: OfferStatus;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    modifiedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    activatedAt?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    increasedInterestRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    increasedInterestRateMonths?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    messageToCustomer?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    paybackYears?: number;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    paybackYearsFixed?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    interestBindingPeriod?: string | null;
    /**
     * 
     * @type {AmortizationRule}
     * @memberof Offer
     */
    amortizationRule?: AmortizationRule;
    /**
     * 
     * @type {Array<InterestRates>}
     * @memberof Offer
     */
    possibleInterestRates?: Array<InterestRates> | null;
    /**
     * 
     * @type {boolean}
     * @memberof Offer
     */
    doNotShow?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Offer
     */
    bankStatus?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Offer
     */
    bankStatusTime?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    acceptedById?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    externalCustomerReference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    expiresAt?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Offer
     */
    preApprovedOffer?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    privateLoanApprovedAmount?: number | null;
    /**
     * 
     * @type {Array<OfferDocument>}
     * @memberof Offer
     */
    documents?: Array<OfferDocument> | null;
    /**
     * 
     * @type {Array<OfferSigning>}
     * @memberof Offer
     */
    offerSignings?: Array<OfferSigning> | null;
    /**
     * 
     * @type {Array<OfferMessage>}
     * @memberof Offer
     */
    offerMessages?: Array<OfferMessage> | null;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    dueDate?: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    offerDocumentId?: string;
}
/**
 * 
 * @export
 * @interface OfferDocument
 */
export interface OfferDocument {
    /**
     * 
     * @type {Array<IDomainEvent>}
     * @memberof OfferDocument
     */
    domainEvents?: Array<IDomainEvent> | null;
    /**
     * 
     * @type {string}
     * @memberof OfferDocument
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof OfferDocument
     */
    offerId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OfferDocument
     */
    applicationId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OfferDocument
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfferDocument
     */
    comment?: string | null;
    /**
     * 
     * @type {OfferDocumentType}
     * @memberof OfferDocument
     */
    type?: OfferDocumentType;
    /**
     * 
     * @type {string}
     * @memberof OfferDocument
     */
    externalReference?: string | null;
    /**
     * 
     * @type {OfferDocumentStatus}
     * @memberof OfferDocument
     */
    status?: OfferDocumentStatus;
    /**
     * 
     * @type {string}
     * @memberof OfferDocument
     */
    contentType?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OfferDocument
     */
    contentSize?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferDocument
     */
    filePath?: string | null;
    /**
     * 
     * @type {Array<OfferDocumentContent>}
     * @memberof OfferDocument
     */
    customerUploads?: Array<OfferDocumentContent> | null;
    /**
     * 
     * @type {string}
     * @memberof OfferDocument
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDocument
     */
    modifiedAt?: string;
    /**
     * 
     * @type {User}
     * @memberof OfferDocument
     */
    createdBy?: User;
    /**
     * 
     * @type {string}
     * @memberof OfferDocument
     */
    createdById?: string | null;
    /**
     * 
     * @type {User}
     * @memberof OfferDocument
     */
    modifiedBy?: User;
    /**
     * 
     * @type {string}
     * @memberof OfferDocument
     */
    modifiedById?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfferDocument
     */
    personalNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfferDocument
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfferDocument
     */
    note?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfferDocument
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OfferDocument
     */
    addedByDamsAdvisor?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof OfferDocument
     */
    documentCheck?: boolean | null;
    /**
     * 
     * @type {OfferDocumentContent}
     * @memberof OfferDocument
     */
    getOfferDocumentContent?: OfferDocumentContent;
}
/**
 * 
 * @export
 * @interface OfferDocumentContent
 */
export interface OfferDocumentContent {
    /**
     * 
     * @type {string}
     * @memberof OfferDocumentContent
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDocumentContent
     */
    offerDocumentId?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDocumentContent
     */
    contentType?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OfferDocumentContent
     */
    contentSize?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferDocumentContent
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof OfferDocumentContent
     */
    title?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OfferDocumentContent
     */
    mergedPdf?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof OfferDocumentContent
     */
    sortOrder?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum OfferDocumentStatus {
    Approved = 'Approved',
    Declined = 'Declined',
    Missing = 'Missing',
    WaitingToBeChecked = 'WaitingToBeChecked',
    Withdrawn = 'Withdrawn'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum OfferDocumentType {
    AmortizationPlan = 'AmortizationPlan',
    PetitionForDivorce = 'PetitionForDivorce',
    CertificateOfEmployment = 'CertificateOfEmployment',
    DivisionOfPropertyAgreement = 'DivisionOfPropertyAgreement',
    VerifyEscrowAgreement = 'VerifyEscrowAgreement',
    FinancialForecastingForTenantOwnershipAssociation = 'FinancialForecastingForTenantOwnershipAssociation',
    PowerOfAttorney = 'PowerOfAttorney',
    PhysicalMortgageDeeds = 'PhysicalMortgageDeeds',
    IntermediationAgreementIncludingStartingPrice = 'IntermediationAgreementIncludingStartingPrice',
    DeedOfGift = 'DeedOfGift',
    PurchaseAgreement = 'PurchaseAgreement',
    ContractorsAgreement = 'ContractorsAgreement',
    ApartmentCertificate = 'ApartmentCertificate',
    CollateralObjectDescription = 'CollateralObjectDescription',
    PensionProjection = 'PensionProjection',
    PensionSpecification = 'PensionSpecification',
    AuditorsCertificate = 'AuditorsCertificate',
    LatestApprovedIncomeStatementAndNe3Appendix = 'LatestApprovedIncomeStatementAndNE3Appendix',
    LatestApprovedIncomeStatementAndNeAppendix = 'LatestApprovedIncomeStatementAndNEAppendix',
    FinalInvoice = 'FinalInvoice',
    SalesAgreement = 'SalesAgreement',
    InformationToContactPerson = 'InformationToContactPerson',
    TenureAgreement = 'TenureAgreement',
    Valuation = 'Valuation',
    ValuationOfCompletedProperty = 'ValuationOfCompletedProperty',
    ValuationFromIndependentBroker = 'ValuationFromIndependentBroker',
    TransferAgreement = 'TransferAgreement',
    Other = 'Other',
    ValuationCertificate = 'ValuationCertificate',
    CashDepositDocumentation = 'CashDepositDocumentation',
    ResultsAndBalanceSheet = 'ResultsAndBalanceSheet',
    SalarySpecification = 'SalarySpecification',
    EmploymentContract = 'EmploymentContract',
    EstateInventory = 'EstateInventory',
    EnergyDeclaration = 'EnergyDeclaration',
    Invoices = 'Invoices',
    StatementOfAccount = 'StatementOfAccount',
    CopyOfIdentificationCard = 'CopyOfIdentificationCard',
    KnowYourCustomerQuestions = 'KnowYourCustomerQuestions',
    OriginalBillOfPurchase = 'OriginalBillOfPurchase',
    TitleDeed = 'TitleDeed',
    RetirementPensionInsurance = 'RetirementPensionInsurance',
    PreliminarySettlementNote = 'PreliminarySettlementNote',
    SpouseConsent = 'SpouseConsent',
    InstrumentOfDebt = 'InstrumentOfDebt',
    FinalServeyingProtocol = 'FinalServeyingProtocol',
    LoanTermsOptions = 'LoanTermsOptions',
    AnnualReportHousingSociety = 'AnnualReportHousingSociety',
    OtherIncomeCertificates = 'OtherIncomeCertificates'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum OfferDueDateTextType {
    SkandiaPromissoryLetter = 'SkandiaPromissoryLetter',
    SbabPurchase = 'SbabPurchase',
    SbabRenegotiate = 'SbabRenegotiate',
    SbabPromissoryLetter = 'SbabPromissoryLetter'
}

/**
 * 
 * @export
 * @interface OfferMessage
 */
export interface OfferMessage {
    /**
     * 
     * @type {Array<IDomainEvent>}
     * @memberof OfferMessage
     */
    domainEvents?: Array<IDomainEvent> | null;
    /**
     * 
     * @type {number}
     * @memberof OfferMessage
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferMessage
     */
    offerId?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferMessage
     */
    username?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfferMessage
     */
    userRole?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfferMessage
     */
    createdAt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OfferMessage
     */
    isRead?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfferMessage
     */
    message?: string | null;
}
/**
 * 
 * @export
 * @interface OfferPreset
 */
export interface OfferPreset {
    /**
     * 
     * @type {number}
     * @memberof OfferPreset
     */
    approvedAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OfferPreset
     */
    interestRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OfferPreset
     */
    effectiveRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OfferPreset
     */
    repaymentPeriod?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OfferPreset
     */
    monthlyInstallment?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OfferPreset
     */
    arrangementFee?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OfferPreset
     */
    administrationFee?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof OfferPreset
     */
    existingCustomer?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof OfferPreset
     */
    notes?: string | null;
}
/**
 * 
 * @export
 * @interface OfferSigning
 */
export interface OfferSigning {
    /**
     * 
     * @type {string}
     * @memberof OfferSigning
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof OfferSigning
     */
    offerId?: number;
    /**
     * 
     * @type {string}
     * @memberof OfferSigning
     */
    personalNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfferSigning
     */
    externalApplicantId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfferSigning
     */
    signedAt?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OfferSigning
     */
    signed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OfferSigning
     */
    expiryDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OfferSigning
     */
    createdAt?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum OfferStatus {
    New = 'New',
    Pending = 'Pending',
    Accepted = 'Accepted',
    Declined = 'Declined',
    Error = 'Error',
    ManualHandling = 'ManualHandling',
    Rejected = 'Rejected'
}

/**
 * 
 * @export
 * @interface OfferView
 */
export interface OfferView {
    /**
     * 
     * @type {boolean}
     * @memberof OfferView
     */
    accepted?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OfferView
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof OfferView
     */
    administrationFee?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OfferView
     */
    approvedAmount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OfferView
     */
    arrangementFee?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OfferView
     */
    effectiveRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OfferView
     */
    interestRate?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OfferView
     */
    monthlyInstallment?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OfferView
     */
    repaymentPeriod?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OfferView
     */
    requiredRepayment?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OfferView
     */
    insuranceInstallment?: number | null;
    /**
     * 
     * @type {BankView}
     * @memberof OfferView
     */
    bank?: BankView;
}
/**
 * 
 * @export
 * @interface OutgoingLeadForm
 */
export interface OutgoingLeadForm {
    /**
     * 
     * @type {number}
     * @memberof OutgoingLeadForm
     */
    mortgageApplicationId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OutgoingLeadForm
     */
    loanApplicationId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OutgoingLeadForm
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OutgoingLeadForm
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OutgoingLeadForm
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OutgoingLeadForm
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OutgoingLeadForm
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OutgoingLeadForm
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OutgoingLeadForm
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OutgoingLeadForm
     */
    realEstateType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OutgoingLeadForm
     */
    numberOfRooms?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OutgoingLeadForm
     */
    areaSquareMeter?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OutgoingLeadForm
     */
    officeCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OutgoingLeadForm
     */
    officeName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OutgoingLeadForm
     */
    leadAction?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OutgoingLeadForm
     */
    message?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OutgoingLeadForm
     */
    brokerCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OutgoingLeadForm
     */
    brokerName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OutgoingLeadForm
     */
    customerPersonalNumber?: string | null;
}
/**
 * 
 * @export
 * @interface PostView
 */
export interface PostView {
    /**
     * 
     * @type {number}
     * @memberof PostView
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PostView
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostView
     */
    slug?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostView
     */
    intro?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostView
     */
    content?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PostView
     */
    featuredImageID?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum PrivateLoanType {
    Private = 'Private',
    Credit = 'Credit',
    Student = 'Student',
    Car = 'Car',
    Downpayment = 'Downpayment'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum PropertyBalconyType {
    Balcony = 'Balcony',
    None = 'None',
    Patio = 'Patio'
}

/**
 * 
 * @export
 * @interface RecommendationRequest
 */
export interface RecommendationRequest {
    /**
     * 
     * @type {string}
     * @memberof RecommendationRequest
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RecommendationRequest
     */
    phoneNumber?: string | null;
}
/**
 * 
 * @export
 * @interface ReferralParams
 */
export interface ReferralParams {
    /**
     * 
     * @type {string}
     * @memberof ReferralParams
     */
    cn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReferralParams
     */
    cv?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReferralParams
     */
    atGb?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReferralParams
     */
    source?: string | null;
}
/**
 * 
 * @export
 * @interface RequiredFields
 */
export interface RequiredFields {
    /**
     * 
     * @type {boolean}
     * @memberof RequiredFields
     */
    propertyAmountFromPreviousSell?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequiredFields
     */
    totalSavingsInSkandia?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequiredFields
     */
    partnerMonthlyIncome?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequiredFields
     */
    propertyHavingOtherOwner?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequiredFields
     */
    skandiaLoanPurpose?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequiredFields
     */
    propertyBuyingDate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequiredFields
     */
    existingLoanAmount?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequiredFields
     */
    bridgeLoanAmount?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequiredFields
     */
    sellingContractSigned?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequiredFields
     */
    propertyAmortizationAmount?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequiredFields
     */
    propertyInterestRate?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequiredFields
     */
    propertyOwnerShipShare?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequiredFields
     */
    downPaymentFrom?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequiredFields
     */
    privateLoansDetails?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequiredFields
     */
    housingsDetails?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequiredFields
     */
    applicantOrganizationNumber?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequiredFields
     */
    coApplicantOrganizationNumber?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequiredFields
     */
    propertyAmortizationRule?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequiredFields
     */
    propertyApartmentFloor?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequiredFields
     */
    propertyAppliedWith?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequiredFields
     */
    propertyIsNewProduction?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequiredFields
     */
    propertyHasElevator?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequiredFields
     */
    propertyBalconyType?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequiredFields
     */
    marginalenLoanPurpose?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequiredFields
     */
    brokerFee?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequiredFields
     */
    occupationalPensionInSkandia?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequiredFields
     */
    propertyHousingCost?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequiredFields
     */
    coApplicantPartnersMonthlyIncome?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequiredFields
     */
    propertySellingPrice?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequiredFields
     */
    propertyStartingPrice?: boolean;
}
/**
 * 
 * @export
 * @interface SelectOffer
 */
export interface SelectOffer {
    /**
     * 
     * @type {AccountInfo}
     * @memberof SelectOffer
     */
    account?: AccountInfo;
    /**
     * 
     * @type {boolean}
     * @memberof SelectOffer
     */
    withInsurance?: boolean;
    /**
     * 
     * @type {AccountInfo}
     * @memberof SelectOffer
     */
    insuranceAccount?: AccountInfo;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SkandiaExcessAmountIntention {
    BuyingProperty = 'BuyingProperty',
    RepaymentOfOtherLoansAndCredits = 'RepaymentOfOtherLoansAndCredits',
    RenovationOfProperty = 'RenovationOfProperty',
    BuyingCapitalGoods = 'BuyingCapitalGoods',
    OtherConsumption = 'OtherConsumption'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum SkandiaLoanPurpose {
    Buy = 'Buy',
    BuyAndSell = 'BuyAndSell',
    BuyAndSplit = 'BuyAndSplit',
    Move = 'Move',
    New = 'New'
}

/**
 * 
 * @export
 * @interface SubmitLoanApplicationResult
 */
export interface SubmitLoanApplicationResult {
    /**
     * 
     * @type {string}
     * @memberof SubmitLoanApplicationResult
     */
    redirect?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SubmitLoanApplicationResult
     */
    qa?: boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum SveaLoanPurpose {
    CarOrBoat = 'carOrBoat',
    MedicalCare = 'medicalCare',
    Apartment = 'apartment',
    Travel = 'travel',
    Consumption = 'consumption',
    PayOffLoan = 'payOffLoan',
    InvestInLiving = 'investInLiving',
    Other = 'other'
}

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isAllowed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    personalNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    displayName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    bankID?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isSales?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isSenior?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    passwordHash?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    showInOptions?: boolean;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    mortgageBankID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    role?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    manager?: string | null;
    /**
     * 
     * @type {User}
     * @memberof User
     */
    managerUser?: User;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    documentCheck?: boolean | null;
}
/**
 * 
 * @export
 * @interface UserView
 */
export interface UserView {
    /**
     * 
     * @type {string}
     * @memberof UserView
     */
    personalNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserView
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserView
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserView
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserView
     */
    givenName?: string | null;
    /**
     * 
     * @type {MyPagesAppType}
     * @memberof UserView
     */
    appType?: MyPagesAppType;
    /**
     * 
     * @type {boolean}
     * @memberof UserView
     */
    myPagesOfferPageViewed?: boolean;
}
/**
 * 
 * @export
 * @interface UtmParams
 */
export interface UtmParams {
    /**
     * 
     * @type {string}
     * @memberof UtmParams
     */
    website?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtmParams
     */
    source?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtmParams
     */
    medium?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtmParams
     */
    campaign?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtmParams
     */
    term?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtmParams
     */
    content?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtmParams
     */
    device?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtmParams
     */
    adposition?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UtmParams
     */
    placement?: string | null;
}
/**
 * 
 * @export
 * @interface WebApplicationForm
 */
export interface WebApplicationForm {
    /**
     * 
     * @type {ApplicantForm}
     * @memberof WebApplicationForm
     */
    applicant?: ApplicantForm;
    /**
     * 
     * @type {ApplicantForm}
     * @memberof WebApplicationForm
     */
    coApplicant?: ApplicantForm;
    /**
     * 
     * @type {Array<ExistingLoans>}
     * @memberof WebApplicationForm
     */
    existingLoan?: Array<ExistingLoans> | null;
    /**
     * 
     * @type {number}
     * @memberof WebApplicationForm
     */
    loanAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof WebApplicationForm
     */
    paybackYears?: number;
    /**
     * 
     * @type {number}
     * @memberof WebApplicationForm
     */
    purposeID?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WebApplicationForm
     */
    consolidate?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WebApplicationForm
     */
    googleAnalyticsClientId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApplicationForm
     */
    clientCookies?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApplicationForm
     */
    couponCode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof WebApplicationForm
     */
    referralOriginalID?: number | null;
    /**
     * 
     * @type {string}
     * @memberof WebApplicationForm
     */
    googleMarketChannel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof WebApplicationForm
     */
    campaignID?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WebApplicationForm
     */
    tracking?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WebApplicationForm
     */
    leadId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof WebApplicationForm
     */
    hasCoApplicant?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WebApplicationForm
     */
    notes?: string | null;
    /**
     * 
     * @type {Array<UtmParams>}
     * @memberof WebApplicationForm
     */
    utmParams?: Array<UtmParams> | null;
    /**
     * 
     * @type {Array<ReferralParams>}
     * @memberof WebApplicationForm
     */
    referralParams?: Array<ReferralParams> | null;
    /**
     * 
     * @type {boolean}
     * @memberof WebApplicationForm
     */
    doNotShowToOtherBrokers?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof WebApplicationForm
     */
    subOwnedByID?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof WebApplicationForm
     */
    agreeToTerms?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof WebApplicationForm
     */
    agreeToMortgage?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof WebApplicationForm
     */
    currentLoanAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof WebApplicationForm
     */
    purpose?: string | null;
}

/**
 * ApplicationApi - axios parameter creator
 * @export
 */
export const ApplicationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveApplications: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/application`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationApi - functional programming interface
 * @export
 */
export const ApplicationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveApplications(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CustomerApplicationsView>> {
            const localVarAxiosArgs = await ApplicationApiAxiosParamCreator(configuration).getActiveApplications(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ApplicationApi - factory interface
 * @export
 */
export const ApplicationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveApplications(options?: any): AxiosPromise<CustomerApplicationsView> {
            return ApplicationApiFp(configuration).getActiveApplications(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApplicationApi - object-oriented interface
 * @export
 * @class ApplicationApi
 * @extends {BaseAPI}
 */
export class ApplicationApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationApi
     */
    public getActiveApplications(options?: any) {
        return ApplicationApiFp(this.configuration).getActiveApplications(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} orderRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdCancel: async (orderRef: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderRef' is not null or undefined
            if (orderRef === null || orderRef === undefined) {
                throw new RequiredError('orderRef','Required parameter orderRef was null or undefined when calling bankIdCancel.');
            }
            const localVarPath = `/auth/bankid/cancel/{orderRef}`
                .replace(`{${"orderRef"}}`, encodeURIComponent(String(orderRef)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdCollect: async (orderRef: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderRef' is not null or undefined
            if (orderRef === null || orderRef === undefined) {
                throw new RequiredError('orderRef','Required parameter orderRef was null or undefined when calling bankIdCollect.');
            }
            const localVarPath = `/auth/bankid/collect/{orderRef}`
                .replace(`{${"orderRef"}}`, encodeURIComponent(String(orderRef)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [personalNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdStart: async (personalNumber?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/bankid/start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (personalNumber !== undefined) {
                localVarQueryParameter['personalNumber'] = personalNumber;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [applicantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdStartApplicant: async (applicantId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/bankid/start-applicant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (applicantId !== undefined) {
                localVarQueryParameter['applicantId'] = applicantId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdV6Cancel: async (orderRef: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderRef' is not null or undefined
            if (orderRef === null || orderRef === undefined) {
                throw new RequiredError('orderRef','Required parameter orderRef was null or undefined when calling bankIdV6Cancel.');
            }
            const localVarPath = `/auth/bankid/v6/cancel/{orderRef}`
                .replace(`{${"orderRef"}}`, encodeURIComponent(String(orderRef)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdV6Collect: async (orderRef: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderRef' is not null or undefined
            if (orderRef === null || orderRef === undefined) {
                throw new RequiredError('orderRef','Required parameter orderRef was null or undefined when calling bankIdV6Collect.');
            }
            const localVarPath = `/auth/bankid/v6/collect/{orderRef}`
                .replace(`{${"orderRef"}}`, encodeURIComponent(String(orderRef)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdV6Start: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/bankid/v6/start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [applicantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdV6StartApplicant: async (applicantId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/bankid/v6/start-applicant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (applicantId !== undefined) {
                localVarQueryParameter['applicantId'] = applicantId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginToken: async (token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling loginToken.');
            }
            const localVarPath = `/auth/login/{token}`
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} orderRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankIdCancel(orderRef: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).bankIdCancel(orderRef, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orderRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankIdCollect(orderRef: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectResponse>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).bankIdCollect(orderRef, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [personalNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankIdStart(personalNumber?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthResponse>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).bankIdStart(personalNumber, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [applicantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankIdStartApplicant(applicantId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthResponse>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).bankIdStartApplicant(applicantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orderRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankIdV6Cancel(orderRef: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).bankIdV6Cancel(orderRef, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} orderRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankIdV6Collect(orderRef: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CollectResponseV6>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).bankIdV6Collect(orderRef, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankIdV6Start(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientAuthResponseV6>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).bankIdV6Start(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [applicantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bankIdV6StartApplicant(applicantId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientAuthResponseV6>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).bankIdV6StartApplicant(applicantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserView>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).getUser(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginToken(token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserView>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).loginToken(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await AuthApiAxiosParamCreator(configuration).logout(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} orderRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdCancel(orderRef: string, options?: any): AxiosPromise<void> {
            return AuthApiFp(configuration).bankIdCancel(orderRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdCollect(orderRef: string, options?: any): AxiosPromise<CollectResponse> {
            return AuthApiFp(configuration).bankIdCollect(orderRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [personalNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdStart(personalNumber?: string, options?: any): AxiosPromise<AuthResponse> {
            return AuthApiFp(configuration).bankIdStart(personalNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [applicantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdStartApplicant(applicantId?: string, options?: any): AxiosPromise<AuthResponse> {
            return AuthApiFp(configuration).bankIdStartApplicant(applicantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdV6Cancel(orderRef: string, options?: any): AxiosPromise<void> {
            return AuthApiFp(configuration).bankIdV6Cancel(orderRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderRef 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdV6Collect(orderRef: string, options?: any): AxiosPromise<CollectResponseV6> {
            return AuthApiFp(configuration).bankIdV6Collect(orderRef, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdV6Start(options?: any): AxiosPromise<ClientAuthResponseV6> {
            return AuthApiFp(configuration).bankIdV6Start(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [applicantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bankIdV6StartApplicant(applicantId?: string, options?: any): AxiosPromise<ClientAuthResponseV6> {
            return AuthApiFp(configuration).bankIdV6StartApplicant(applicantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(options?: any): AxiosPromise<UserView> {
            return AuthApiFp(configuration).getUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} token 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginToken(token: string, options?: any): AxiosPromise<UserView> {
            return AuthApiFp(configuration).loginToken(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): AxiosPromise<void> {
            return AuthApiFp(configuration).logout(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {string} orderRef 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public bankIdCancel(orderRef: string, options?: any) {
        return AuthApiFp(this.configuration).bankIdCancel(orderRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderRef 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public bankIdCollect(orderRef: string, options?: any) {
        return AuthApiFp(this.configuration).bankIdCollect(orderRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [personalNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public bankIdStart(personalNumber?: string, options?: any) {
        return AuthApiFp(this.configuration).bankIdStart(personalNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [applicantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public bankIdStartApplicant(applicantId?: string, options?: any) {
        return AuthApiFp(this.configuration).bankIdStartApplicant(applicantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderRef 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public bankIdV6Cancel(orderRef: string, options?: any) {
        return AuthApiFp(this.configuration).bankIdV6Cancel(orderRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderRef 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public bankIdV6Collect(orderRef: string, options?: any) {
        return AuthApiFp(this.configuration).bankIdV6Collect(orderRef, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public bankIdV6Start(options?: any) {
        return AuthApiFp(this.configuration).bankIdV6Start(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [applicantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public bankIdV6StartApplicant(applicantId?: string, options?: any) {
        return AuthApiFp(this.configuration).bankIdV6StartApplicant(applicantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getUser(options?: any) {
        return AuthApiFp(this.configuration).getUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public loginToken(token: string, options?: any) {
        return AuthApiFp(this.configuration).loginToken(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public logout(options?: any) {
        return AuthApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContactApi - axios parameter creator
 * @export
 */
export const ContactApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MortgageContactForm} [mortgageContactForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submit: async (mortgageContactForm?: MortgageContactForm, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/contact/mortgage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof mortgageContactForm !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(mortgageContactForm !== undefined ? mortgageContactForm : {})
                : (mortgageContactForm || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MortgagePartialContactApplicationForm} [mortgagePartialContactApplicationForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitPartialMortageForm: async (mortgagePartialContactApplicationForm?: MortgagePartialContactApplicationForm, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/contact/mortgage-partial`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof mortgagePartialContactApplicationForm !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(mortgagePartialContactApplicationForm !== undefined ? mortgagePartialContactApplicationForm : {})
                : (mortgagePartialContactApplicationForm || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactApi - functional programming interface
 * @export
 */
export const ContactApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MortgageContactForm} [mortgageContactForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submit(mortgageContactForm?: MortgageContactForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContactApiAxiosParamCreator(configuration).submit(mortgageContactForm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {MortgagePartialContactApplicationForm} [mortgagePartialContactApplicationForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitPartialMortageForm(mortgagePartialContactApplicationForm?: MortgagePartialContactApplicationForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await ContactApiAxiosParamCreator(configuration).submitPartialMortageForm(mortgagePartialContactApplicationForm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * ContactApi - factory interface
 * @export
 */
export const ContactApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {MortgageContactForm} [mortgageContactForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submit(mortgageContactForm?: MortgageContactForm, options?: any): AxiosPromise<void> {
            return ContactApiFp(configuration).submit(mortgageContactForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MortgagePartialContactApplicationForm} [mortgagePartialContactApplicationForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitPartialMortageForm(mortgagePartialContactApplicationForm?: MortgagePartialContactApplicationForm, options?: any): AxiosPromise<void> {
            return ContactApiFp(configuration).submitPartialMortageForm(mortgagePartialContactApplicationForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactApi - object-oriented interface
 * @export
 * @class ContactApi
 * @extends {BaseAPI}
 */
export class ContactApi extends BaseAPI {
    /**
     * 
     * @param {MortgageContactForm} [mortgageContactForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public submit(mortgageContactForm?: MortgageContactForm, options?: any) {
        return ContactApiFp(this.configuration).submit(mortgageContactForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MortgagePartialContactApplicationForm} [mortgagePartialContactApplicationForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactApi
     */
    public submitPartialMortageForm(mortgagePartialContactApplicationForm?: MortgagePartialContactApplicationForm, options?: any) {
        return ContactApiFp(this.configuration).submitPartialMortageForm(mortgagePartialContactApplicationForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GdprApi - axios parameter creator
 * @export
 */
export const GdprApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenicate: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/gdpr/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GdprRequestForm} [gdprRequestForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitErasureRequest: async (gdprRequestForm?: GdprRequestForm, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/gdpr/erasure`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof gdprRequestForm !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(gdprRequestForm !== undefined ? gdprRequestForm : {})
                : (gdprRequestForm || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GdprRequestForm} [gdprRequestForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitSubjectAccessRequest: async (gdprRequestForm?: GdprRequestForm, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/gdpr/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof gdprRequestForm !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(gdprRequestForm !== undefined ? gdprRequestForm : {})
                : (gdprRequestForm || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GdprApi - functional programming interface
 * @export
 */
export const GdprApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenicate(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await GdprApiAxiosParamCreator(configuration).authenicate(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {GdprRequestForm} [gdprRequestForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitErasureRequest(gdprRequestForm?: GdprRequestForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await GdprApiAxiosParamCreator(configuration).submitErasureRequest(gdprRequestForm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {GdprRequestForm} [gdprRequestForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitSubjectAccessRequest(gdprRequestForm?: GdprRequestForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await GdprApiAxiosParamCreator(configuration).submitSubjectAccessRequest(gdprRequestForm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * GdprApi - factory interface
 * @export
 */
export const GdprApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenicate(options?: any): AxiosPromise<void> {
            return GdprApiFp(configuration).authenicate(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GdprRequestForm} [gdprRequestForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitErasureRequest(gdprRequestForm?: GdprRequestForm, options?: any): AxiosPromise<void> {
            return GdprApiFp(configuration).submitErasureRequest(gdprRequestForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GdprRequestForm} [gdprRequestForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitSubjectAccessRequest(gdprRequestForm?: GdprRequestForm, options?: any): AxiosPromise<void> {
            return GdprApiFp(configuration).submitSubjectAccessRequest(gdprRequestForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GdprApi - object-oriented interface
 * @export
 * @class GdprApi
 * @extends {BaseAPI}
 */
export class GdprApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GdprApi
     */
    public authenicate(options?: any) {
        return GdprApiFp(this.configuration).authenicate(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GdprRequestForm} [gdprRequestForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GdprApi
     */
    public submitErasureRequest(gdprRequestForm?: GdprRequestForm, options?: any) {
        return GdprApiFp(this.configuration).submitErasureRequest(gdprRequestForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GdprRequestForm} [gdprRequestForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GdprApi
     */
    public submitSubjectAccessRequest(gdprRequestForm?: GdprRequestForm, options?: any) {
        return GdprApiFp(this.configuration).submitSubjectAccessRequest(gdprRequestForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthCheckApi - axios parameter creator
 * @export
 */
export const HealthCheckApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnvironment: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/HealthCheck/env`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/HealthCheck/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthCheckApi - functional programming interface
 * @export
 */
export const HealthCheckApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnvironment(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await HealthCheckApiAxiosParamCreator(configuration).getEnvironment(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCheck(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await HealthCheckApiAxiosParamCreator(configuration).healthCheck(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * HealthCheckApi - factory interface
 * @export
 */
export const HealthCheckApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnvironment(options?: any): AxiosPromise<void> {
            return HealthCheckApiFp(configuration).getEnvironment(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options?: any): AxiosPromise<void> {
            return HealthCheckApiFp(configuration).healthCheck(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthCheckApi - object-oriented interface
 * @export
 * @class HealthCheckApi
 * @extends {BaseAPI}
 */
export class HealthCheckApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCheckApi
     */
    public getEnvironment(options?: any) {
        return HealthCheckApiFp(this.configuration).getEnvironment(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCheckApi
     */
    public healthCheck(options?: any) {
        return HealthCheckApiFp(this.configuration).healthCheck(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HemnetApi - axios parameter creator
 * @export
 */
export const HemnetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListing: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getListing.');
            }
            const localVarPath = `/hemnet/listing/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HemnetApi - functional programming interface
 * @export
 */
export const HemnetApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getListing(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListingResponse>> {
            const localVarAxiosArgs = await HemnetApiAxiosParamCreator(configuration).getListing(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * HemnetApi - factory interface
 * @export
 */
export const HemnetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListing(id: string, options?: any): AxiosPromise<ListingResponse> {
            return HemnetApiFp(configuration).getListing(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HemnetApi - object-oriented interface
 * @export
 * @class HemnetApi
 * @extends {BaseAPI}
 */
export class HemnetApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HemnetApi
     */
    public getListing(id: string, options?: any) {
        return HemnetApiFp(this.configuration).getListing(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HouseIdApi - axios parameter creator
 * @export
 */
export const HouseIdApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHouseIdListing: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getHouseIdListing.');
            }
            const localVarPath = `/houseid/listing/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMoveHouseIdListing: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getMoveHouseIdListing.');
            }
            const localVarPath = `/houseid/move/listing/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HouseIdApi - functional programming interface
 * @export
 */
export const HouseIdApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHouseIdListing(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HouseIdResponse>> {
            const localVarAxiosArgs = await HouseIdApiAxiosParamCreator(configuration).getHouseIdListing(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMoveHouseIdListing(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HouseIdResponse>> {
            const localVarAxiosArgs = await HouseIdApiAxiosParamCreator(configuration).getMoveHouseIdListing(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * HouseIdApi - factory interface
 * @export
 */
export const HouseIdApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHouseIdListing(id: string, options?: any): AxiosPromise<HouseIdResponse> {
            return HouseIdApiFp(configuration).getHouseIdListing(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMoveHouseIdListing(id: string, options?: any): AxiosPromise<HouseIdResponse> {
            return HouseIdApiFp(configuration).getMoveHouseIdListing(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HouseIdApi - object-oriented interface
 * @export
 * @class HouseIdApi
 * @extends {BaseAPI}
 */
export class HouseIdApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HouseIdApi
     */
    public getHouseIdListing(id: string, options?: any) {
        return HouseIdApiFp(this.configuration).getHouseIdListing(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HouseIdApi
     */
    public getMoveHouseIdListing(id: string, options?: any) {
        return HouseIdApiFp(this.configuration).getMoveHouseIdListing(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LeadApi - axios parameter creator
 * @export
 */
export const LeadApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {OutgoingLeadForm} [outgoingLeadForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outgoingLead: async (outgoingLeadForm?: OutgoingLeadForm, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/lead/outgoing`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof outgoingLeadForm !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(outgoingLeadForm !== undefined ? outgoingLeadForm : {})
                : (outgoingLeadForm || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeadApi - functional programming interface
 * @export
 */
export const LeadApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {OutgoingLeadForm} [outgoingLeadForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outgoingLead(outgoingLeadForm?: OutgoingLeadForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LeadApiAxiosParamCreator(configuration).outgoingLead(outgoingLeadForm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LeadApi - factory interface
 * @export
 */
export const LeadApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {OutgoingLeadForm} [outgoingLeadForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outgoingLead(outgoingLeadForm?: OutgoingLeadForm, options?: any): AxiosPromise<void> {
            return LeadApiFp(configuration).outgoingLead(outgoingLeadForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LeadApi - object-oriented interface
 * @export
 * @class LeadApi
 * @extends {BaseAPI}
 */
export class LeadApi extends BaseAPI {
    /**
     * 
     * @param {OutgoingLeadForm} [outgoingLeadForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadApi
     */
    public outgoingLead(outgoingLeadForm?: OutgoingLeadForm, options?: any) {
        return LeadApiFp(this.configuration).outgoingLead(outgoingLeadForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LoanApi - axios parameter creator
 * @export
 */
export const LoanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankCodes: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/loan/bank-codes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} myPagesOfferPageViewed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loanOfferPageViewed: async (myPagesOfferPageViewed: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'myPagesOfferPageViewed' is not null or undefined
            if (myPagesOfferPageViewed === null || myPagesOfferPageViewed === undefined) {
                throw new RequiredError('myPagesOfferPageViewed','Required parameter myPagesOfferPageViewed was null or undefined when calling loanOfferPageViewed.');
            }
            const localVarPath = `/loan/offer-page-viewed/{myPagesOfferPageViewed}`
                .replace(`{${"myPagesOfferPageViewed"}}`, encodeURIComponent(String(myPagesOfferPageViewed)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SelectOffer} [selectOffer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectLoanOffer: async (id: number, selectOffer?: SelectOffer, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling selectLoanOffer.');
            }
            const localVarPath = `/loan/select-offer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof selectOffer !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(selectOffer !== undefined ? selectOffer : {})
                : (selectOffer || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomerLoanChangeRequest} [customerLoanChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitChangeRequest: async (customerLoanChangeRequest?: CustomerLoanChangeRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/loan/change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof customerLoanChangeRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(customerLoanChangeRequest !== undefined ? customerLoanChangeRequest : {})
                : (customerLoanChangeRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationFormRequest} [applicationFormRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitLoanApplication: async (applicationFormRequest?: ApplicationFormRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/loan`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof applicationFormRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(applicationFormRequest !== undefined ? applicationFormRequest : {})
                : (applicationFormRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoanApi - functional programming interface
 * @export
 */
export const LoanApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBankCodes(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await LoanApiAxiosParamCreator(configuration).getBankCodes(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {boolean} myPagesOfferPageViewed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loanOfferPageViewed(myPagesOfferPageViewed: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LoanApiAxiosParamCreator(configuration).loanOfferPageViewed(myPagesOfferPageViewed, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SelectOffer} [selectOffer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async selectLoanOffer(id: number, selectOffer?: SelectOffer, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LoanApiAxiosParamCreator(configuration).selectLoanOffer(id, selectOffer, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CustomerLoanChangeRequest} [customerLoanChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitChangeRequest(customerLoanChangeRequest?: CustomerLoanChangeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LoanApiAxiosParamCreator(configuration).submitChangeRequest(customerLoanChangeRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ApplicationFormRequest} [applicationFormRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitLoanApplication(applicationFormRequest?: ApplicationFormRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubmitLoanApplicationResult>> {
            const localVarAxiosArgs = await LoanApiAxiosParamCreator(configuration).submitLoanApplication(applicationFormRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LoanApi - factory interface
 * @export
 */
export const LoanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBankCodes(options?: any): AxiosPromise<{ [key: string]: string; }> {
            return LoanApiFp(configuration).getBankCodes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} myPagesOfferPageViewed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loanOfferPageViewed(myPagesOfferPageViewed: boolean, options?: any): AxiosPromise<void> {
            return LoanApiFp(configuration).loanOfferPageViewed(myPagesOfferPageViewed, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SelectOffer} [selectOffer] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectLoanOffer(id: number, selectOffer?: SelectOffer, options?: any): AxiosPromise<void> {
            return LoanApiFp(configuration).selectLoanOffer(id, selectOffer, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerLoanChangeRequest} [customerLoanChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitChangeRequest(customerLoanChangeRequest?: CustomerLoanChangeRequest, options?: any): AxiosPromise<void> {
            return LoanApiFp(configuration).submitChangeRequest(customerLoanChangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationFormRequest} [applicationFormRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitLoanApplication(applicationFormRequest?: ApplicationFormRequest, options?: any): AxiosPromise<SubmitLoanApplicationResult> {
            return LoanApiFp(configuration).submitLoanApplication(applicationFormRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoanApi - object-oriented interface
 * @export
 * @class LoanApi
 * @extends {BaseAPI}
 */
export class LoanApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApi
     */
    public getBankCodes(options?: any) {
        return LoanApiFp(this.configuration).getBankCodes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} myPagesOfferPageViewed 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApi
     */
    public loanOfferPageViewed(myPagesOfferPageViewed: boolean, options?: any) {
        return LoanApiFp(this.configuration).loanOfferPageViewed(myPagesOfferPageViewed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SelectOffer} [selectOffer] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApi
     */
    public selectLoanOffer(id: number, selectOffer?: SelectOffer, options?: any) {
        return LoanApiFp(this.configuration).selectLoanOffer(id, selectOffer, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerLoanChangeRequest} [customerLoanChangeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApi
     */
    public submitChangeRequest(customerLoanChangeRequest?: CustomerLoanChangeRequest, options?: any) {
        return LoanApiFp(this.configuration).submitChangeRequest(customerLoanChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationFormRequest} [applicationFormRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoanApi
     */
    public submitLoanApplication(applicationFormRequest?: ApplicationFormRequest, options?: any) {
        return LoanApiFp(this.configuration).submitLoanApplication(applicationFormRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LogApi - axios parameter creator
 * @export
 */
export const LogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [context] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logBrowserError: async (context?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/log/browser/error`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (context !== undefined) {
                localVarQueryParameter['context'] = context;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogApi - functional programming interface
 * @export
 */
export const LogApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [context] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logBrowserError(context?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LogApiAxiosParamCreator(configuration).logBrowserError(context, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LogApi - factory interface
 * @export
 */
export const LogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} [context] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logBrowserError(context?: string, options?: any): AxiosPromise<void> {
            return LogApiFp(configuration).logBrowserError(context, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LogApi - object-oriented interface
 * @export
 * @class LogApi
 * @extends {BaseAPI}
 */
export class LogApi extends BaseAPI {
    /**
     * 
     * @param {string} [context] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogApi
     */
    public logBrowserError(context?: string, options?: any) {
        return LogApiFp(this.configuration).logBrowserError(context, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MediaApi - axios parameter creator
 * @export
 */
export const MediaApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download: async (id: number, fileName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling download.');
            }
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling download.');
            }
            const localVarPath = `/media/{id}/{fileName}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MediaApi - functional programming interface
 * @export
 */
export const MediaApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async download(id: number, fileName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MediaApiAxiosParamCreator(configuration).download(id, fileName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MediaApi - factory interface
 * @export
 */
export const MediaApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download(id: number, fileName: string, options?: any): AxiosPromise<void> {
            return MediaApiFp(configuration).download(id, fileName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MediaApi - object-oriented interface
 * @export
 * @class MediaApi
 * @extends {BaseAPI}
 */
export class MediaApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {string} fileName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MediaApi
     */
    public download(id: number, fileName: string, options?: any) {
        return MediaApiFp(this.configuration).download(id, fileName, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MessageApi - axios parameter creator
 * @export
 */
export const MessageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countUnread: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/message/count-unread`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppType: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/message/app-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessage: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getMessage.');
            }
            const localVarPath = `/message/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessages: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<MessageView>} [messageView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markListRead: async (messageView?: Array<MessageView>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/message/read-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof messageView !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(messageView !== undefined ? messageView : {})
                : (messageView || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markRead: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling markRead.');
            }
            const localVarPath = `/message/{id}/read`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomerMessageForm} [customerMessageForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitForm: async (customerMessageForm?: CustomerMessageForm, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/message`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof customerMessageForm !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(customerMessageForm !== undefined ? customerMessageForm : {})
                : (customerMessageForm || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessageApi - functional programming interface
 * @export
 */
export const MessageApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countUnread(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await MessageApiAxiosParamCreator(configuration).countUnread(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAppType(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await MessageApiAxiosParamCreator(configuration).getAppType(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessage(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessageView>> {
            const localVarAxiosArgs = await MessageApiAxiosParamCreator(configuration).getMessage(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMessages(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MessageView>>> {
            const localVarAxiosArgs = await MessageApiAxiosParamCreator(configuration).getMessages(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<MessageView>} [messageView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markListRead(messageView?: Array<MessageView>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MessageApiAxiosParamCreator(configuration).markListRead(messageView, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markRead(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MessageApiAxiosParamCreator(configuration).markRead(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CustomerMessageForm} [customerMessageForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitForm(customerMessageForm?: CustomerMessageForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MessageApiAxiosParamCreator(configuration).submitForm(customerMessageForm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MessageApi - factory interface
 * @export
 */
export const MessageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countUnread(options?: any): AxiosPromise<number> {
            return MessageApiFp(configuration).countUnread(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAppType(options?: any): AxiosPromise<string> {
            return MessageApiFp(configuration).getAppType(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessage(id: number, options?: any): AxiosPromise<MessageView> {
            return MessageApiFp(configuration).getMessage(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMessages(options?: any): AxiosPromise<Array<MessageView>> {
            return MessageApiFp(configuration).getMessages(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<MessageView>} [messageView] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markListRead(messageView?: Array<MessageView>, options?: any): AxiosPromise<void> {
            return MessageApiFp(configuration).markListRead(messageView, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markRead(id: number, options?: any): AxiosPromise<void> {
            return MessageApiFp(configuration).markRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerMessageForm} [customerMessageForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitForm(customerMessageForm?: CustomerMessageForm, options?: any): AxiosPromise<void> {
            return MessageApiFp(configuration).submitForm(customerMessageForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MessageApi - object-oriented interface
 * @export
 * @class MessageApi
 * @extends {BaseAPI}
 */
export class MessageApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public countUnread(options?: any) {
        return MessageApiFp(this.configuration).countUnread(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public getAppType(options?: any) {
        return MessageApiFp(this.configuration).getAppType(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public getMessage(id: number, options?: any) {
        return MessageApiFp(this.configuration).getMessage(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public getMessages(options?: any) {
        return MessageApiFp(this.configuration).getMessages(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<MessageView>} [messageView] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public markListRead(messageView?: Array<MessageView>, options?: any) {
        return MessageApiFp(this.configuration).markListRead(messageView, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public markRead(id: number, options?: any) {
        return MessageApiFp(this.configuration).markRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerMessageForm} [customerMessageForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessageApi
     */
    public submitForm(customerMessageForm?: CustomerMessageForm, options?: any) {
        return MessageApiFp(this.configuration).submitForm(customerMessageForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MortgageApi - axios parameter creator
 * @export
 */
export const MortgageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMortgageDocument: async (id: string, file?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling addMortgageDocument.');
            }
            const localVarPath = `/mortgage/document/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CapTimeRequest} [capTimeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        capCallbackTime: async (capTimeRequest?: CapTimeRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mortgage/cap-callback-time`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof capTimeRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(capTimeRequest !== undefined ? capTimeRequest : {})
                : (capTimeRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [offerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApplicationRenewalUrl: async (offerId?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mortgage/renewal-url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (offerId !== undefined) {
                localVarQueryParameter['offerId'] = offerId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentContent: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteDocumentContent.');
            }
            const localVarPath = `/mortgage/{id}/document-content`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} offerDocumentContentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOfferDocumentContent: async (id: string, offerDocumentContentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOfferDocumentContent.');
            }
            // verify required parameter 'offerDocumentContentId' is not null or undefined
            if (offerDocumentContentId === null || offerDocumentContentId === undefined) {
                throw new RequiredError('offerDocumentContentId','Required parameter offerDocumentContentId was null or undefined when calling deleteOfferDocumentContent.');
            }
            const localVarPath = `/mortgage/{id}/{offerDocumentContentId}/offer-document-content`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"offerDocumentContentId"}}`, encodeURIComponent(String(offerDocumentContentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAdvisory: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling downloadAdvisory.');
            }
            const localVarPath = `/mortgage/{id}/download-advisory`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocument: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling downloadDocument.');
            }
            const localVarPath = `/mortgage/{id}/download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} offerDocumentContentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOfferDocumentContent: async (id: string, offerDocumentContentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling downloadOfferDocumentContent.');
            }
            // verify required parameter 'offerDocumentContentId' is not null or undefined
            if (offerDocumentContentId === null || offerDocumentContentId === undefined) {
                throw new RequiredError('offerDocumentContentId','Required parameter offerDocumentContentId was null or undefined when calling downloadOfferDocumentContent.');
            }
            const localVarPath = `/mortgage/{id}/{offerDocumentContentId}/download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"offerDocumentContentId"}}`, encodeURIComponent(String(offerDocumentContentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} loanAmount 
         * @param {number} interestRate 
         * @param {number} loanToValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAmortizationCost: async (loanAmount: number, interestRate: number, loanToValue: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loanAmount' is not null or undefined
            if (loanAmount === null || loanAmount === undefined) {
                throw new RequiredError('loanAmount','Required parameter loanAmount was null or undefined when calling getAmortizationCost.');
            }
            // verify required parameter 'interestRate' is not null or undefined
            if (interestRate === null || interestRate === undefined) {
                throw new RequiredError('interestRate','Required parameter interestRate was null or undefined when calling getAmortizationCost.');
            }
            // verify required parameter 'loanToValue' is not null or undefined
            if (loanToValue === null || loanToValue === undefined) {
                throw new RequiredError('loanToValue','Required parameter loanToValue was null or undefined when calling getAmortizationCost.');
            }
            const localVarPath = `/mortgage/amortization-cost/{loanAmount}/{interestRate}/{loanToValue}`
                .replace(`{${"loanAmount"}}`, encodeURIComponent(String(loanAmount)))
                .replace(`{${"interestRate"}}`, encodeURIComponent(String(interestRate)))
                .replace(`{${"loanToValue"}}`, encodeURIComponent(String(loanToValue)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} applicantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicantInfo: async (applicantId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicantId' is not null or undefined
            if (applicantId === null || applicantId === undefined) {
                throw new RequiredError('applicantId','Required parameter applicantId was null or undefined when calling getApplicantInfo.');
            }
            const localVarPath = `/mortgage/applicant-info/{applicantId}`
                .replace(`{${"applicantId"}}`, encodeURIComponent(String(applicantId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [accessToken] 
         * @param {string} [applicantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationFormView: async (accessToken?: string, applicantId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mortgage/form`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }

            if (accessToken !== undefined) {
                localVarQueryParameter['accessToken'] = accessToken;
            }

            if (applicantId !== undefined) {
                localVarQueryParameter['applicantId'] = applicantId;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InterestRateProposalRequest} [interestRateProposalRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterestRateProposal: async (interestRateProposalRequest?: InterestRateProposalRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mortgage/interest-rate-proposal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof interestRateProposalRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(interestRateProposalRequest !== undefined ? interestRateProposalRequest : {})
                : (interestRateProposalRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionsTerms: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mortgage/questions-terms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequiredFields: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mortgage/required`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalActiveApplications: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mortgage/total-active-applications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnReadAdvisoryCount: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mortgage/unread-advisory-count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUtm: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mortgage/utm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} myPagesOfferPageViewed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mortgageOfferPageViewed: async (myPagesOfferPageViewed: boolean, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'myPagesOfferPageViewed' is not null or undefined
            if (myPagesOfferPageViewed === null || myPagesOfferPageViewed === undefined) {
                throw new RequiredError('myPagesOfferPageViewed','Required parameter myPagesOfferPageViewed was null or undefined when calling mortgageOfferPageViewed.');
            }
            const localVarPath = `/mortgage/offer-page-viewed/{myPagesOfferPageViewed}`
                .replace(`{${"myPagesOfferPageViewed"}}`, encodeURIComponent(String(myPagesOfferPageViewed)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectMortgageOffer: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling selectMortgageOffer.');
            }
            const localVarPath = `/mortgage/select-offer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CallbackRequest} [callbackRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCallbackFromCustomer: async (callbackRequest?: CallbackRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mortgage/callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof callbackRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(callbackRequest !== undefined ? callbackRequest : {})
                : (callbackRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitAlert: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mortgage/alert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApplicationAdditionsForm} [applicationAdditionsForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitApplicationAdditions: async (applicationAdditionsForm?: ApplicationAdditionsForm, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mortgage/additions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof applicationAdditionsForm !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(applicationAdditionsForm !== undefined ? applicationAdditionsForm : {})
                : (applicationAdditionsForm || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomerLoanChangeRequest} [customerLoanChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitChangeRequestMortgage: async (customerLoanChangeRequest?: CustomerLoanChangeRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mortgage/change`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof customerLoanChangeRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(customerLoanChangeRequest !== undefined ? customerLoanChangeRequest : {})
                : (customerLoanChangeRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitCustomerFeedback: async (body?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mortgage/customer-feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof body !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MortgageApplicationRenewalForm} [mortgageApplicationRenewalForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitMortgageApplicationRenewal: async (mortgageApplicationRenewalForm?: MortgageApplicationRenewalForm, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mortgage/renewal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof mortgageApplicationRenewalForm !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(mortgageApplicationRenewalForm !== undefined ? mortgageApplicationRenewalForm : {})
                : (mortgageApplicationRenewalForm || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CustomerNewPropertyRequest} [customerNewPropertyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitNewPropertyObject: async (customerNewPropertyRequest?: CustomerNewPropertyRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mortgage/new-property-object`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof customerNewPropertyRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(customerNewPropertyRequest !== undefined ? customerNewPropertyRequest : {})
                : (customerNewPropertyRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RecommendationRequest} [recommendationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitRecommendationForm: async (recommendationRequest?: RecommendationRequest, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mortgage/recommendation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof recommendationRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(recommendationRequest !== undefined ? recommendationRequest : {})
                : (recommendationRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MortgageQuestionsForm} [mortgageQuestionsForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerAnswers: async (mortgageQuestionsForm?: MortgageQuestionsForm, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mortgage/customer-answers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof mortgageQuestionsForm !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(mortgageQuestionsForm !== undefined ? mortgageQuestionsForm : {})
                : (mortgageQuestionsForm || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MortgageApi - functional programming interface
 * @export
 */
export const MortgageApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addMortgageDocument(id: string, file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MortgageApiAxiosParamCreator(configuration).addMortgageDocument(id, file, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CapTimeRequest} [capTimeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async capCallbackTime(capTimeRequest?: CapTimeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await MortgageApiAxiosParamCreator(configuration).capCallbackTime(capTimeRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} [offerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createApplicationRenewalUrl(offerId?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await MortgageApiAxiosParamCreator(configuration).createApplicationRenewalUrl(offerId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDocumentContent(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MortgageApiAxiosParamCreator(configuration).deleteDocumentContent(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} offerDocumentContentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOfferDocumentContent(id: string, offerDocumentContentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MortgageApiAxiosParamCreator(configuration).deleteOfferDocumentContent(id, offerDocumentContentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadAdvisory(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MortgageApiAxiosParamCreator(configuration).downloadAdvisory(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadDocument(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MortgageApiAxiosParamCreator(configuration).downloadDocument(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} offerDocumentContentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadOfferDocumentContent(id: string, offerDocumentContentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MortgageApiAxiosParamCreator(configuration).downloadOfferDocumentContent(id, offerDocumentContentId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} loanAmount 
         * @param {number} interestRate 
         * @param {number} loanToValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAmortizationCost(loanAmount: number, interestRate: number, loanToValue: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalculatedMortgage>> {
            const localVarAxiosArgs = await MortgageApiAxiosParamCreator(configuration).getAmortizationCost(loanAmount, interestRate, loanToValue, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} applicantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicantInfo(applicantId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MortgageApplicantInfoView>> {
            const localVarAxiosArgs = await MortgageApiAxiosParamCreator(configuration).getApplicantInfo(applicantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [accessToken] 
         * @param {string} [applicantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getApplicationFormView(accessToken?: string, applicantId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MortgageApiAxiosParamCreator(configuration).getApplicationFormView(accessToken, applicantId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {InterestRateProposalRequest} [interestRateProposalRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInterestRateProposal(interestRateProposalRequest?: InterestRateProposalRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InterestRateProposalReply>>> {
            const localVarAxiosArgs = await MortgageApiAxiosParamCreator(configuration).getInterestRateProposal(interestRateProposalRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuestionsTerms(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MortgageQuestionsView>> {
            const localVarAxiosArgs = await MortgageApiAxiosParamCreator(configuration).getQuestionsTerms(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRequiredFields(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MyPagesRequiredFields>> {
            const localVarAxiosArgs = await MortgageApiAxiosParamCreator(configuration).getRequiredFields(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotalActiveApplications(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await MortgageApiAxiosParamCreator(configuration).getTotalActiveApplications(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnReadAdvisoryCount(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await MortgageApiAxiosParamCreator(configuration).getUnReadAdvisoryCount(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUtm(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UtmParams>>> {
            const localVarAxiosArgs = await MortgageApiAxiosParamCreator(configuration).getUtm(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {boolean} myPagesOfferPageViewed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mortgageOfferPageViewed(myPagesOfferPageViewed: boolean, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MortgageApiAxiosParamCreator(configuration).mortgageOfferPageViewed(myPagesOfferPageViewed, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async selectMortgageOffer(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MortgageApiAxiosParamCreator(configuration).selectMortgageOffer(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CallbackRequest} [callbackRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setCallbackFromCustomer(callbackRequest?: CallbackRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MortgageApiAxiosParamCreator(configuration).setCallbackFromCustomer(callbackRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitAlert(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MortgageApiAxiosParamCreator(configuration).submitAlert(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ApplicationAdditionsForm} [applicationAdditionsForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitApplicationAdditions(applicationAdditionsForm?: ApplicationAdditionsForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MortgageApiAxiosParamCreator(configuration).submitApplicationAdditions(applicationAdditionsForm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CustomerLoanChangeRequest} [customerLoanChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitChangeRequestMortgage(customerLoanChangeRequest?: CustomerLoanChangeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MortgageApiAxiosParamCreator(configuration).submitChangeRequestMortgage(customerLoanChangeRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitCustomerFeedback(body?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MortgageApiAxiosParamCreator(configuration).submitCustomerFeedback(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {MortgageApplicationRenewalForm} [mortgageApplicationRenewalForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitMortgageApplicationRenewal(mortgageApplicationRenewalForm?: MortgageApplicationRenewalForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MortgageSubmitResult>> {
            const localVarAxiosArgs = await MortgageApiAxiosParamCreator(configuration).submitMortgageApplicationRenewal(mortgageApplicationRenewalForm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {CustomerNewPropertyRequest} [customerNewPropertyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitNewPropertyObject(customerNewPropertyRequest?: CustomerNewPropertyRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MortgageApiAxiosParamCreator(configuration).submitNewPropertyObject(customerNewPropertyRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {RecommendationRequest} [recommendationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submitRecommendationForm(recommendationRequest?: RecommendationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MortgageApiAxiosParamCreator(configuration).submitRecommendationForm(recommendationRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {MortgageQuestionsForm} [mortgageQuestionsForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCustomerAnswers(mortgageQuestionsForm?: MortgageQuestionsForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await MortgageApiAxiosParamCreator(configuration).updateCustomerAnswers(mortgageQuestionsForm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MortgageApi - factory interface
 * @export
 */
export const MortgageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addMortgageDocument(id: string, file?: any, options?: any): AxiosPromise<void> {
            return MortgageApiFp(configuration).addMortgageDocument(id, file, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CapTimeRequest} [capTimeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        capCallbackTime(capTimeRequest?: CapTimeRequest, options?: any): AxiosPromise<Array<number>> {
            return MortgageApiFp(configuration).capCallbackTime(capTimeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [offerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createApplicationRenewalUrl(offerId?: number, options?: any): AxiosPromise<string> {
            return MortgageApiFp(configuration).createApplicationRenewalUrl(offerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentContent(id: string, options?: any): AxiosPromise<void> {
            return MortgageApiFp(configuration).deleteDocumentContent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} offerDocumentContentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOfferDocumentContent(id: string, offerDocumentContentId: string, options?: any): AxiosPromise<void> {
            return MortgageApiFp(configuration).deleteOfferDocumentContent(id, offerDocumentContentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAdvisory(id: string, options?: any): AxiosPromise<void> {
            return MortgageApiFp(configuration).downloadAdvisory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocument(id: string, options?: any): AxiosPromise<void> {
            return MortgageApiFp(configuration).downloadDocument(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} offerDocumentContentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOfferDocumentContent(id: string, offerDocumentContentId: string, options?: any): AxiosPromise<void> {
            return MortgageApiFp(configuration).downloadOfferDocumentContent(id, offerDocumentContentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} loanAmount 
         * @param {number} interestRate 
         * @param {number} loanToValue 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAmortizationCost(loanAmount: number, interestRate: number, loanToValue: number, options?: any): AxiosPromise<CalculatedMortgage> {
            return MortgageApiFp(configuration).getAmortizationCost(loanAmount, interestRate, loanToValue, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} applicantId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicantInfo(applicantId: string, options?: any): AxiosPromise<MortgageApplicantInfoView> {
            return MortgageApiFp(configuration).getApplicantInfo(applicantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [accessToken] 
         * @param {string} [applicantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getApplicationFormView(accessToken?: string, applicantId?: string, options?: any): AxiosPromise<void> {
            return MortgageApiFp(configuration).getApplicationFormView(accessToken, applicantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InterestRateProposalRequest} [interestRateProposalRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInterestRateProposal(interestRateProposalRequest?: InterestRateProposalRequest, options?: any): AxiosPromise<Array<InterestRateProposalReply>> {
            return MortgageApiFp(configuration).getInterestRateProposal(interestRateProposalRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuestionsTerms(options?: any): AxiosPromise<MortgageQuestionsView> {
            return MortgageApiFp(configuration).getQuestionsTerms(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRequiredFields(options?: any): AxiosPromise<MyPagesRequiredFields> {
            return MortgageApiFp(configuration).getRequiredFields(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotalActiveApplications(options?: any): AxiosPromise<number> {
            return MortgageApiFp(configuration).getTotalActiveApplications(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnReadAdvisoryCount(options?: any): AxiosPromise<number> {
            return MortgageApiFp(configuration).getUnReadAdvisoryCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUtm(options?: any): AxiosPromise<Array<UtmParams>> {
            return MortgageApiFp(configuration).getUtm(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} myPagesOfferPageViewed 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mortgageOfferPageViewed(myPagesOfferPageViewed: boolean, options?: any): AxiosPromise<void> {
            return MortgageApiFp(configuration).mortgageOfferPageViewed(myPagesOfferPageViewed, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectMortgageOffer(id: number, options?: any): AxiosPromise<void> {
            return MortgageApiFp(configuration).selectMortgageOffer(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CallbackRequest} [callbackRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCallbackFromCustomer(callbackRequest?: CallbackRequest, options?: any): AxiosPromise<void> {
            return MortgageApiFp(configuration).setCallbackFromCustomer(callbackRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitAlert(options?: any): AxiosPromise<void> {
            return MortgageApiFp(configuration).submitAlert(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ApplicationAdditionsForm} [applicationAdditionsForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitApplicationAdditions(applicationAdditionsForm?: ApplicationAdditionsForm, options?: any): AxiosPromise<void> {
            return MortgageApiFp(configuration).submitApplicationAdditions(applicationAdditionsForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerLoanChangeRequest} [customerLoanChangeRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitChangeRequestMortgage(customerLoanChangeRequest?: CustomerLoanChangeRequest, options?: any): AxiosPromise<void> {
            return MortgageApiFp(configuration).submitChangeRequestMortgage(customerLoanChangeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitCustomerFeedback(body?: string, options?: any): AxiosPromise<void> {
            return MortgageApiFp(configuration).submitCustomerFeedback(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MortgageApplicationRenewalForm} [mortgageApplicationRenewalForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitMortgageApplicationRenewal(mortgageApplicationRenewalForm?: MortgageApplicationRenewalForm, options?: any): AxiosPromise<MortgageSubmitResult> {
            return MortgageApiFp(configuration).submitMortgageApplicationRenewal(mortgageApplicationRenewalForm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CustomerNewPropertyRequest} [customerNewPropertyRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitNewPropertyObject(customerNewPropertyRequest?: CustomerNewPropertyRequest, options?: any): AxiosPromise<void> {
            return MortgageApiFp(configuration).submitNewPropertyObject(customerNewPropertyRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RecommendationRequest} [recommendationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitRecommendationForm(recommendationRequest?: RecommendationRequest, options?: any): AxiosPromise<void> {
            return MortgageApiFp(configuration).submitRecommendationForm(recommendationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MortgageQuestionsForm} [mortgageQuestionsForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerAnswers(mortgageQuestionsForm?: MortgageQuestionsForm, options?: any): AxiosPromise<void> {
            return MortgageApiFp(configuration).updateCustomerAnswers(mortgageQuestionsForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MortgageApi - object-oriented interface
 * @export
 * @class MortgageApi
 * @extends {BaseAPI}
 */
export class MortgageApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageApi
     */
    public addMortgageDocument(id: string, file?: any, options?: any) {
        return MortgageApiFp(this.configuration).addMortgageDocument(id, file, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CapTimeRequest} [capTimeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageApi
     */
    public capCallbackTime(capTimeRequest?: CapTimeRequest, options?: any) {
        return MortgageApiFp(this.configuration).capCallbackTime(capTimeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [offerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageApi
     */
    public createApplicationRenewalUrl(offerId?: number, options?: any) {
        return MortgageApiFp(this.configuration).createApplicationRenewalUrl(offerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageApi
     */
    public deleteDocumentContent(id: string, options?: any) {
        return MortgageApiFp(this.configuration).deleteDocumentContent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} offerDocumentContentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageApi
     */
    public deleteOfferDocumentContent(id: string, offerDocumentContentId: string, options?: any) {
        return MortgageApiFp(this.configuration).deleteOfferDocumentContent(id, offerDocumentContentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageApi
     */
    public downloadAdvisory(id: string, options?: any) {
        return MortgageApiFp(this.configuration).downloadAdvisory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageApi
     */
    public downloadDocument(id: string, options?: any) {
        return MortgageApiFp(this.configuration).downloadDocument(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} offerDocumentContentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageApi
     */
    public downloadOfferDocumentContent(id: string, offerDocumentContentId: string, options?: any) {
        return MortgageApiFp(this.configuration).downloadOfferDocumentContent(id, offerDocumentContentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} loanAmount 
     * @param {number} interestRate 
     * @param {number} loanToValue 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageApi
     */
    public getAmortizationCost(loanAmount: number, interestRate: number, loanToValue: number, options?: any) {
        return MortgageApiFp(this.configuration).getAmortizationCost(loanAmount, interestRate, loanToValue, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} applicantId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageApi
     */
    public getApplicantInfo(applicantId: string, options?: any) {
        return MortgageApiFp(this.configuration).getApplicantInfo(applicantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [accessToken] 
     * @param {string} [applicantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageApi
     */
    public getApplicationFormView(accessToken?: string, applicantId?: string, options?: any) {
        return MortgageApiFp(this.configuration).getApplicationFormView(accessToken, applicantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InterestRateProposalRequest} [interestRateProposalRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageApi
     */
    public getInterestRateProposal(interestRateProposalRequest?: InterestRateProposalRequest, options?: any) {
        return MortgageApiFp(this.configuration).getInterestRateProposal(interestRateProposalRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageApi
     */
    public getQuestionsTerms(options?: any) {
        return MortgageApiFp(this.configuration).getQuestionsTerms(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageApi
     */
    public getRequiredFields(options?: any) {
        return MortgageApiFp(this.configuration).getRequiredFields(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageApi
     */
    public getTotalActiveApplications(options?: any) {
        return MortgageApiFp(this.configuration).getTotalActiveApplications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageApi
     */
    public getUnReadAdvisoryCount(options?: any) {
        return MortgageApiFp(this.configuration).getUnReadAdvisoryCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageApi
     */
    public getUtm(options?: any) {
        return MortgageApiFp(this.configuration).getUtm(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} myPagesOfferPageViewed 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageApi
     */
    public mortgageOfferPageViewed(myPagesOfferPageViewed: boolean, options?: any) {
        return MortgageApiFp(this.configuration).mortgageOfferPageViewed(myPagesOfferPageViewed, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageApi
     */
    public selectMortgageOffer(id: number, options?: any) {
        return MortgageApiFp(this.configuration).selectMortgageOffer(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CallbackRequest} [callbackRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageApi
     */
    public setCallbackFromCustomer(callbackRequest?: CallbackRequest, options?: any) {
        return MortgageApiFp(this.configuration).setCallbackFromCustomer(callbackRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageApi
     */
    public submitAlert(options?: any) {
        return MortgageApiFp(this.configuration).submitAlert(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ApplicationAdditionsForm} [applicationAdditionsForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageApi
     */
    public submitApplicationAdditions(applicationAdditionsForm?: ApplicationAdditionsForm, options?: any) {
        return MortgageApiFp(this.configuration).submitApplicationAdditions(applicationAdditionsForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerLoanChangeRequest} [customerLoanChangeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageApi
     */
    public submitChangeRequestMortgage(customerLoanChangeRequest?: CustomerLoanChangeRequest, options?: any) {
        return MortgageApiFp(this.configuration).submitChangeRequestMortgage(customerLoanChangeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageApi
     */
    public submitCustomerFeedback(body?: string, options?: any) {
        return MortgageApiFp(this.configuration).submitCustomerFeedback(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MortgageApplicationRenewalForm} [mortgageApplicationRenewalForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageApi
     */
    public submitMortgageApplicationRenewal(mortgageApplicationRenewalForm?: MortgageApplicationRenewalForm, options?: any) {
        return MortgageApiFp(this.configuration).submitMortgageApplicationRenewal(mortgageApplicationRenewalForm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CustomerNewPropertyRequest} [customerNewPropertyRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageApi
     */
    public submitNewPropertyObject(customerNewPropertyRequest?: CustomerNewPropertyRequest, options?: any) {
        return MortgageApiFp(this.configuration).submitNewPropertyObject(customerNewPropertyRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RecommendationRequest} [recommendationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageApi
     */
    public submitRecommendationForm(recommendationRequest?: RecommendationRequest, options?: any) {
        return MortgageApiFp(this.configuration).submitRecommendationForm(recommendationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MortgageQuestionsForm} [mortgageQuestionsForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageApi
     */
    public updateCustomerAnswers(mortgageQuestionsForm?: MortgageQuestionsForm, options?: any) {
        return MortgageApiFp(this.configuration).updateCustomerAnswers(mortgageQuestionsForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MortgageCalcApi - axios parameter creator
 * @export
 */
export const MortgageCalcApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MortgagePartialApplicationForm} [mortgagePartialApplicationForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaximumLoanAmount: async (mortgagePartialApplicationForm?: MortgagePartialApplicationForm, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/mortgage/calc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof mortgagePartialApplicationForm !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(mortgagePartialApplicationForm !== undefined ? mortgagePartialApplicationForm : {})
                : (mortgagePartialApplicationForm || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MortgageCalcApi - functional programming interface
 * @export
 */
export const MortgageCalcApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {MortgagePartialApplicationForm} [mortgagePartialApplicationForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMaximumLoanAmount(mortgagePartialApplicationForm?: MortgagePartialApplicationForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaxLoanAmountDetailsView>> {
            const localVarAxiosArgs = await MortgageCalcApiAxiosParamCreator(configuration).getMaximumLoanAmount(mortgagePartialApplicationForm, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * MortgageCalcApi - factory interface
 * @export
 */
export const MortgageCalcApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {MortgagePartialApplicationForm} [mortgagePartialApplicationForm] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMaximumLoanAmount(mortgagePartialApplicationForm?: MortgagePartialApplicationForm, options?: any): AxiosPromise<MaxLoanAmountDetailsView> {
            return MortgageCalcApiFp(configuration).getMaximumLoanAmount(mortgagePartialApplicationForm, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MortgageCalcApi - object-oriented interface
 * @export
 * @class MortgageCalcApi
 * @extends {BaseAPI}
 */
export class MortgageCalcApi extends BaseAPI {
    /**
     * 
     * @param {MortgagePartialApplicationForm} [mortgagePartialApplicationForm] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MortgageCalcApi
     */
    public getMaximumLoanAmount(mortgagePartialApplicationForm?: MortgagePartialApplicationForm, options?: any) {
        return MortgageCalcApiFp(this.configuration).getMaximumLoanAmount(mortgagePartialApplicationForm, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PostApi - axios parameter creator
 * @export
 */
export const PostApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling get.');
            }
            const localVarPath = `/post/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPosts: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/post`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PostApi - functional programming interface
 * @export
 */
export const PostApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostView>> {
            const localVarAxiosArgs = await PostApiAxiosParamCreator(configuration).get(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPosts(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PostView>>> {
            const localVarAxiosArgs = await PostApiAxiosParamCreator(configuration).getPosts(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * PostApi - factory interface
 * @export
 */
export const PostApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: number, options?: any): AxiosPromise<PostView> {
            return PostApiFp(configuration).get(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPosts(options?: any): AxiosPromise<Array<PostView>> {
            return PostApiFp(configuration).getPosts(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PostApi - object-oriented interface
 * @export
 * @class PostApi
 * @extends {BaseAPI}
 */
export class PostApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    public get(id: number, options?: any) {
        return PostApiFp(this.configuration).get(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    public getPosts(options?: any) {
        return PostApiFp(this.configuration).getPosts(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TrackingApi - axios parameter creator
 * @export
 */
export const TrackingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTracking: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/tracking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeTracking: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/tracking`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication basic required
            // http basic authentication required
            if (configuration && (configuration.username || configuration.password)) {
                localVarRequestOptions["auth"] = { username: configuration.username, password: configuration.password };
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrackingApi - functional programming interface
 * @export
 */
export const TrackingApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTracking(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await TrackingApiAxiosParamCreator(configuration).getTracking(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async storeTracking(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await TrackingApiAxiosParamCreator(configuration).storeTracking(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * TrackingApi - factory interface
 * @export
 */
export const TrackingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTracking(options?: any): AxiosPromise<{ [key: string]: string; }> {
            return TrackingApiFp(configuration).getTracking(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        storeTracking(options?: any): AxiosPromise<void> {
            return TrackingApiFp(configuration).storeTracking(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TrackingApi - object-oriented interface
 * @export
 * @class TrackingApi
 * @extends {BaseAPI}
 */
export class TrackingApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackingApi
     */
    public getTracking(options?: any) {
        return TrackingApiFp(this.configuration).getTracking(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackingApi
     */
    public storeTracking(options?: any) {
        return TrackingApiFp(this.configuration).storeTracking(options).then((request) => request(this.axios, this.basePath));
    }
}


