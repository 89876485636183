
import { defineComponent } from "vue";
import { api, MortgageApplicationView } from "src/api";
import OfferDocumentsIndexView from "./OfferDocumentsIndexView.vue";
import _ from "lodash";

export default defineComponent({
  data() {
    return {
      app: {} as MortgageApplicationView,
      offer: {} as any,
      isLoaded: false
    };
  },

  components: {
    OfferDocumentsIndexView
  },

  methods: {
    load: async function () {
      const activeApps = (await api.Application.getActiveApplications()).data;
      this.isLoaded = true;
      if (activeApps.mortgageApplication) {
        this.app = activeApps.mortgageApplication;
        this.offer = this.chosenOffer(activeApps.mortgageApplication);
      }
    },
    chosenOffer(app: MortgageApplicationView) {
      let offer = {};
      if (app.chosenOfferId) {
        app.offers?.forEach(function (item) {
          if (item.id === app.chosenOfferId) {
            offer = item;
          }
        });
        return offer ?? null;
      }
    }
  },
  beforeMount: async function () {
    this.load();
  }
});
