export * from "./gen/api";
export * from "./gen/configuration";
import axios from "axios";
import {
  AuthApi,
  MessageApi,
  LoanApi,
  MediaApi,
  MortgageApi,
  PostApi,
  ApplicationApi,
  LeadApi
} from "./gen/api";

const http = axios.create({
  withCredentials: true,
  headers: {
    common: {
      "X-Requested-By": "direkto-api-client"
    }
  }
});

const baseUrl =
  window.location.hostname === "localhost" ? "http://localhost:5020" : "/api";

export const api = {
  Auth: new AuthApi(undefined, baseUrl, http),
  Message: new MessageApi(undefined, baseUrl, http),
  Loan: new LoanApi(undefined, baseUrl, http),
  Media: new MediaApi(undefined, baseUrl, http),
  Mortgage: new MortgageApi(undefined, baseUrl, http),
  Post: new PostApi(undefined, baseUrl, http),
  Application: new ApplicationApi(undefined, baseUrl, http),
  Lead: new LeadApi(undefined, baseUrl, http),
};
