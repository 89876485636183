import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoanSelectOfferForm = _resolveComponent("LoanSelectOfferForm")!

  return (_openBlock(), _createBlock(_component_LoanSelectOfferForm, {
    offer: _ctx.offer,
    loanStatus: _ctx.loanStatus,
    isLoading: _ctx.isLoading,
    offerSelectingError: _ctx.offerSelectingError,
    onSubmit: _ctx.submit
  }, null, 8, ["offer", "loanStatus", "isLoading", "offerSelectingError", "onSubmit"]))
}