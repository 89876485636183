
import { defineComponent, PropType } from "vue";
import { MessageView, api } from "src/api";

export default defineComponent({
  name: "Messages",
  props: {
    loaded: Boolean,
    messages: Array as PropType<MessageView[]>
  },
  methods: {
    formatDate: function (date: string) {
      const formatter = new Intl.DateTimeFormat("sv-SE", {
        hour: "numeric",
        minute: "numeric",
        month: "short",
        day: "numeric"
      });
      return formatter.format(new Date(date));
    },

    formatMessage(val: string) {
      const regexStr = val.replace(/(?:\r|\n).*$/, "");
      return regexStr;
    },
    markAsRead: async function (id: number) {
      await api.Message.markRead(id);
    },
     markAsReadList: async function (messages: MessageView[]) {
      await api.Message.markListRead(messages);
    },
    geLoanType(value: string) {
      if (value === "Loan") {
        return "Privatlån";
      } else if (value === "Insurance") {
        return "Försäkring";
      } else if (value === "Mortgage") {
        return "Bolån";
      } else {
        return "";
      }
    }
  }
});
