
import { defineComponent } from "vue";
export default defineComponent({
  props: ["showFoundProperty", "active", "showMortgageCalculator"],
  computed: {
    getMenu(): any {
      let menu = [
        {
          name: "Startsida",
          path: "/mortgage",
          show: true,
          isActive: this.active == "Startsida"
        },
        {
          name: "Låneförslag",
          path: "/mortgage/offers",
          show: true,
          isActive: this.active == "Låneförslag"
        },
        {
          name: "Dokument",
          path: "/mortgage/offer-documents",
          show: true,
          isActive: this.active == "Dokument"
        },
      ];

      if (this.showFoundProperty) {
        menu.push({
          name: "Hittat bostad?",
          path: "/mortgage/new-property-object",
          show: this.showFoundProperty,
          isActive: this.active == "Hittat bostad?"
        });
      }

      if(this.showMortgageCalculator) {
        menu.push({
          name: "Räkna på bolån",
          path: "/mortgage/calculation",
          show: true,
          isActive: this.active == "Räkna på bolån"
        });
      }

      return menu;
    }
  },
  methods: {
    gotoPage(path: string) {
      this.$router.push(path);
    }
  }
});
