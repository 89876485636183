
import { defineComponent } from "vue";

export default defineComponent({
  methods: {
    goBack: function () {
      window.history.back();
    }
  }
});
