<template>
  <div class="content-section">
    <div class="card card-feat">
      <div class="card-body">
        <div v-if="signing">
          <h1 class="h2 text-center">BankID signering</h1>
          <p class="text-center py-2">
            Tack för din låneansökan. Vänligen verifiera din identitet här med
            hjälp av BankID.
          </p>
        </div>
        <div v-else>
          <h1 class="h2 text-center">Logga in på mina sidor</h1>
        </div>

        <BankIdV6Login :signing="signing" :applicantId="applicantId" />

        <div
          class="text-center border-top border-light pt-3"
          v-if="isTokenLogin"
        >
          <div class="alert alert-info d-inline-block">
            Logging in with token...
          </div>
          <br />
          <div class="alert alert-danger d-inline-block" v-if="invalidToken">
            Invalid token
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import BankIdLogin from "@/components/BankId/BankIdLogin";
import BankIdV6Login from "@/components/BankId/BankIdV6Login";
import { api, MyPagesAppType } from "src/api";
import { userStore } from "src/store/UserStore";

export default defineComponent({
  props: {
    token: {
      type: String
    },
    applicantId: {
      type: String
    },
    signing: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      invalidToken: false
    };
  },
  computed: {
    isTokenLogin: function () {
      return !!this.token;
    }
  },
  components: { BankIdLogin, BankIdV6Login },
  beforeMount: function () {
    if (this.token) this.tokenLogin();
  },
  methods: {
    tokenLogin: async function () {
      try {
        const response = await api.Auth.loginToken(this.token);

        if (response.data.name) userStore.loginUser(response.data.name);

        if (response.data.appType === MyPagesAppType.Mortgage) {
          this.$router.push({ name: "mortgage_index" });
        }

        if (response.data.appType === MyPagesAppType.Loan) {
          this.$router.push({ name: "loan_index" });
        }
      } catch (e) {
        this.invalidToken = true;
      }
    }
  }
});
</script>
