import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = {
  for: "textarea",
  class: "form-label"
}
const _hoisted_3 = ["rows", "value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      _renderSlot(_ctx.$slots, "label", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.label), 1)
      ])
    ]),
    _createElementVNode("textarea", {
      class: "form-control",
      id: "textarea",
      rows: _ctx.rows,
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
    }, null, 40, _hoisted_3)
  ]))
}