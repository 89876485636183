
import { defineComponent } from "vue";
import { MortgageFlowStatus, MortgageFlowEvent } from "src/api";
export default defineComponent({
  props: ["app"],
  data() {
    return {
      expanded: false,
      housing: [
        { type: "House", name: "Villa" },
        { type: "Condominium", name: "Bostadsrätt" },
        { type: "Cottage", name: "Fritidshus" },
    ],
    };
  },
  methods: {
    formatMoney(value: number) {
      if (value != null) return Math.round(value).toLocaleString("sv");
      return "";
    },
    formatDecimal: function (value: number) {
      return (Math.round(value * 100) / 100).toFixed(2).replace(".", ",");
    },
    getStatus(): string {
      if(this.app.mortgageFlowStatus.length == 0) return ""
      var currentStep = this.app.mortgageFlowStatus.find(
        (step: MortgageFlowStatus) => step.status == "Current"
      );
      var currentEvent = currentStep.events.find((event : MortgageFlowEvent) => event.show == true);
      return currentEvent.title;
    },
    mapHousing(housingType : string ) : string {
      var currentHousing = this.housing.find(x => x.type == housingType);
      return currentHousing == null ? "" : currentHousing.name
    }
  }
});
