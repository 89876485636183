
import { defineComponent } from "vue";
import { api, MessageView } from "src/api";

export default defineComponent({
  props: {
    id: Number
  },
  data: function () {
    return {
      message: {} as MessageView
    };
  },
  components: {},
  methods: {
    formatDate: function (date: string) {
      if (!date) return "";
      const formatter = new Intl.DateTimeFormat("sv-SE", {
        hour: "numeric",
        minute: "numeric",
        month: "short",
        day: "numeric"
      });
      return formatter.format(new Date(date));
    },

    loanType(value: string) {
      if (value === "Loan") {
        return "Privatlån";
      } else if (value === "Insurance") {
        return "Försäkring";
      } else if (value === "Mortgage") {
        return "Bolån";
      } else {
        return "";
      }
    }
  },
  mounted: async function () {
    if (this.$props.id === undefined) return;
    this.message = (await api.Message.getMessage(this.$props.id)).data;

    if (!this.message.isRead && !this.message.fromCustomer && this.$props.id) {
      await api.Message.markRead(this.$props.id);
      this.$emit("messageRead");
    }
  }
});
