
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    modelValue: String,
    label: String,
    numbersOnly: { type: Boolean, default: false },
    placeholder: String,
    valid: { type: Boolean, default: false },
    error: String
  },
  data() {
    return {
      inputValue: this.modelValue,
      isEmpty: false
    };
  },
  methods: {
    onInput(event: { target: HTMLInputElement }) {
      this.inputValue = event.target.value;
      this.checkIfEmpty();
      this.$emit("update:modelValue", event.target.value);
    },
    checkIfEmpty: function () {
      this.isEmpty = this.inputValue === "undefined" || this.inputValue === "";
    },
    OnlyNumbers(evt: KeyboardEvent) {
      if (this.numbersOnly) {
        const keysAllowed: string[] = [
          "0",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          ".",
          "Backspace"
        ];
        const keyPressed: string = evt.key;
        if (!keysAllowed.includes(keyPressed)) {
          evt.preventDefault();
        }
      }
    }
  },
  watch: {
    modelValue: {
      handler(value: string) {
        if (value === "" || value === null) this.inputValue = "";
        else this.inputValue = value;
      },
      deep: true,
      immediate: true
    }
  }
});
