
import { defineComponent } from "vue";
import TextField from "../Controls/TextInput.vue";
import {
  MortgageFlowStatus,
  MortgageFlowEvent,
  MortgageOfferView,
  api
} from "src/api";
export default defineComponent({
  props: ["app", "step", "event", "flowStatus"],
  components: {
    TextField
  },
  data() {
    return {
      appEvent: null,
      seletedBank: null,
      recommendationSent: false,
      recommendationForm: { name: "", phoneNumber: "" },
      bankLink: [
        {
          bankName: "Skandia",
          link: "https://login.skandia.se/?client_id=i_web_individual_short"
        },
        {
          bankName: "SBAB",
          link: "https://secure.sbab.se/logga-in?rev=1636555126303&v=latest"
        },
        {
          bankName: "Goodbank",
          link: "https://www.ordnabolan.se/"
        }
      ]
    };
  },
  computed: {
    currentEvent: function () {
      return this.event || this.appEvent;
    },
    mortgageFlowStatus: function () {
      return this.flowStatus || this.app.mortgageFlowStatus;
    },
    Disabled() {
      return (
        this.recommendationForm.name === "" ||
        this.recommendationForm.phoneNumber == ""
      );
    }
  },
  methods: {
    setcurrentEvent() {
      if (this.mortgageFlowStatus.length == 0) return;
      let currentStep = this.mortgageFlowStatus.find(
        (step: MortgageFlowStatus) => step.status == "Current"
      );
      let currentEvent = currentStep.events.find(
        (event: MortgageFlowEvent) => event.show == true
      );
      this.appEvent = currentEvent;
    },
    goToPage(pageToGo: string) {
      // TODO proper router links, without page=='text'...
      let path = "";
      if (pageToGo === "Dokument") path = "/mortgage/offer-documents";
      if (pageToGo === "Hitta bostad") path = "/mortgage/new-property-object";
      if (pageToGo === "Meddelande") path = "/message";
      if (pageToGo === "Se låneförslag") path = "/mortgage/offers";
      if (pageToGo === "Förnya lånelöfte") {
        // go to renewal link
        api.Mortgage.createApplicationRenewalUrl().then((res) => {
          if (res.data.indexOf("http") === 0) window.location.href = res.data;
        });
      }
      if (pageToGo === "Gå till frågor") path = "/mortgage/questions";
      if (pageToGo === "Till banken") {
        window.open(this.getBankLink());
        return;
      }
      if (pageToGo === "Ordna Trustpilot") {
        window.open(
          "https://se.trustpilot.com/review/ordnabolan.se?utm_medium=trustbox&utm_source=Carousel"
        );
        return;
      }

      this.$router.push(path);
    },
    getBankLink() {
      let offer = this.app.offers.find(
        (off: MortgageOfferView) => off.id == this.app.chosenOfferId
      );
      var link = this.bankLink.find((bl) =>
        offer.bank?.name?.startsWith(bl.bankName)
      );
      return link?.link;
    },
    getBankName() {
      let offer = this.app.offers.find(
        (off: MortgageOfferView) => off.id == this.app.chosenOfferId
      );
      return offer?.bank?.name;
    },
    sendRecommendation: async function () {
      this.recommendationSent = false;
      await api.Mortgage.submitRecommendationForm(this.recommendationForm);
      this.recommendationForm = { name: "", phoneNumber: "" };
      this.recommendationSent = true;
    },
    getInstructions() {
      if (this.app.purpose == "Renegotiate")
        return this.currentEvent.renegotiateInstructions;
      return this.currentEvent.instructions;
    }
  },
  beforeMount() {
    this.setcurrentEvent();
  }
});
