
import { defineComponent } from "vue";
import ExpandPanel from "../Controls/ExpandPanel.vue";
export default defineComponent({
  props: ["app"],
  data() {
    return {
      //Store QA in database? change by settings in dams
      qaItems: [
        {
          question: "Vilka är grundkraven för att få ett privatlån beviljat?",
          answer:
            "De grundkrav som bankerna ställer skiljer sig från bank till bank. Nedan är " +
            "därför en minsta gemensamma nämnare för vad våra banker har för krav. Många av våra banker har därför " +
            "hårdare krav än det som anges nedan vad gäller inkomst exempelvis. Så ju bättre ekonomisk situation du har desto " +
            "fler banker kan vi skicka din ansökan till. Har man betalningsanmärkningar begränsar det också antalet banker vi kan titta med." +
            "<br/></br>Du ska" +
            "<br/>- vara minst 20 år gammal" +
            "<br/>- ha en årsinkomst på minst 100 000" +
            "<br/>- ha en anställning (fast, prov, extra, tim, annan form)" +
            "<br/>- eller vara förtidspensionär/pensionär" +
            "<br/>- ha betalat av lånet innan du blir 79 år" +
            "<br/>- vara skuldfri från kronofogdemyndigheten i minst 6 månader" +
            "<br/>- inte ha fått en betalningsanmärkning de senaste 6 månaderna"
        },
        {
          question: "Vad ligger räntorna på privatlån på?",
          answer:
            "Genomsnittlig effektiv marknadsränta på privatlån ligger nominellt på ca 4,5%. Lägsta officiella räntan bland långivarna " +
            "är 2,95%. Ordna förmedlar lån mellan 2,95% - 19,90%." +
            "<br/><br/>Vi rekommenderar dock att det är den effektiva räntan man ska räkna på " +
            "när man ska ta ett lån. Den nominella räntan kan nämligen vara lurig på så sätt att den inte tar hänsyn till andra " +
            "avgifter för lånet som exempelvis uppläggningsavgift och aviavgifter. Men dessa avgifter är inkluderade i den effektiva räntan. " +
            "För att ta reda på den verkligen månadskostnaden för privatlånet rekommenderar vi därför att man ska räkna på den effektiva räntan."
        },
        {
          question:
            "Vad är det för skillnad mellan privatlån, blancolån och lån utan säkerhet?",
          answer:
            "Privatlån som också kallas blancolån eller konsumtionslån är lån utan säkerhet. De betyder alltså alla samma sak. " +
            "Även köp med kreditkort eller på avbetalning via butiker och på nätet är lån utan säkerhet. Medlemslån är ett annat exempel på privatlån. " +
            "<br/><br/>Gemensamt för alla dessa benämningar är att det inte finns en underliggande säkerhet för lånet och du kan spendera lånet på mer eller mindre det du själv vill."
        },
        {
          question: "Hur stort privatlån kan jag ansöka om med er tjänst?",
          answer:
            "Vi förmedlar privatlån mellan 10 000 - 600 000 kr. De flesta av våra banker beviljar lån på minst 10 000 kr."
        },
        {
          id: 4,
          question: "Är det inte bättre att ha kreditkort än privatlån?",
          answer:
            "Kreditkort har generellt sätt högre ränta och avgifter än privatlån. Men utöver detta så beror valet helt och hållet " +
            "på syftet med ditt lån. Om du till exempel vill ta lån för att göra ett större inköp som till exempel en bil som du ska betala " +
            "av under en längre tid är det generellt sett bättre att ansöka om ett privatlån. Om syftet med ditt lån är ren konsumtion där du " +
            "vill utnyttja mindre summor som du återbetalar i sin helhet varje månad är det mer förmånligt med ett kreditkort."
        },
        {
          question: "När borde jag omförhandla mitt privatlån?",
          answer:
            "Som kortast borde du vänta i minst 6 månader innan du omförhandlar ditt privatlån och har du haft ett och samma lån " +
            "i flera år utan att se över det är det hög tid att du gör det nu. " +
            "<br/><br/>Om din ekonomiska situation förändrats – exempelvis genom att " +
            "du fått en högre taxerad inkomst från ett år till ett annat kan ge dig förutsättningar att få lägre ränta på ditt privatlån än den " +
            "ränta du fick när du först tecknade lånet. Om du gjort få eller inte gjort några kreditupplysningar alls under en period om 6-12 " +
            "månader kan ha bidragit till att din så kallade UC score minskat och bankerna ser dig då som mer kreditvärdig vilket också kan göra " +
            "att du kan få en bättre ränta än när du tecknade lånet."
        }
      ]
    };
  },

  components: {
    ExpandPanel
  },

  computed: {
    isPaid() {
      let isPaid = true;
      this.app.mortgageFlowStatus.forEach((item: any) => {
        if (item.title === "Utbetalning" && item.status === "Current") {
          isPaid = false;
        }
      });
      return isPaid;
    }
  },

  methods: {
    goToPage() {
      window.open("https://www.ordnabolan.se/privatlan/");
    }
  }
});
