import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
    return (_openBlock(), _createElementBlock("div", {
      class: "form-check",
      key: index
    }, [
      _createElementVNode("input", {
        type: "radio",
        checked: _ctx.modelValue,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChanged && _ctx.onChanged(...args))),
        class: "form-check-input"
      }, null, 40, _hoisted_1),
      _createElementVNode("label", {
        class: "form-check-label",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.emitValue(!_ctx.modelValue)))
      }, [
        _renderSlot(_ctx.$slots, "label", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.label), 1)
        ])
      ])
    ]))
  }), 128))
}