
import { defineComponent } from "vue";
import { api, CustomerMessageForm, MessageLoantype } from "src/api";
import SelectInput from "../Controls/SelectInput.vue";

export default defineComponent({
  data: function () {
    return {
      form: {} as CustomerMessageForm,
      loanType: false,
      category: false,
      message: false,
      loanOptions: [{}],
      messageSent: false,
      showLoanOption: false
    };
  },
  components: {
    SelectInput
  },
  methods: {
    submit: function () {
      this.messageSent = false;

      if (this.form.loanType == null) this.loanType = true;
      if (this.form.message == null) this.message = true;

      if (this.form.loanType != null && this.form.message != null) {
        api.Message.submitForm(this.form).then(() => {
          this.$router.push("/message");
          this.loanType = false;
          this.category = false;
          this.message = false;
          this.messageSent = true;
        });
      }
    },
    getLoanOptions() {
      api.Message.getAppType().then((res) => {
        if (res.data == "Mortgage") {
          this.showLoanOption = false;
          this.form.loanType = MessageLoantype.Mortgage;
        } else if (res.data == "Loan") {
          this.showLoanOption = false;
          this.form.loanType = MessageLoantype.Loan;
        } else {
          this.showLoanOption = true;
          this.loanOptions = [
            { value: "Bolån", id: 0 },
            { value: "Privatlån", id: 1 }
          ];
        }
      });
    }
  },
  beforeMount() {
    this.getLoanOptions();
  }
});
