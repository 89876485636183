
import { defineComponent } from "vue";
import SfOutgoingLead from "./SfOutgoingLead.vue";
import RenovationLoan from "./RenovationLoan.vue";
import PropertySharing from "./PropertySharing.vue";
import LegalAdvice from "./LegalAdvice.vue";
import HouseIdApp from "./HouseIdApp.vue";
export default defineComponent({
  props: ["app", "isLoaded"],
  components: {
    SfOutgoingLead,
    RenovationLoan,
    PropertySharing,
    LegalAdvice,
    HouseIdApp
  },
  data() {
    return {
      currentPage: "SfOutgoingLead"
    };
  },
  methods: {
    setCurrentPage(value: string) {
      this.currentPage = value;
    }
  }
});
