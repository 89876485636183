
import { defineComponent } from "vue";

let idSequence = 0;

export default defineComponent({
  props: {
    modelValue: Boolean,
    label: String,
    valid: { type: Boolean, default: null },
    error: String
  },

  emits: ["update:modelValue"],

  data() {
    return { checkboxId: idSequence++ };
  },

  setup(props, { emit }) {
    function emitValue(value: boolean) {
      emit("update:modelValue", value);
    }

    function onChanged(e: InputEvent) {
      const target = e.currentTarget as HTMLInputElement;
      emitValue(target?.checked);
    }

    return {
      emitValue,
      onChanged
    };
  }
});
