
import { defineComponent } from "vue";
import OfferDocuments from "./OfferDocuments.vue";
import _ from "lodash";
import MortgageTopMenu from "../Controls/MortgageTopMenu.vue";

export default defineComponent({
  props: ["app", "offer", "isLoaded"],

  components: {
    OfferDocuments,
    MortgageTopMenu,
  },

  computed: {
    sortedDocuments: function (): any[] {
      if (this.offer == null || this.offer != null && this.offer.documents.length == 0) return _.orderBy(this.app.documents, ["modifiedAt"], ["desc"]);
      let sort = _.orderBy(this.offer.documents, ["modifiedAt"], ["desc"]);
      return sort;
    }
  },

  methods: {
    update() {
      this.$emit("update");
    }
  }
});
