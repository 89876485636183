import { Store } from "./Store";

interface AuthenticatedUser extends Object {
  name: string;
  appType: string;
  myPagesOfferViewed  : boolean;
}

class UserStore extends Store<AuthenticatedUser> {
  protected data(): AuthenticatedUser {
    return {
      name: "",
      appType: "",
      myPagesOfferViewed: false
    };
  }

  loginUser(name: string) {
    this.state.name = name;
  }

  setMypagesOfferViewed(type: string, myPagesOfferViewed: boolean = false) {
    this.state.appType = type;
    this.state.myPagesOfferViewed = myPagesOfferViewed;
  }

  isLoggedIn(): boolean {
    return this.state.name != "";
  }
}

export const userStore: UserStore = new UserStore();
