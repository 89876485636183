
import TextField from "./Controls/TextInput.vue";
export default {
  props: ["user"],
  data: function () {
    return {
      form: {}
    };
  },
  components: {
    TextField
  },
  methods: {
    submit: async function () {
      //api.
    }
  }
};
