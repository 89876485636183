
import { defineComponent } from "vue";
import MortgageQuestionsSteps from "@/components/Mortgage/MortgageQuestionsSteps.vue";
import {
  api,
  MortgageQuestion,
  MortgageQuestionsForm,
  MortgageQuestionsTerms
} from "src/api";
import questions from "@/model/mortgageQuestionsModel";

export default defineComponent({
  components: {
    MortgageQuestionsSteps
  },
  data() {
    return {
      questionsTerms: {} as MortgageQuestionsTerms,
      form: {} as MortgageQuestionsForm,
      questions: questions as MortgageQuestion[],
      loaded: false,
      isCoApplicant: false
    };
  },
  beforeMount: async function () {
    const view = (await api.Mortgage.getQuestionsTerms()).data;

    if (view.terms) this.questionsTerms = view.terms;
    if (view.form) this.form = view.form;
    if ((view as any).isCoApplicant) this.isCoApplicant = true;
    this.loaded = true;
  },
  methods: {
    onSubmitStep(form: MortgageQuestionsForm) {
      api.Mortgage.updateCustomerAnswers(form);
      //console.log(form, "onsubmitstep");
      // TODO submit api
    }
  }
});
