
import { defineComponent } from "vue";
export default defineComponent({
  props: ["item"],

  watch: {
    expanded() {
      if (this.expanded) this.showContent = true;
      else this.showContent = false;
    }
  },

  data() {
    return {
      expanded: false,
      showContent: false,
      expandedItems: new Array<number>()
    };
  }
});
