
import { defineComponent } from "vue";
import OfferCard from "./OfferCard.vue";
import ChangeRequest from "./ChangeRequest.vue";
import AdditionalInformation from "./AdditionalInformation.vue";
import MortgageTopMenu from "../Controls/MortgageTopMenu.vue";
import _ from "lodash";
import {
  api,
  MortgageApplicationView,
  MortgageOfferView,
  OfferStatus
} from "src/api";

export default defineComponent({
  props: ["showAdditionalInformation"],

  data() {
    return {
      app: {} as MortgageApplicationView,
      expandedOffers: new Array<number>(),
      showchangeRequest: false,
      isLoaded: false,
      questionsSubmitted: false
    };
  },
  components: {
    OfferCard,
    ChangeRequest,
    AdditionalInformation,
    MortgageTopMenu
  },

  beforeMount: async function () {
    this.load();
  },

  methods: {
    load: async function () {
      const activeApps = (await api.Application.getActiveApplications()).data;
      this.isLoaded = true;
      if (activeApps.mortgageApplication)
        this.app = activeApps.mortgageApplication;
    },
    onQuestionsSubmitted: function () {
      this.questionsSubmitted = true;
      this.load();
    },
    HasReceivedOfferReplyFromBank() {
      return this.app.offers?.some(
        (offer) =>
          offer.status == OfferStatus.Accepted ||
          (offer.bank?.name?.startsWith("sbab") &&
            offer.approvedAmount != null &&
            offer.approvedAmount > 0) ||
          (offer.bank?.name?.toLowerCase() == "skandia" &&
            offer.approvedAmount != null &&
            offer.approvedAmount > 0)
      );
    }
  },

  computed: {
    showQuestions: function (): boolean {
      if (this.showAdditionalInformation) return true;

      // don't show additional information if application has offers
      if (this.app.offers != null && this.app.offers?.length > 0) return false;

      // TODO enum/better
      if (this.app.loanStatus != 7 && this.app.loanStatus != 6) return false;

      return false;

      //   if (this.app.requiredFields == null) return false;

      //   const required = this.app.requiredFields;

      // !! fixes warning about null/unspecified (these booleans can't be null, wrong typescript generation)
      // TODO better/saner check in backend?
      //   const isAnyFieldRequired =
      //     !!required.propertyAmountFromPreviousSell ||
      //     !!required.totalSavingsInSkandia ||
      //     !!required.partnerMonthlyIncome ||
      //     !!required.propertyHavingOtherOwner ||
      //     !!required.skandiaLoanPurpose ||
      //     !!required.propertyBuyingDate ||
      //     !!required.existingLoanAmount ||
      //     !!required.bridgeLoanAmount ||
      //     !!required.sellingContractSigned ||
      //     !!required.downPaymentFrom ||
      //     !!required.privateLoansDetails ||
      //     !!required.housingsDetails ||
      //     !!required.propertyAmortizationAmount ||
      //     !!required.propertyInterestRate ||
      //     !!required.propertyOwnerShipShare;

      //   console.log(isAnyFieldRequired, "isAnyFieldRequired");

      //   return isAnyFieldRequired;
    },
    sortedOffers: function (): MortgageOfferView[] {
      return _.orderBy(this.app.offers, ["interestRate"], ["asc"]);
    },

    status: function () {
      if (this.app.loanStatus == 1) {
        return "Banken hanterar nu ditt ärende";
      } else if (this.app.loanStatus == 2) {
        return "Din ansökan är nu stängd";
      } else if (this.app.loanStatus == 3) {
        return "Din ansökan är nu färdigbehandlad.";
      } else if (this.app.loanStatus == 4) {
        return "Dessvärre kunde ingen bank erbjuda dig ett lån.";
      } else if (this.app.loanStatus == 5) {
        return "Vi väntar fortfarande svar från banker.";
      } else if (this.app.loanStatus == 6) {
        return "Din ansökan hanteras nu.";
      } else if (this.app.loanStatus == 7) {
        return "Din ansökan behandlas.";
      } else {
        return "-";
      }
    }
  }
});
