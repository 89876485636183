
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return { showTooltip: false };
  },
  props: ["title", "show"],
  methods: {
    toggle() {
      this.showTooltip = !this.showTooltip;
    }
  }
});
