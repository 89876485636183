
import { defineComponent } from "vue";

let idSequence = 0;

export default defineComponent({
  props: {
    modelValue: {} as any,
    options: {} as any,
    label: String,
    descriptions: { type: Array, default: null },
    descriptionsText: { type: String, default: null },
    itemText: { type: String, default: null},
    includeDash: { type: Boolean, default: true }
  },
  emits: ["update:modelValue"],
  data() {
    return {
      inputValue: "",
      isDirty: false,
      elementId: idSequence++
    };
  },
  methods: {
    emitValue(event: any) {
      var isInteger = parseInt(event.target.value);
      if (!isNaN(isInteger)) {
        this.$emit("update:modelValue", parseInt(event.target.value));
      } else this.$emit("update:modelValue", event.target.value);
    }
  }
});
