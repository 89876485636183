import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row my-3" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getMenu, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "col-sm-6 col-lg-3 col-md-4 d-grid"
      }, [
        (item.show)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              onClick: ($event: any) => (_ctx.gotoPage(item.path)),
              class: _normalizeClass(["btn btn-light mb-2", { active: item.isActive }])
            }, _toDisplayString(item.name), 11, _hoisted_2))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}