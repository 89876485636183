import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OfferDocumentsIndexView = _resolveComponent("OfferDocumentsIndexView")!

  return (_openBlock(), _createBlock(_component_OfferDocumentsIndexView, {
    app: _ctx.app,
    offer: _ctx.offer,
    isLoaded: _ctx.isLoaded,
    onUpdate: _ctx.load
  }, null, 8, ["app", "offer", "isLoaded", "onUpdate"]))
}