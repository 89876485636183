
import { defineComponent } from "vue";
import { api, MortgageApplicationView } from "src/api";
import CustomerOffersIndexView from "./CustomerOffersIndexView.vue";
export default defineComponent({
  components: {
    CustomerOffersIndexView
  },
  data() {
    return {
      app: {} as MortgageApplicationView,
      isLoaded: false
    };
  },
  methods: {
    load: async function () {
      const activeApps = (await api.Application.getActiveApplications()).data;
      this.isLoaded = false
      if (activeApps.mortgageApplication) {
        this.app = activeApps.mortgageApplication;
        this.isLoaded = true
      }
      if (activeApps.loanApplication) {
        this.app = activeApps.loanApplication;
        this.isLoaded = true
      }
    }
  },
  beforeMount: async function () {
    this.load();
  }
});
