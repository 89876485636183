import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "value"]
const _hoisted_3 = {
  key: 0,
  value: ""
}
const _hoisted_4 = ["selected", "value"]
const _hoisted_5 = {
  key: 0,
  class: "mt-3"
}
const _hoisted_6 = { class: "form-label" }
const _hoisted_7 = { class: "alert alert-secondary mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", {
      for: `select-${_ctx.elementId}`,
      class: "form-label"
    }, [
      _renderSlot(_ctx.$slots, "label", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.label), 1)
      ])
    ], 8, _hoisted_1),
    _createElementVNode("div", null, [
      _createElementVNode("select", {
        id: `select-${_ctx.elementId}`,
        class: "form-select",
        onInput: _cache[0] || (_cache[0] = (event) => _ctx.emitValue(event)),
        value: _ctx.modelValue
      }, [
        (_ctx.includeDash)
          ? (_openBlock(), _createElementBlock("option", _hoisted_3, "-"))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
          return (_openBlock(), _createElementBlock("option", {
            key: index,
            selected: option.id ?? index == _ctx.modelValue,
            value: option.id ?? index
          }, _toDisplayString(_ctx.itemText ? option[_ctx.itemText] : option.value ? option.value : option), 9, _hoisted_4))
        }), 128))
      ], 40, _hoisted_2)
    ]),
    (_ctx.descriptions && _ctx.descriptions[_ctx.modelValue])
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.descriptionsText), 1),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("small", null, _toDisplayString(_ctx.descriptions[_ctx.modelValue]), 1)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}