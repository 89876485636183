
import { defineComponent } from "vue";
export default defineComponent({
  props: ["backgroundColor", "trackColor", "progressWidth"],
  data() {
    return {
      progress: 0
    };
  },
  watch: {
    progressWidth() {
      this.progress = this.progressWidth;
    }
  }
});
