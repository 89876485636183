import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row align-items-center position-relative" }
const _hoisted_2 = { class: "row mb-4" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "h3 text-center" }
const _hoisted_5 = {
  key: 0,
  class: "mt-2"
}
const _hoisted_6 = { class: "row mb-2" }
const _hoisted_7 = { class: "col-sm-12 col-lg-6" }
const _hoisted_8 = { class: "col-sm-12 col-lg-6" }
const _hoisted_9 = {
  key: 0,
  class: "row mb-2"
}
const _hoisted_10 = { class: "col-sm-12 col-lg-4" }
const _hoisted_11 = { class: "col-sm-12 col-lg-4" }
const _hoisted_12 = { class: "col-sm-12 col-lg-4" }
const _hoisted_13 = {
  key: 1,
  class: "row mb-3"
}
const _hoisted_14 = { class: "col-sm-12 col-lg-4" }
const _hoisted_15 = { class: "col-sm-12 col-lg-4" }
const _hoisted_16 = { class: "col-sm-12 col-lg-4" }
const _hoisted_17 = {
  key: 2,
  class: "row mb-2"
}
const _hoisted_18 = { class: "col-sm-12 col-lg-4" }
const _hoisted_19 = { class: "col-sm-12 col-lg-4" }
const _hoisted_20 = {
  key: 3,
  class: "row mb-3"
}
const _hoisted_21 = { class: "col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_NumberInput = _resolveComponent("NumberInput")!
  const _component_SelectInput = _resolveComponent("SelectInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, [
          _createElementVNode("strong", null, _toDisplayString(_ctx.formLabels.title), 1)
        ]),
        _createElementVNode("div", null, _toDisplayString(_ctx.formLabels.description), 1),
        (
            _ctx.app.offerIsPremiumBank &&
            _ctx.app.maxLoanAmount &&
            _ctx.calculationType == 'CalculateCosts'
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("strong", null, " Det maximala lånebeloppet som ni kan låna är " + _toDisplayString(_ctx.formatCurrency(_ctx.app.maxLoanAmount)) + " Kr. ", 1)
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_NumberInput, {
          modelValue: _ctx.form.propertyEstimateValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.propertyEstimateValue) = $event)),
          money: true,
          onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.calculateInterestRate())),
          placeholder: "2 000 000"
        }, {
          label: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_ctx.formLabels.proppertyEstimateValueLabel), 1),
            (_ctx.formLabels.propertyEstimateValueTooltip)
              ? (_openBlock(), _createBlock(_component_Tooltip, {
                  key: 0,
                  title: `${_ctx.formLabels.propertyEstimateValueTooltip}`
                }, null, 8, ["title"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_NumberInput, {
          modelValue: _ctx.form.loanAmount,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.loanAmount) = $event)),
          money: true,
          onInput: _cache[3] || (_cache[3] = ($event: any) => (_ctx.calculateInterestRate())),
          placeholder: "1 000 000",
          error: 
            _ctx.loanAmountIsGreaterThanMaxLoanAmount ? _ctx.getMaxLoanAmountText() : ''
          
        }, {
          label: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_ctx.formLabels.loanAmountLabel), 1),
            (_ctx.formLabels.loanAmountLabelTooltip)
              ? (_openBlock(), _createBlock(_component_Tooltip, {
                  key: 0,
                  title: `${_ctx.formLabels.loanAmountLabelTooltip}`
                }, null, 8, ["title"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue", "error"])
      ])
    ]),
    (_ctx.selectInterestRate)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_SelectInput, {
              modelValue: _ctx.selectedInterestRateIndex,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedInterestRateIndex) = $event)),
              options: _ctx.interestRates,
              label: "Välj bindningstid",
              "item-text": "binding",
              includeDash: false,
              onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setInterestRate()))
            }, null, 8, ["modelValue", "options"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_NumberInput, {
              label: "Din nya ränta",
              percent: true,
              modelValue: _ctx.selectInterestRate.interestRate,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.selectInterestRate.interestRate) = $event)),
              readonly: true
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_NumberInput, {
              label: "Effektiv ränta",
              percent: true,
              modelValue: _ctx.selectInterestRate.effectiveInterestRate,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.selectInterestRate.effectiveInterestRate) = $event)),
              readonly: true
            }, null, 8, ["modelValue"])
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.amortizationRate && _ctx.selectInterestRate)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_component_NumberInput, {
              label: "Amortering",
              money: true,
              modelValue: _ctx.amortizationRate.amortizationCost,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.amortizationRate.amortizationCost) = $event)),
              readonly: true
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_NumberInput, {
              label: "Räntekostnad",
              money: true,
              modelValue: _ctx.amortizationRate.interestCost,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.amortizationRate.interestCost) = $event)),
              readonly: true
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_16, [
            _createVNode(_component_NumberInput, {
              label: 
            _ctx.app.showMortgageCalculationForCanceledApp
              ? 'Total kostnad'
              : 'Total bolånekostnad'
          ,
              money: true,
              modelValue: _ctx.amortizationRate.monthlyCost,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.amortizationRate.monthlyCost) = $event)),
              readonly: true
            }, null, 8, ["label", "modelValue"])
          ])
        ]))
      : _createCommentVNode("", true),
    (
        _ctx.selectInterestRate &&
        _ctx.calculationType == 'CalculateCosts' &&
        !_ctx.app.showMortgageCalculationForCanceledApp
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createVNode(_component_NumberInput, {
              label: 
            _ctx.app.propertyHousing == 'Condominium'
              ? 'Månadsavgift'
              : 'Driftkostnad'
          ,
              money: true,
              modelValue: _ctx.app.propertyMonthlyCost,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.app.propertyMonthlyCost) = $event)),
              onOnInput: _cache[12] || (_cache[12] = ($event: any) => (_ctx.calculateTotalCost()))
            }, null, 8, ["label", "modelValue"])
          ]),
          _createElementVNode("div", _hoisted_19, [
            _createVNode(_component_NumberInput, {
              label: "Total månadskostnad",
              money: true,
              modelValue: _ctx.totalCost,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.totalCost) = $event)),
              readonly: true
            }, null, 8, ["modelValue"])
          ])
        ]))
      : _createCommentVNode("", true),
    (
        (_ctx.calculationType == 'CalculateInterest' &&
          _ctx.interestRates.length > 0 &&
          _ctx.app.showMortgageCalculationForCanceledApp) ||
        (_ctx.calculationType == 'CalculateCosts' &&
          _ctx.amortizationRate != null &&
          _ctx.app.showMortgageCalculationForCanceledApp)
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("button", {
              onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.gotoApplicationForm())),
              class: "btn btn-primary"
            }, _toDisplayString(_ctx.btnText), 1)
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}