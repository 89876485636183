<template>
  <div class="content-section">
    <div class="card card-feat">
      <div class="card-body">
        <div class="">
          <div class="btn btn-xs btn-outline mb-3" @click="$router.back()">
            <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.289827 6.64289L5.21736 11.4486C5.40326 11.6286 5.65245 11.7309 5.91302 11.7343C6.17502 11.7338 6.42617 11.6311 6.61157 11.4486C6.7989 11.2673 6.90351 11.0188 6.90142 10.76C6.9011 10.5026 6.7968 10.2558 6.61157 10.0743L3.38258 6.93146L12.4145 6.93146C12.9388 6.90344 13.3494 6.47619 13.3494 5.9586C13.3494 5.44101 12.9388 5.01376 12.4145 4.98574L3.37678 4.98574L6.60577 1.84289C6.89676 1.56693 6.98753 1.14391 6.83481 0.775445C6.68209 0.40698 6.3168 0.167625 5.91302 0.171457C5.65358 0.172028 5.40516 0.274915 5.22316 0.457171L0.289827 5.25717C0.104785 5.44115 0.000658035 5.68953 -2.76566e-05 5.9486C-0.00138187 6.2089 0.102996 6.45892 0.289827 6.64289Z" fill="currentColor"/>
          </svg>
            Tillbaka
          </div>

          <div class="text-center border-bottom border-light mb-3 py-2">
            <h1 class="h3">
              Vi på Ordna tackar för att vi fick hjälpa till med ditt bolån
            </h1>   
          </div>  
          <div class="text-center">    
            <div>
              <p>
                Har du funderingar när du ska fylla i lånehandlingarna? Kontakta oss!
              </p>

              <div class="border border-light d-inline-block p-3 my-3">                
                <p>
                  www.ordnabolan.se<br />
                  020-25 26 26
                </p>
                <p class="my-2">
                  <img
                    src="@/assets/icons/logo-2.svg"
                    alt="footer-logo-black"
                    width="215"
                    class="img-fluid"
                  />
                </p>
              </div>
            </div>
            <div class="border-top border-light mt-3 pt-3">  
              <p class="small">
                Spara gärna vårt telefonnummer eller ta en skärmbild på detta så har
                du våra kontakter för dina framtida lånebehov.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
