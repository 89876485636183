import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50fd1c7c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["value"]
const _hoisted_3 = ["id"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["checked", "onInput"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", {
      for: `multiselect-${_ctx.elementId}`,
      class: "form-label"
    }, [
      _renderSlot(_ctx.$slots, "label", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.label), 1)
      ], true)
    ], 8, _hoisted_1),
    _createElementVNode("input", {
      type: "text",
      readonly: "",
      class: "form-select",
      onFocus: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onTextInputFocus && _ctx.onTextInputFocus(...args))),
      value: _ctx.textValue
    }, null, 40, _hoisted_2),
    _withDirectives(_createElementVNode("ul", {
      tabindex: "0",
      ref: "dropdownMenu",
      id: _ctx.dropdownMenuId,
      class: "list-group dropdown-menu",
      onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onDropdownBlur && _ctx.onDropdownBlur(...args)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
        return (_openBlock(), _createElementBlock("li", {
          class: _normalizeClass(["list-group-item", {
        'list-group-item-primary': _ctx.modelValue && _ctx.modelValue.includes(index)
      }]),
          key: index,
          onClick: ($event: any) => (_ctx.onListItemClick($event, index))
        }, [
          _createElementVNode("label", null, [
            _createElementVNode("input", {
              type: "checkbox",
              checked: _ctx.modelValue && _ctx.modelValue.includes(index),
              onInput: ($event: any) => (_ctx.toggleChecked(index)),
              onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onDropdownBlur && _ctx.onDropdownBlur(...args)))
            }, null, 40, _hoisted_5),
            _createTextVNode(" " + _toDisplayString(option), 1)
          ])
        ], 10, _hoisted_4))
      }), 128))
    ], 40, _hoisted_3), [
      [_vShow, _ctx.expanded]
    ])
  ]))
}