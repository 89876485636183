
  import { defineComponent } from "vue";
  import MortgageCalculationView from "./MortgageCalculationView.vue";
  import { api, MortgageApplicationView } from "src/api";
  export default defineComponent({
    components: {
        MortgageCalculationView
    },
    data() {
      return {
        app: {} as MortgageApplicationView,
        isLoaded: false
      };
    },
    methods: {
      load: async function () {
        const activeApps = (await api.Application.getActiveApplications()).data;
        this.isLoaded = true;
        if (activeApps.mortgageApplication)
          this.app = activeApps.mortgageApplication;
      }
    },
    beforeMount: async function () {
      this.load();
    }
  });
  