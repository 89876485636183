
// import { isNumber } from "lodash";
import { defineComponent } from "vue";

interface HasSubmitted {
  submitted: boolean;
}

export default defineComponent({
  props: {
    modelValue: { type: [String, Number], default: null },
    percent: Boolean,
    money: Boolean,
    accountNumber: { type: Boolean, default: false },
    label: String,
    valid: { type: Boolean, default: false },
    error: String,
    readonly: { type: Boolean, default: false },
    placeholder: String,
  },
  data() {
    return {
      inputValue: "",
      isDirty: false
    };
  },
  watch: {
    modelValue: {
      handler(value: number) {
        if (!this.isDirty) {
          if (typeof value === "undefined" || value == null)
            this.inputValue = "";
          else {
            this.setInputValue(value.toString());
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    // isEmpty: function (): boolean {
    //   return typeof this.inputValue === "undefined" || this.inputValue === "";
    // }
    // isValidNumber: function (): boolean {
    //   if (typeof this.inputValue === "undefined") return false;
    //   return !isNaN(this.inputValue as unknown as number);
    // },
    // isValid: function (): boolean {
    //   return (
    //     (this.isEmpty && !this.required) ||
    //     (!this.isEmpty && this.isValidNumber)
    //   );
    // }
  },
  methods: {
    setInputValue(value: string) {
      if (this.money) {
        try {
          const float = parseFloat(value);
          this.inputValue = isNaN(float) ? "" : float.toLocaleString("se").replaceAll(",", " ");
        } catch (e) {

          this.inputValue = value;
        }
      } else if (this.percent) {
        this.inputValue = value.replace(".", ",");
      } else {

        this.inputValue = value;
      }
    },
    // allow only numbers, and comma, dot (,.)
    onKeydown(event: KeyboardEvent) {
      if (!isNaN(parseInt(event.key))) return;
      if (this.percent && (event.key == "." || event.key == ",")) {
        if (this.inputValue && this.inputValue.indexOf(".") !== -1)
          event.preventDefault();
        return;
      }
      if (
        [
          "Backspace",
          "Delete",
          "ArrowLeft",
          "ArrowRight",
          "Home",
          "End",
          "Tab"
        ].indexOf(event.key) !== -1
      )
        return;

      // prevent all other keys
      event.preventDefault();
    },
    onInput(event: { target: HTMLInputElement }) {
      let value = event.target.value;

      if (this.percent) {
        value = value.replaceAll(",", ".");
      }
      value = value.replace(/[^.\d]/g, "");

      const numberValue = value as unknown as number;
      const isNumber = !isNaN(numberValue);

      // cap percent to 100%
      if (isNumber && this.percent && numberValue > 100) {
        value = "100";
        event.target.value = value;
      }
      
      this.setInputValue(value);

      if (isNumber && !this.accountNumber) this.$emit("update:modelValue", Number(value));
      if (isNumber && this.accountNumber) this.$emit("update:modelValue", value);
      if (value === "") this.$emit("update:modelValue", null);
      this.$emit("onInput")
    }
  }
});
