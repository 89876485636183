
import { defineComponent } from "vue";
import { api, MortgageApplicantInfoView, MyPagesAppType } from "src/api";
import BankIdForm from "src/components/BankId/BankIdForm.vue";
import { userStore } from "src/store/UserStore";

export default defineComponent({
  props: {
    applicantId: { type: String, required: false },
    signing: { type: Boolean, required: false }
  },
  data: function () {
    return {
      status: "init",
      orderRef: "",
      autoStartToken: "",
      collectDelay: 2000,
      userMessage: "",
      errorCode: "",
      response: {},
      chosenDevice: "",
      isLoaded: false,
      applicants: {} as MortgageApplicantInfoView,
      signAsCoApplicant: false
    };
  },
  components: { BankIdForm },

  beforeMount: async function () {
    if (this.applicantId) {
      try {
        this.applicants = (
          await api.Mortgage.getApplicantInfo(this.applicantId)
        ).data;

        if (this.applicants.coApplicantId == this.applicantId)
          this.signAsCoApplicant = true;
      } catch (e) {
        console.log("applicantInfo not found");
      }
    }
    this.isLoaded = true;
  },

  methods: {
    reset: function () {
      this.status = "init";
      this.userMessage = "";
      this.orderRef = "";
      this.autoStartToken = "";
      this.chosenDevice = "";
    },
    start: async function (personalNumber: string) {
      this.status = "start";

      const response = this.applicants.applicantId
        ? await api.Auth.bankIdStartApplicant(
            this.signAsCoApplicant && this.applicants.coApplicantId
              ? this.applicants.coApplicantId
              : this.applicants.applicantId
          )
        : await api.Auth.bankIdStart(personalNumber);

      if (response.data.errorCode) {
        // TODO
        this.errorCode = response.data.errorCode;
        this.status = "error";
        return;
      }

      this.orderRef = response.data.orderRef ?? "";
      this.autoStartToken = response.data.autoStartToken ?? "";

      this.status = "start";

      // Open BankId on same device
      if (!personalNumber) {
        const url =
          "bankid:///?autostarttoken=" +
          response.data.autoStartToken +
          "&redirect=null";

        // TODO iOs requires redirect

        window.location.assign(url);
      }

      this.pollStatus();
    },

    collect: async function () {
      console.log(this.orderRef, "this.orderRef");

      const response = await api.Auth.bankIdCollect(
        this.orderRef,
        this.signing
      );

      if (response.status != 200) {
        this.status = "error";
        return;
      }

      this.response = response.data;
      if (
        typeof response.data.orderRef === "undefined" ||
        response.data.orderRef !== this.orderRef
      ) {
        return;
      }
      this.userMessage = response.data.userMessage ?? "";
      this.status = response.data.status ?? "";

      return response;
    },

    pollStatus: async function () {
      // conditions to stop polling
      if (this.status !== "pending" && this.status !== "start") return;

      try {
        let response = null;
        response = await this.collect();

        if (!response) {
          this.status = "error";
          return;
        }

        // check if orderRef was changed on cancel
        if (response.data.orderRef !== this.orderRef) this.status = "error";

        // successful login
        if (
          response.data.completionData?.user &&
          response.data.completionData.user.name
        ) {
          this.status = "success";

          userStore.loginUser(response.data.completionData.user.name);

          if (response.data.appType === MyPagesAppType.Mortgage) {
            this.$router.push({ name: "mortgage_index" });
          }

          if (response.data.appType === MyPagesAppType.Loan) {
            this.$router.push({ name: "loan_index" });
          }

          return response;
        }
        window.setTimeout(this.pollStatus, this.collectDelay);
      } catch (e) {
        // just keep polling like nothing happened
        // TODO maybe not best idea - this tries to fix Safari login error. Maybe we should do this only if NetworkError in Safari
        console.log("BankId network connection lost",e);
        window.setTimeout(this.pollStatus, this.collectDelay);
      }
    },

    cancel: async function () {
      const response = await api.Auth.bankIdCancel(this.orderRef);
      if (response.status != 200) this.status = "error";
      else this.reset();
    }
  }
});
