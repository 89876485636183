
import { defineComponent } from "vue";
import SfButton from "../Controls/SfButton.vue";
import SelectInput from "../Controls/SelectInput.vue";
import municipalitiesJson from "../json/Municipalities.json";
import TextField from "../Controls/TextInput.vue";
import { api, OutgoingLeadForm, MortgageApplicationView } from "src/api";
export default defineComponent({
  props: {
    app: { type: Object, default: {} },
    isLoaded: { type: Boolean, default: false }
  },
  components: {
    SfButton,
    SelectInput,
    TextField
  },
  data() {
    return {
      form: {} as OutgoingLeadForm,
      isInValid: false,
      isValid: false,
      failedAlertText: "",
      successAlertText: "",
      disabledBtn:false,
      propertyType: [
        {
          text: "Villa",
          icon: "villa-gray-xdark.svg",
          type: "House"
        },
        {
          text: "Bostadsrätt",
          icon: "lagenhet-gray-xdark.svg",
          type: "Condominium"
        },
        {
          text: "Fritidshus",
          icon: "fritidshus-gray-xdark.svg",
          type: "Cottage"
        },
        { text: "Tomt", icon: "tomt-gray-xdark.svg", type: "Plot" }
      ],
      leadAction: [
        {
          text: "Försäljning",
          type: "Sale"
        },
        {
          text: "Värdering",
          type: "Valuation"
        },
        {
          text: "Skrivuppdrag",
          type: "Writing Assignment"
        }
      ],
      municipalities: municipalitiesJson,
      officeName: {} as number
    };
  },
  methods: {
    setForm() {
      (this.form.city = this.app.applicant.city),
        (this.form.officeName = this.app.applicant.city),
        (this.form.address = this.app.applicant.address),
        (this.form.postalCode = this.app.applicant.postalCode),
        (this.form.mortgageApplicationId = this.app.applicationId),
        (this.form.loanApplicationId = this.app.loanApplicationId),
        (this.form.firstName = this.app.applicant.firstname),
        (this.form.lastName = this.app.applicant.lastname),
        (this.form.email = this.app.applicant.email),
        (this.form.phone = this.app.applicant.phone),
        (this.form.customerPersonalNumber =
          this.app.applicant.personalNumber);
      this.form.realEstateType = "";
      this.form.leadAction = "";
      this.form.areaSquareMeter = "";
      this.form.numberOfRooms = "";
    },
    validate() {
      if (
        this.form.realEstateType == "" ||
        this.form.realEstateType == null ||
        this.form.realEstateType == "undefined"
      )
        return { isInValid: true, text: "Du måste välja bostadstyp" };
      if (
        this.form.leadAction == "" ||
        this.form.leadAction == null ||
        this.form.leadAction == "undefined"
      )
        return { isInValid: true, text: "Du måste välja ärendetyp" };
      if (
        this.form.numberOfRooms == "" ||
        this.form.numberOfRooms == null ||
        this.form.numberOfRooms == "undefined"
      )
        return { isInValid: true, text: "Du måste fylla i antal rum" };
      if (
        this.form.areaSquareMeter == "" ||
        this.form.areaSquareMeter == null ||
        this.form.areaSquareMeter == "undefined"
      )
        return { isInValid: true, text: "Du måste fylla i boarea (kvm)" };
      if (
        this.form.address == "" ||
        this.form.address == null ||
        this.form.address == "undefined"
      )
        return { isInValid: true, text: "Du måste fylla i adress" };
      if (
        this.form.postalCode == "" ||
        this.form.postalCode == null ||
        this.form.postalCode == "undefined"
      )
        return { isInValid: true, text: "Du måste fylla i postnummer" };
      if (
        this.form.city == "" ||
        this.form.city == null ||
        this.form.city == "undefined"
      )
        return { isInValid: true, text: "Du måste fylla i postort" };

      return {
        isInValid: false,
        text: "Tack för din intresseanmälan. En mäklare med lokalkännedom kommer att kontakta dig inom kort."
      };
    },
    getMessage(){
      return  "Hej! Här kommer ett tips via vår hemsida. Vi har inte pratat med kunden och kan därför inte ge dig mer information om vart i säljprocessen kunden befinner sig. Tacksam om ni tar kontakt med kunden. Lycka till! Hälsningar,  Henrik Andersson, Ordna Bolån";
    },
    sendLead: async function () {
      this.isInValid = false;
      this.isValid = false;
      this.disabledBtn = true;
      var validation = this.validate();
      if (validation.isInValid) {
        this.isInValid = true;
        this.disabledBtn = false;
        this.failedAlertText = validation.text;
        return;
      }
      this.form.message = this.getMessage();
      try {
        await api.Lead.outgoingLead(this.form);
        this.isValid = true;
        this.disabledBtn = false;
        this.successAlertText = validation.text;
      } catch (error) {
        this.isInValid = true;
        this.disabledBtn = false;
        this.failedAlertText =
          "Någonting gick fel. Kontakta oss under Meddelande.";
      }
    },
    setOfficeName() {
      this.form.officeName = this.municipalities[this.officeName];
    }
  },
  watch: {
    isLoaded: {
      handler(loaded: Boolean) {
        if (loaded) {
          this.setForm();
        }
      },
      deep: true,
      immediate: true
    }
  }
});
