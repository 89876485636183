
import { defineComponent, PropType } from "vue";
import StatusCard from "./StatusCard.vue";
import StatusProgress from "./StatusProgress.vue";
import MortgageTopMenu from "../Controls/MortgageTopMenu.vue";
import Feedback from "./Feedback.vue";
import MortgageCalculationView from "./MortgageCalculationView.vue";
import { CustomerStatus, MortgageApplicationView } from "@/api";

export default defineComponent({
  components: {
    StatusCard,
    StatusProgress,
    MortgageTopMenu,
    Feedback,
    MortgageCalculationView
  },
  props: {
    app: { type: Object as PropType<MortgageApplicationView> }
  },
  computed: {
    isNoContactStatus() {
      return this.app?.customerStatus == CustomerStatus.NoContact;
    }
  }
});
