
import { defineComponent } from "vue";
import { api } from "src/api";
import Tooltip from "src/components/Controls/Tooltip.vue";
import ProgressBar from "@/components/Controls/FileUploadProgressBar.vue";

export default defineComponent({
  props: ["item", "app"],
  components: { Tooltip, ProgressBar },
  data() {
    return {
      file: {} as File,
      successMessage: false,
      progressWidth: 0,
      showProgress: false
    };
  },

  watch: {
    item() {
      this.showProgress = false;
      this.progressWidth = 0;
    }
  },

  computed: {
    showUploads() {
      //   return true;
      return this.item.status != "Approved";
    },
    checkPersonalNumber() {
      if (this.app?.applicantPersonalNumber === this.item.personalNumber)
        return "Bifoga dokument för huvudsökande";
      else if (this.app?.coApplicantPersonalNumber === this.item.personalNumber)
        return "Bifoga dokument för medsökande";
      else return "Bifoga dokument";
    },
    status() {
      if (this.item.status === "WaitingToBeChecked")
        return "Väntar på granskning";
      else if (
        (this.item.status === "Missing" && this.item.contentType != null) ||
        (this.item.status === "Missing" && this.item.customerUploads.length > 0)
      )
        return "Uppladdat";
      else if (this.item.status === "Approved")
        return "Dokument som är godkända";
      else if (this.item.status === "Declined")
        return "Dokument som inte är godkända";
      else if (this.item.status === "Withdrawn") return "Åerkallad";
      else return "Dokument som saknas";
    },
    applicantName() {
      if (this.app?.applicantPersonalNumber === this.item.personalNumber)
        return "huvudsökande";
      else if (this.app?.coApplicantPersonalNumber != null && this.app.coApplicantPersonalNumber === this.item.personalNumber)
        return "medsökande";
    }
  },

  methods: {
    onFileChange(e: Event) {
      const files = (e.target as HTMLInputElement).files; // || e.dataTransfer.files;
      if (files !== null && files.length) this.file = files[0];
    },
    setProgress(progressEvent: ProgressEvent) {
      this.progressWidth = Math.round(
        (progressEvent.loaded / this.file.size) * 100
      );
    },
    async sendFile() {
      if (this.file.size == null) return;
      this.showProgress = true;
      await api.Mortgage.addMortgageDocument(this.item.id, this.file, {
        onUploadProgress: (progressEvent: ProgressEvent) =>
          this.setProgress(progressEvent)
      });
      this.$emit("update");
      this.file = {} as File;
    },
    async openDocument(id: string) {
      const url = await api.Mortgage.downloadDocument(id);
      // ts fix because data is void in generated api
      window.open(url.config.url, url.data as unknown as string);
    },

    async deleteDocument(id: string) {
      await api.Mortgage.deleteDocumentContent(id);
      this.$emit("update");
    },

    async openOfferDocumentContent(id: string, offerDocumentContentId: string) {
      const url = await api.Mortgage.downloadOfferDocumentContent(
        id,
        offerDocumentContentId
      );
      // ts fix because data is void in generated api
      window.open(url.config.url, url.data as unknown as string);
    },

    async deleteOfferDocumentContent(
      id: string,
      offerDocumentContentId: string
    ) {
      await api.Mortgage.deleteOfferDocumentContent(id, offerDocumentContentId);
      this.$emit("update");
    }
  }
});
