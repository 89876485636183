
import { defineComponent } from "vue";

let idSequence = 0;

export default defineComponent({
  props: {
    modelValue: {} as any,
    options: Array,
    label: String
  },
  emits: ["update:modelValue"],
  data() {
    return {
      elementId: idSequence++,
      expanded: false
    };
  },
  computed: {
    textValue() {
      return this.modelValue?.map((index: number) =>
        this.options ? this.options[index] : ""
      );
    },
    dropdownMenuId() {
      return `dropdown-menu-${this.elementId}`;
    }
  },
  methods: {
    onDropdownBlur(event: FocusEvent) {
      let node = event.relatedTarget as HTMLElement;
      if (node == null) {
        this.expanded = false;
        return;
      }
      // if focused element is in dropdown menu, keep dropdown open
      // set max parent search depth to 5
      for (let i = 0; i < 5; i++) {
        if (node === null || node?.id === this.dropdownMenuId) {
          return;
        }
        if (node.parentElement === null) {
          break;
        }
        node = node.parentElement;
      }
      // close dropdown if focused element not in dropdown menu
      this.expanded = false;
    },
    async onTextInputFocus(event: FocusEvent) {
      if (!this.expanded) {
        // if not open, instead of focusing input, we want to focus first checkbox
        event.preventDefault();

        this.expanded = true;

        // await until checkboxes are rendered, otherwise we cant focus checkbox
        await this.$nextTick();

        const dropdownMenu = this.$refs.dropdownMenu as HTMLElement;
        const first = dropdownMenu.querySelector("input");
        first?.focus();
      } else {
        const dropdownMenu = this.$refs.dropdownMenu as HTMLElement;
        const first = dropdownMenu.querySelector("input");
        first?.focus();
        this.expanded = false;
      }
    },
    toggleChecked(index: number) {
      let newValue = [];
      if (!Array.isArray(this.modelValue)) newValue = [index];
      // was checked, option needs to be removed
      else if (this.modelValue.includes(index)) {
        newValue = this.modelValue.slice();
        newValue.splice(newValue.indexOf(index), 1);
      }
      // was unchecked, option needs to be added
      else {
        newValue = this.modelValue.slice();
        newValue.push(index);
      }
      this.$emit("update:modelValue", newValue);
    },
    onListItemClick(event: PointerEvent, index: number) {
      // check if actual list item was clicked, so we exclude checkbox/label clicks
      const target = event.target as HTMLElement;
      if (target.tagName != "LI") return;
      return this.toggleChecked(index);
    }
  }
});
