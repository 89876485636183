
import { defineComponent } from "vue";
import { api, CustomerLoanChangeRequest } from "src/api";

const formatterWithoutCurrency = new Intl.NumberFormat("sv-SE", {
  currency: "SEK"
});

export default defineComponent({
  props: ["loanAmount"],

  data() {
    return {
      submitted: false,
      changeRequest: {
        loanAmount: 0
      } as CustomerLoanChangeRequest
    };
  },
  watch: {
    loanAmount: function (value) {
      console.log("watch");
      const parsed = parseInt(value);
      if (parsed) this.changeRequest.loanAmount = parsed;
    }
  },
  computed: {
    changeRequestLoanAmount: {
      get: function (): string {
        const value = this.changeRequest.loanAmount ?? 0;
        return formatterWithoutCurrency.format(value);
      },
      set: function (newvalue: string) {
        const value = this.takeOutspaceFromNumber(newvalue);
        this.changeRequest.loanAmount = Number(value);
      }
    }
  },
  methods: {
    submit: function () {
      api.Mortgage.submitChangeRequestMortgage(this.changeRequest).then(() => {
        this.submitted = true;
      });
    },
    takeOutspaceFromNumber(number: string) {
      let value;
      if (typeof number == "number") {
        value = number;
      } else {
        const takeOutSpace = number.replace(/\s/g, "");
        value = takeOutSpace;
      }
      return value;
    }
  }
});
